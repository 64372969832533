import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { change, reset } from 'redux-form';
import { stack as Menu } from 'react-burger-menu';
import v4 from 'uuid/v4';
import { connect } from 'react-redux';
import {
    MdArrowDropDown,
    MdVpnLock,
    MdWeb,
    MdVpnKey,
    MdCode,
    MdSave,
    MdDomain,
} from 'react-icons/md';
import {
    FaTruck,
    FaChalkboardTeacher,
    FaLifeRing,
    FaBtc,
} from 'react-icons/fa';
import {
    globalContractType,
    openModal,
} from '../../../methods/actions/actionCreators';
import { getDomainType, getUserAvatar } from '../../../methods/helpers/common';

import DropButton, {
    DropButtonGroup,
    DropButtonItem,
} from '../dropButton/DropButton';
import C from '../../../constants/actionTypes';

const { $ } = window;

class MenuBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };
        this.setDropdownActive = this.setDropdownActive.bind(this);
        this.setDropdownInactive = this.setDropdownInactive.bind(this);
        this.scrollToSection = this.scrollToSection.bind(this);
        this.getMenuItemClassName = this.getMenuItemClassName.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setContractType = this.setContractType.bind(this);
    }

    handleClickOutside(event) {
        if (
            this.dropdownList &&
            !this.dropdownList.contains(event.target) &&
            this.state.active
        ) {
            this.setDropdownInactive();
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setDropdownActive() {
        const { active } = this.state;

        if (!active) {
            this.setState({
                active: true,
            });
        }
    }

    setDropdownInactive() {
        this.setState({
            active: false,
        });
    }

    scrollToSection(type) {
        const {
            location: { pathname },
            history,
        } = this.props;
        const path = pathname.slice(1);
        const $html = $('html, body');

        if (path.length === 0) {
            const $targetBlock = $(`#${type}`);
            const offset = $targetBlock.offset().top;
            $html.animate({ scrollTop: `${offset}px` }, 500);
        } else {
            history.push('/');
            setTimeout(() => {
                const $targetBlock = $(`#${type}`);
                const offset = $targetBlock.offset().top;
                $html.animate({ scrollTop: `${offset}px` }, 700);
            }, 150);
        }
    }

    setContractType(type) {
        const { dispatch } = this.props;

        dispatch(globalContractType(type));

        dispatch(change('CreateContractForm', 'ContractType', type));
    }

    getMenuItemClassName(type) {
        const {
            location: { pathname },
        } = this.props;

        if (type !== 'history') {
            return classNames({
                'navigation-item': true,
                active: pathname.includes(type),
            });
        }
        return classNames({
            'navigation-item': true,
            active:
                pathname.includes(type) &&
                !pathname.includes('deals') &&
                !pathname.includes('contracts'),
        });
    }

    render() {
        const { active } = this.state;
        const {
            user,
            openModal,
            location: { pathname },
            dispatch,
        } = this.props;
        const { DealsTotal, ContractsTotal, UnreadMessages } = user;
        const strPathNames = ['contracts', 'deals', 'history', 'messages'];

        const myDealsClassName = classNames({
            'navigation-item': true,
            active: strPathNames.some((item) => pathname.includes(item)),
        });

        const publicOffersClassName = classNames({
            'navigation-item': true,
            active: pathname.includes('public'),
        });

        return [
            <MediaQuery minWidth={992} key={v4()}>
                <div className="menu-block">
                    {user.loggedIn && (
                        <DropButton label={i18next.t('new_deal')}>
                            <DropButtonItem>
                                <MdDomain className="blue" size="24px" />
                                <Link to="/createnew/11">
                                    {i18next.t('contractType_11')}
                                </Link>
                            </DropButtonItem>
                            <DropButtonItem>
                                <MdVpnLock className="blue" size="24px" />
                                <Link
                                    to="/create/1"
                                    onClick={() => this.setContractType(0)}>
                                    {i18next.t('contractType_1')}
                                </Link>
                            </DropButtonItem>
                            <DropButtonGroup
                                icon={<MdWeb className="blue" size="24px" />}
                                label={i18next.t('social_contract_types')}>
                                <DropButtonItem>
                                    <Link to="/createnew/12">
                                        {i18next.t('contractType_12')}
                                    </Link>
                                </DropButtonItem>
                            </DropButtonGroup>
                            {/* <DropButtonGroup icon={<MdWeb className='blue' size='24px'/>} label={i18next.t('contractType_1')}> */}
                            {/*  <DropButtonItem> */}
                            {/*    <Link to="/create/1">{i18next.t('contractType_9')}</Link> */}
                            {/*  </DropButtonItem> */}
                            {/*  <DropButtonItem> */}
                            {/*    <Link to="/create/1">{i18next.t('contractType_10')}</Link> */}
                            {/*  </DropButtonItem> */}
                            {/* </DropButtonGroup> */}
                            {/*<DropButtonItem>*/}
                            {/*    <MdVpnKey className="blue" size="24px" />*/}
                            {/*    <Link*/}
                            {/*        to="/create/1"*/}
                            {/*        onClick={() => this.setContractType(8)}>*/}
                            {/*        {i18next.t('contractType_8')}*/}
                            {/*    </Link>*/}
                            {/*</DropButtonItem>*/}
                            <DropButtonItem>
                                <FaTruck className="blue" size="24px" />
                                <Link
                                    to="/create/1"
                                    onClick={() => this.setContractType(3)}>
                                    {i18next.t('contractType_3')}{' '}
                                </Link>
                            </DropButtonItem>
                            <DropButtonItem>
                                <FaChalkboardTeacher
                                    className="blue"
                                    size="24px"
                                />
                                <Link
                                    to="/create/1"
                                    onClick={() => this.setContractType(4)}>
                                    {i18next.t('contractType_4')}
                                </Link>
                            </DropButtonItem>
                            <DropButtonItem>
                                <MdCode className="blue" size="24px" />
                                <Link
                                    to="/create/1"
                                    onClick={() => this.setContractType(5)}>
                                    {i18next.t('contractType_5')}
                                </Link>
                            </DropButtonItem>
                            <DropButtonItem>
                                <FaBtc className="blue" size="24px" />
                                <Link
                                    to="/create/1"
                                    onClick={() => this.setContractType(7)}>
                                    {i18next.t('contractType_7')}
                                </Link>
                            </DropButtonItem>
                            <DropButtonItem>
                                <MdSave className="blue" size="24px" />
                                <Link
                                    to="/create/1"
                                    onClick={() => this.setContractType(2)}>
                                    {i18next.t('contractType_2')}
                                </Link>
                            </DropButtonItem>
                        </DropButton>
                    )}
                    {getDomainType() !== 3 && (
                        <Link to="/user/deals" className={myDealsClassName}>
                            {i18next.t('my_deals')}
                        </Link>
                    )}
                    {!pathname.includes('user') &&
                        !pathname.includes('public') &&
                        !pathname.includes('create') && [
                            <span
                                className="menu"
                                key={v4()}
                                ref={(el) => (this.dropdownList = el)}>
                                <a
                                    className="navigation-item how-it-works"
                                    onClick={this.setDropdownActive}>
                                    {i18next.t('how_it_works')}
                                    <MdArrowDropDown />
                                </a>
                                {active && (
                                    <div className="menu-dropdown animated fadeIn">
                                        <a
                                            onClick={() =>
                                                this.scrollToSection(
                                                    'buyers_block'
                                                )
                                            }>
                                            {i18next.t('buyers_block_link')}
                                        </a>
                                        <a
                                            onClick={() =>
                                                this.scrollToSection(
                                                    'sellers_block'
                                                )
                                            }>
                                            {i18next.t('sellers_block_link')}
                                        </a>
                                        <a
                                            onClick={() =>
                                                this.scrollToSection(
                                                    'marketplace_block'
                                                )
                                            }>
                                            {i18next.t('markets_block_link')}
                                        </a>
                                        <a
                                            onClick={() =>
                                                this.scrollToSection(
                                                    'paymentMethods_block'
                                                )
                                            }>
                                            {i18next.t('payments_block_link')}
                                        </a>
                                    </div>
                                )}
                            </span>,
                        ]}

                    {getDomainType() !== 3 && (
                        <Link to="/public" className={publicOffersClassName}>
                            {i18next.t('catalogue_link')}
                        </Link>
                    )}
                </div>
            </MediaQuery>,
            <MediaQuery maxWidth={991} key={v4()}>
                <Menu
                    pageWrapId="page-wrap"
                    className="bm-list"
                    width="100%"
                    isOpen={false}
                    right
                    outerContainerId="outer-container">
                    <ul>
                        {user.loggedIn && (
                            <>
                                <li>
                                    <Link
                                        to="/create/1"
                                        onClick={() => {
                                            dispatch(
                                                reset('CreateContractForm')
                                            );
                                        }}
                                        className={this.getMenuItemClassName(
                                            'create'
                                        )}>
                                        {i18next.t('new_deal')}
                                    </Link>
                                </li>
                                {getDomainType() !== 3 && (
                                    <>
                                        <li>
                                            <Link
                                                to="/user/deals"
                                                className={this.getMenuItemClassName(
                                                    'deals'
                                                )}>
                                                {`${i18next.t('my_deals')}: `}
                                                <span className="navigation-item__badge">
                                                    {DealsTotal}
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/user/contracts"
                                                className={this.getMenuItemClassName(
                                                    'contracts'
                                                )}>
                                                {i18next.t('offer_deal')} :
                                                <span className="navigation-item__badge">
                                                    {ContractsTotal}
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/user/messages"
                                                className={this.getMenuItemClassName(
                                                    'messages'
                                                )}>
                                                {i18next.t('message')} :
                                                <span className="navigation-item__badge">
                                                    {UnreadMessages}
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/user/history/deals"
                                                className={this.getMenuItemClassName(
                                                    'history'
                                                )}>
                                                {i18next.t('history')}
                                            </Link>
                                        </li>
                                    </>
                                )}
                            </>
                        )}

                        {getDomainType() !== 3 && (
                            <>
                                <li>
                                    <Link
                                        to="/public"
                                        className={this.getMenuItemClassName(
                                            'public'
                                        )}>
                                        {i18next.t('catalogue_link')}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/faq"
                                        className={this.getMenuItemClassName(
                                            'faq'
                                        )}>
                                        {i18next.t('faq')}
                                    </Link>
                                </li>
                                <li>
                                    <a
                                        className="navigation-item"
                                        onClick={() => openModal('help')}>
                                        <FaLifeRing /> &nbsp;
                                        {i18next.t('support_link')}
                                    </a>
                                </li>
                            </>
                        )}
                    </ul>
                    {user.loggedIn ? (
                        <div className="user-block">
                            <Link to="/profile" className="profile-link">
                                {getUserAvatar(
                                    user.AvatarBase64,
                                    user.Profile.LoginProvider
                                )}
                                <span className="link">
                                    {user.Profile.LoginProvider &&
                                    (user.Profile.LoginProvider ===
                                        'WebMoney' ||
                                        user.Profile.LoginProvider === 'Site')
                                        ? user.UserLogin
                                        : user.Email}
                                </span>
                            </Link>
                            <div>
                                <button
                                    onClick={() => openModal('logout')}
                                    className="bm-list_btn">
                                    {i18next.t('logout_text')}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="user-block">
                            <button
                                onClick={() => openModal('login')}
                                className="bm-list_btn login">
                                {i18next.t('login_text')}
                            </button>
                        </div>
                    )}
                </Menu>
            </MediaQuery>,
        ];
    }
}

export default connect(null, { openModal })(MenuBlock);
