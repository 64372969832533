import Cookies from 'js-cookie';
import param from 'jquery-param';
import i18next from 'i18next';
import { push } from 'react-router-redux';
import {
    handleError,
    successNotifier,
    messageNotifier,
} from '../helpers/notifications';
import { userAuthenticated } from './session';
import URLS from '../../constants/urls';
import {
    handleServerResponse,
    handleDataReceived,
    getData,
} from '../helpers/common';
import cookiesNames from '../../constants/cookiesNames';
import { openModal } from '../actions/actionCreators';

// Registration
export const handleRegistration = ({ Email, Password }) => {
    const body = param({
        Email,
        Password,
    });

    return fetch(URLS.USER_REGISTER_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body,
    })
        .then(handleServerResponse)
        .then(handleDataReceived);
};

export const handleRegistrationViaInvitation = ({
    Email,
    Password,
    Code: SecretCode = null,
}) => {
    const body = param({
        Email,
        Password,
        SecretCode,
    });

    return fetch(URLS.USER_INVOICE_REGISTRATION_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body,
    })
        .then(handleServerResponse)
        .then(handleDataReceived);
};

export const handleInvoiceRegistration = (data) => (dispatch) => {
    const invoiceCookie = JSON.parse(decodeURIComponent(data));
    Cookies.remove(cookiesNames.INVOICE_REGISTRATION);
    if (invoiceCookie.IsSuccess) {
        dispatch(openModal('invoice-registration', invoiceCookie.Data));
    } else {
        return dispatch(handleError(i18next.t('7_error_code')));
    }
};

export const handleRegistrationConfirmation = (data) => (dispatch) => {
    const regData = JSON.parse(decodeURIComponent(data));
    Cookies.remove(cookiesNames.EMAIL_CONFIRMED);
    if (regData.IsSuccess) {
        dispatch(userAuthenticated(regData.Data));
        dispatch(successNotifier(i18next.t('email_confirmation_text')));
    } else {
        dispatch(handleError(regData));
    }
};

export const handleRegistrationAlreadyComplited = (data) => (dispatch) => {
    const resData = JSON.parse(decodeURIComponent(data));
    Cookies.remove(cookiesNames.REGISTRATION_ALREADY_COMPLITED);
    if (resData.AuthData.IsSuccess) {
        dispatch(userAuthenticated(resData.AuthData.Data));
        dispatch(push(`/user/contracts/${resData.ContractId}`));
        dispatch(
            messageNotifier(
                i18next.t('user_registration_already_complited_text')
            )
        );
    } else {
        dispatch(handleError(resData.AuthData));
    }
};

// Password recovery

export const startPasswordRecovery = (data) => (dispatch) =>
    fetch(`${URLS.PASSWORD_RECOVERY_SEND_EMAIL_URL}?email=${data.Email}`)
        .then(handleServerResponse)
        .then(handleDataReceived)
        .then(() =>
            dispatch(successNotifier(i18next.t('password_recovery_text_2')))
        );

export const openPasswordRecoveryModal = (data) => (dispatch) => {
    const reset_data = JSON.parse(decodeURIComponent(data));
    Cookies.remove(cookiesNames.RESET_PASSWORD);
    if (reset_data.IsSuccess) {
        dispatch(openModal('new-password', reset_data.Data));
    } else {
        dispatch(handleError(reset_data));
    }
};

export const submitRestoredPassword = (data) => (dispatch) =>
    fetch(URLS.SUBMIT_RESTORED_PASSWORD_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: param(data),
    })
        .then(handleServerResponse)
        .then(handleDataReceived)
        .then(() =>
            dispatch(
                successNotifier(
                    i18next.t('access_recovered_2'),
                    i18next.t('access_recovered')
                )
            )
        );

export const getUserShortInfo =
    (login = '') =>
    (dispatch) => {
        const url = URLS.USER_SHORT_PROFILE_URL + login;

        return getData(url).catch((err) => dispatch(handleError(err)));
    };
