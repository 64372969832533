export default {
    CONTRACT_UPDATED: 'CONTRACT_UPDATED',
    COUNTER_OFFER: 'COUNTER_OFFER',
    CONTRACT_ACCEPTED_BY_USER: 'CONTRACT_ACCEPTED_BY_USER',
    CONTRACT_ACCEPTED_BY_PARTNER: 'CONTRACT_ACCEPTED_BY_PARTNER',
    CONTRACT_DECLINED_BY_USER: 'CONTRACT_DECLINED_BY_USER',
    CONTRACT_DECLINED_BY_PARTNER: 'CONTRACT_DECLINED_BY_PARTNER',
    CONTRACT_CREATED: 'CONTRACT_CREATED',
    CONTRACTS_FETCHED: 'CONTRACTS_FETCHED',
    CONTRACTS_FETCHING: 'CONTRACTS_FETCHING',
    CONTRACT_FETCHING: 'CONTRACT_FETCHING',
    CONTRACT_FETCH_FAILED: 'CONTRACT_FETCH_FAILED',
    CONTRACTS_FETCH_FAILED: 'CONTRACTS_FETCH_FAILED',
    CONTRACTS_LOADED_MORE: 'CONTRACTS_LOADED_MORE',
    CONTRACT_TYPES_FETCHED: 'CONTRACT_TYPES_FETCHED',
    CONTRACT_TEMPLATE_FETCHED: 'CONTRACT_TEMPLATE_FETCHED',
    CONTRACT_TEMPLATES_FETCHED: 'CONTRACT_TEMPLATES_FETCHED',
    NO_AVAILABLE_CONTRACT_TEMPLATE: 'NO_AVAILABLE_CONTRACT_TEMPLATE',
    NEW_CONTRACT_OFFER: 'NEW_CONTRACT_OFFER',

    CLEAR_NEW_CONTRACT: 'CLEAR_NEW_CONTRACT',
    SET_REFERRAL_LINK: 'SET_REFERRAL_LINK',
    CLEAR_REFERRAL_LINK: 'CLEAR_REFERRAL_LINK',
    SET_REDIRECT_URL: 'SET_REDIRECT_URL',
    CLEAR_REDIRECT_URL: 'CLEAR_REDIRECT_URL',

    CONTRACTS_HISTORY_FETCHED: 'CONTRACTS_HISTORY_FETCHED',
    CONTRACT_TRANSACTIONS_FETCHED: 'CONTRACT_TRANSACTIONS_FETCHED',
    CONTRACT_FETCHED: 'CONTRACT_FETCHED',
    CONTRACTS_CLEAR: 'CONTRACTS_CLEAR',
    CONTRACTS_SORT: 'CONTRACTS_SORT',
    USERS_PUBLIC_CONTRACTS_FETCHED: 'USERS_PUBLIC_CONTRACTS_FETCHED',
    PREVIOUS_CONTRACT_VERSION_FETCHED: 'PREVIOUS_CONTRACT_VERSION_FETCHED',
    PREVIOUS_CONTRACT_VERSION_FETCH_FAILED:
        'PREVIOUS_CONTRACT_VERSION_FETCH_FAILED',
    CLEAR_CURRENT_CONTRACT: 'CLEAR_CURRENT_CONTRACT',

    PUBLIC_CONTRACTS_FETCHED: 'PUBLIC_CONTRACTS_FETCHED',
    PUBLIC_CONTRACT_FETCHED: 'PUBLIC_CONTRACT_FETCHED',
    PUBLIC_CONTRACT_FETCH_FAILED: 'PUBLIC_CONTRACT_FETCH_FAILED',
    PUBLIC_CONTRACT_CREATED: 'PUBLIC_CONTRACT_CREATED',
    PUBLIC_CONTRACTS_CLEAR: 'PUBLIC_CONTRACTS_CLEAR',
    PUBLIC_CONTRACT_ACCEPTED: 'PUBLIC_CONTRACT_ACCEPTED',

    NEW_DEAL_CREATED: 'NEW_DEAL_CREATED',
    DEAL_CREATED: 'DEAL_CREATED',
    DEAL_FETCHED: 'DEAL_FETCHED',
    DEAL_TRANSACTIONS_FETCHED: 'DEAL_TRANSACTIONS_FETCHED',
    DEAL_FETCHING: 'DEAL_FETCHING',
    DEALS_FETCHING: 'DEALS_FETCHING',
    DEAL_UPDATED: 'DEAL_UPDATED',
    DEAL_DURATION_UPDATED: 'DEAL_DURATION_UPDATED',
    DEAL_FETCH_FAILED: 'DEAL_FETCH_FAILED',
    DEALS_FETCHED: 'DEALS_FETCHED',
    DEAL_PAID: 'DEAL_PAID',
    DEAL_PAYMENT_CONFIRMED: 'DEAL_PAYMENT_CONFIRMED',
    DEALS_FETCH_FAILED: 'DEALS_FETCH_FAILED',
    DEALS_SORT: 'DEALS_SORT',
    DEALS_HISTORY_FETCHED: 'DEALS_HISTORY_FETCHED',
    DISPUTE_OPENED: 'DISPUTE_OPENED',
    DEAL_CLOSED: 'DEAL_CLOSED',
    DEAL_PROLONGATION_REQUESTED: 'DEAL_PROLONGATION_REQUESTED',
    DEAL_PROLONGATION_REQUEST_SENT: 'DEAL_PROLONGATION_REQUEST_SENT',
    DEAL_PROLONGATION_CONFIRMED: 'DEAL_PROLONGATION_CONFIRMED',
    DEAL_PROLONGED: 'DEAL_PROLONGED',
    DEAL_PROLONGATION_REJECTED: 'DEAL_PROLONGATION_REJECTED',
    DEAL_PROLONGATION_REQUEST_REJECTED: 'DEAL_PROLONGATION_REQUEST_REJECTED',
    DEAL_TEMPLATE_FETCHED: 'DEAL_TEMPLATE_FETCHED',

    AUTHENTICATED: 'AUTHENTICATED',
    USER_LOGGED_IN: 'LOGGED_IN',
    USER_WM_LOGIN: 'USER_WM_LOGIN',
    USER_LOGGED_OUT: 'USER_LOGGED_OUT',
    USER_PROFILE_FETCHED: 'USER_PROFILE_FETCHED',
    USER_PROFILE_UPDATED: 'USER_PROFILE_UPDATED',
    USER_REGISTERED: 'USER_REGISTERED',
    USER_PURSE_ADDED: 'USER_PURSE_ADDED',
    USER_PURSES_LOADED: 'USER_PURSES_LOADED',
    USER_PURSE_REMOVED: 'USER_PURSE_REMOVED',
    USER_PASSWORD_CHANGED: 'USER_PASSWORD_CHANGED',
    USER_TRANSACTIONS_FETCHED: 'USER_TRANSACTIONS_FETCHED',
    USER_TRANSACTIONS_FILTERED: 'USER_TRANSACTIONS_FILTERED',
    USER_ACCOUNT_SAVING_WITHDRAW_UPDATE: 'USER_ACCOUNT_SAVING_WITHDRAW_UPDATE',
    USER_FETCHING_WITHDRAW_MONEY: 'USER_FETCHING_WITHDRAW_MONEY',
    USER_FETCHED_WITHDRAW_MONEY: 'USER_FETCHED_WITHDRAW_MONEY',
    USER_PASSPORT_ADDED: 'USER_PASSPORT_ADDED',
    SESSION_CLOSED: 'SESSION_CLOSED',
    SESSION_OUTDATED: 'SESSION_OUTDATED',
    CLEAR_SESSION: 'CLEAR_SESSION',
    SMEV_STATUS_CHANGED: 'SMEV_STATUS_CHANGED',

    ADD_SUCCESS_MESSAGE: 'ADD_SUCCESS_MESSAGE',

    SHOW_LOADER: 'SHOW_LOADER',
    HIDE_LOADER: 'HIDE_LOADER',

    LANGUAGE_CHANGED: 'LANGUAGE_CHANGED',
    LOCALE_LOADED: 'LOCALE_LOADED',
    PAYMENT_METHODS_LOADED: 'PAYMENT_METHODS_LOADED',
    PAYMENT_DIFF_METHODS_LOADED: 'PAYMENT_DIFF_METHODS_LOADED',
    RESET_FEES: 'RESET_FEES',
    HUB_CONNECTED: 'HUB_CONNECTED',
    TOKEN_REFRESHED: 'TOKEN_REFRESHED',

    SHOW_NEW_CONTRACTS: 'SHOW_NEW_CONTRACTS',
    SHOW_ACTIVE_CONTRACTS: 'SHOW_ACTIVE_CONTRACTS',
    SHOW_ALL_CONTRACTS: 'SHOW_ALL_CONTRACTS',

    SHOW_ACTIVE_DEALS: 'SHOW_ACTIVE_DEALS',
    SHOW_DISPUTED_DEALS: 'SHOW_DISPUTED_DEALS',
    SHOW_NEW_DEALS: 'SHOW_NEW_DEALS',
    SHOW_ALL_DEALS: 'SHOW_ALL_DEALS',

    CHATS_LIST_FETCHED: 'CHATS_LIST_FETCHED',
    CONTRACTS_CHATS_LIST_FETCHED: 'CONTRACTS_CHATS_LIST_FETCHED',

    CHAT_ENTITY_FETCHED: 'CHAT_ENTITY_FETCHED',
    CHAT_ENTITY_FETCH_FAILED: 'CHAT_ENTITY_FETCH_FAILED',
    SENT_MESSAGES_READ: 'SENT_MESSAGES_READ',

    CHAT_DELETED: 'CHAT_DELETED',
    CHAT_OPENED: 'CHAT_OPENED',
    CHAT_CLOSED: 'CHAT_CLOSED',
    INCOMING_MESSAGES_READ: 'INCOMING_MESSAGES_READ',

    NEW_MESSAGE: 'NEW_MESSAGE',
    MESSAGES_FETCHED: 'MESSAGES_FETCHED',
    MESSAGES_LAZY_LOADED: 'CHAT_MESSAGES_LAZY_LOADED',
    CLEAR_CHAT_MESSAGES: 'CLEAR_CHAT_MESSAGES',
    UPDATE_MESSAGES_COUNTER: 'UPDATE_MESSAGES_COUNTER',

    VALIDATIONS_FETCHED: 'VALIDATIONS_FETCHED',

    CLEAR_CONTRACT_DETAILED: 'CLEAR_CONTRACT_DETAILED',
    CLEAR_DEAL_DETAILED: 'CLEAR_DEAL_DETAILED',
    SET_TIMER: 'SET_TIMER',
    CLEAR_TIMER: 'CLEAR_TIMER',

    LIMITED_CONTRACT_DATA_FETCHED: 'LIMITED_CONTRACT_DATA_FETCHED',

    SET_CONTRACTS_FILTERS: 'SET_CONTRACTS_FILTERS',
    UNSET_CONTRACTS_FILTERS: 'UNSET_CONTRACTS_FILTERS',

    SET_DEALS_FILTERS: 'SET_DEALS_FILTERS',
    UNSET_DEALS_FILTERS: 'UNSET_DEALS_FILTERS',

    SET_DEALS_HISTORY_FILTERS: 'SET_DEALS_HISTORY_FILTERS',
    UNSET_DEALS_HISTORY_FILTERS: 'UNSET_DEALS_HISTORY_FILTERS',

    SET_CONTRACTS_HISTORY_FILTERS: 'SET_CONTRACTS_HISTORY_FILTERS',
    UNSET_CONTRACTS_HISTORY_FILTERS: 'UNSET_CONTRACTS_HISTORY_FILTERS',

    FEES_FETCHED: 'FEES_FETCHED',
    CONTRACT_FEES_FETCHED: 'CONTRACT_FEES_FETCHED',
    DEAL_FEES_FETCHED: 'DEAL_FEES_FETCHED',

    LOAD_FAILED: 'LOAD_FAILED',
    WS_CONNECTED: 'WS_CONNECTED',
    WS_DISCONNECT: 'WS_DISCONNECT',

    STATISTICS_FETCHED: 'STATISTICS_FETCHED',

    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    MODAL_LOADING: 'MODAL_LOADING',
    MODAL_LOADED: 'MODAL_LOADED',

    ACCOUNT_REFRESH_EXPIRED: 'ACCOUNT_REFRESH_EXPIRED',
    DETACH_ACCOUNT: 'DETACH_ACCOUNT',

    UNAUTHORIZED_ACCEPTANCE_TRIGGERED: 'UNAUTHORIZED_ACCEPTANCE_TRIGGERED',
    CLEAR_PUBLIC_CONTRACT_ACCEPTANCE_DATA:
        'CLEAR_PUBLIC_CONTRACT_ACCEPTANCE_DATA',
    FAST_LOGIN_FAILED: 'FAST_LOGIN_FAILED',
    FAST_LOGIN_SUCCEEDED: 'FAST_LOGIN_SUCCEEDED',

    CONTRACTS_COUNTER_UPDATED: 'CONTRACTS_COUNTER_UPDATED',
    DEALS_COUNTER_UPDATED: 'DEALS_COUNTER_UPDATED',
    SUPPORT_WINDOW_OPENED: 'SUPPORT_WINDOW_OPENED',
    SUPPORT_WINDOW_CLOSED: 'SUPPORT_WINDOW_CLOSED',
    NEW_SUPPORT_MESSAGE: 'NEW_SUPPORT_MESSAGE',
    SUPPORT_MESSAGES_FETCHED: 'SUPPORT_MESSAGES_FETCHED',
    RESTRICT_BTC: 'RESTRICT_BTC',
    GLOBAL_CONTRACT_TYPE: 'GLOBAL_CONTRACT_TYPE',
};
