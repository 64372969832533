import React, { Component } from 'react';
import { NavLink, withRouter, Link } from 'react-router-dom';
import Dropdown from 'react-toolbox/lib/dropdown';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { fetchLocale } from '../../methods/api/settings';
import { changeLocale, openModal } from '../../methods/actions/actionCreators';
import { getData, getDomainType } from '../../methods/helpers/common';
import locales from '../../constants/locales';
import visa from '../../assets/images/visa.svg';
import master from '../../assets/images/Mastercard-logo.svg';
import mir from '../../assets/images/mir.svg';
import maestro from '../../assets/images/Maestro_Logo.svg';
import webmoney from '../../assets/images/WebMoney_logo_blue.svg';
import bitcoin from '../../assets/images/bitcoin.svg';
import sbp from '../../assets/images/sbp.svg';

const langItem = (item) => (
    <div>
        <img src={item.img} alt="flag" />
        <span>{item.label}</span>
    </div>
);

const _renderEmailAddress = function (email = 'support@guarantee.money') {
    return (
        <a href={`mailto:${email}`} title={email}>
            {email}
        </a>
    );
};
const getYear = () => new Date().getFullYear();

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLang: 'ru-RU',
        };
        this.changeLang = this.changeLang.bind(this);
    }

    componentDidMount() {
        const { locale } = this.props;
        this.setState({
            selectedLang: locale.lang,
        });
    }

    componentWillReceiveProps(nextProps) {
        const { locale } = this.props;
        if (locale.lang !== nextProps.locale.lang) {
            this.setState({
                selectedLang: nextProps.locale.lang,
            });
        }
    }

    changeLang(lang) {
        const { changeLocale, locale, fetchLocale } = this.props;
        this.setState({
            selectedLang: lang,
        });

        if (locale.localizationData[lang]) {
            changeLocale(lang);
        } else {
            fetchLocale(lang)
                .then(() => {
                    changeLocale(lang);
                })
                .catch((err) => console.log(err));
        }
    }

    render() {
        const { btcRestricted } = this.props;
        const showBtc = false;
        return (
            <footer>
                <div className="ui container">
                    <div className="footer-content two column ui stackable grid">
                        <div className="column">
                            <ul className="payment-methods">
                                {getDomainType() !== 3 && (
                                    <li className="webmoney">
                                        <img
                                            src={webmoney}
                                            alt="webmoney_logo"
                                        />
                                    </li>
                                )}
                                {
                                    // TODO: временная заглушка для неотображения BTC на главной
                                    showBtc &&
                                        !btcRestricted &&
                                        getDomainType() !== 3 && (
                                            <li className="bitcoin">
                                                <img
                                                    src={bitcoin}
                                                    alt="bitcoin_logo"
                                                />
                                            </li>
                                        )
                                }

                                <li className="visa">
                                    <img src={visa} alt="visa_logo" />
                                </li>
                                <li className="mastercard">
                                    <img src={master} alt="master_logo" />
                                </li>
                                <li className="mir">
                                    <img src={mir} alt="mir_logo" />
                                </li>
                                <li className="maestro">
                                    <img src={maestro} alt="maestro_logo" />
                                </li>
                                <li className="maestro">
                                    <img src={sbp} alt="visa_logo" />
                                </li>
                            </ul>

                            <div className="ui two column grid footer__menu">
                                <div className="column">
                                    <div>
                                        {getDomainType() !== 3 && (
                                            <NavLink to="/about">
                                                {i18next.t('about_text')}
                                            </NavLink>
                                        )}
                                    </div>
                                    <div>
                                        {getDomainType() !== 3 && (
                                            <NavLink to="/arbitration">
                                                {i18next.t(
                                                    'settlement_of_disputes'
                                                )}{' '}
                                                / Арбитраж
                                            </NavLink>
                                        )}
                                    </div>
                                    <div>
                                        <NavLink to="/developers">
                                            {i18next.t('developer_link_text')}
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="column">
                                    <div>
                                        {getDomainType() !== 3 && (
                                            <NavLink to="/terms-of-use">
                                                {i18next.t('lisence_term')}
                                            </NavLink>
                                        )}
                                        {getDomainType() === 3 && (
                                            <a
                                                href="https://kkb.ru/doc/payments_transfers_individual/oferta-po-bezopasnoi-sdelke.pdf"
                                                title={i18next.t('oferta')}>
                                                {i18next.t('oferta')}
                                            </a>
                                        )}
                                    </div>
                                    <div>
                                        {getDomainType() === 3 && (
                                            <a
                                                href="https://kkb.ru/doc/information_security/policy_pdn.pdf"
                                                title={i18next.t('confi_term')}>
                                                {i18next.t('confi_term')}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <p className="support">
                                По всем вопросам и для получения <br />
                                доступа к API Escrow отправьте заявку на&nbsp;
                                {_renderEmailAddress('escrow@molot.ru')}
                            </p>

                            {getDomainType() === 3 ? (
                                <p className="copyright">
                                    {i18next
                                        .t('kkb_copyright')
                                        .replace('{year}', getYear())}
                                </p>
                            ) : (
                                <p className="copyright">
                                    {i18next
                                        .t('platron_copyright')
                                        .replace('{year}', getYear())}
                                </p>
                            )}
                        </div>
                        <div className="column right aligned">
                            <Dropdown
                                source={locales}
                                className="language-dropdown"
                                auto
                                onChange={this.changeLang}
                                value={this.state.selectedLang}
                                template={langItem}
                            />
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

const mapStateToProps = ({ locale, btcRestricted }) => ({
    locale,
    btcRestricted,
});

export default withRouter(
    connect(mapStateToProps, {
        changeLocale,
        openModal,
        fetchLocale,
    })(Footer)
);
