import C from '../../constants/actionTypes';

const initialState = {
    currentInfo: {},
    chat: {
        Items: [],
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case C.DEAL_FETCHED:
            return {
                ...state,
                currentInfo: {
                    ...action.payload,
                },
            };

        case C.DEAL_UPDATED:
        case C.DEAL_PROLONGATION_CONFIRMED:
            if (state.currentInfo.Id === parseInt(action.payload.Id)) {
                return {
                    ...state,
                    currentInfo: {
                        ...state.currentInfo,
                        ...action.payload,
                    },
                };
            }
            return state;

        case C.DISPUTE_OPENED:
            if (state.currentInfo.Id === action.payload) {
                return {
                    ...state,

                    currentInfo: {
                        ...state.currentInfo,
                        Status: 2,
                    },
                };
            }
            return state;

        case 'DEAL_CANCEL_CONFIRMED':
            if (state.currentInfo.Id === action.payload) {
                return {
                    ...state,

                    currentInfo: {
                        ...state.currentInfo,
                        Status: 9,
                    },
                };
            }
            return state;

        case C.DEAL_PAYMENT_CONFIRMED:
            if (state.currentInfo.Id === action.payload.Id) {
                return {
                    ...state,
                    currentInfo: {
                        ...state.currentInfo,
                        ...action.payload,
                        NeedPayeePurse: false,
                    },
                };
            }
            return state;

        case C.MESSAGES_FETCHED:
            if (
                state.currentInfo &&
                state.currentInfo.ContractId === action.payload.EntityId
            ) {
                return {
                    ...state,
                    chat: {
                        Items: action.payload.Items.slice().reverse(),
                        Count: action.payload.Count,
                    },
                };
            }
            return state;

        case C.NEW_MESSAGE:
            if (
                state.currentInfo &&
                state.currentInfo.ContractId ===
                    parseInt(action.payload.BindedObjectId)
            ) {
                return {
                    ...state,
                    chat: {
                        Items: [...state.chat.Items, action.payload],
                        Count: state.chat.Count + 1,
                    },
                };
            }
            return state;

        case C.MESSAGES_LAZY_LOADED:
            if (
                state.currentInfo &&
                state.currentInfo.ContractId === action.payload.EntityId
            ) {
                return {
                    ...state,
                    chat: {
                        ...state.chat,
                        Items: [
                            ...action.payload.Items.slice().reverse(),
                            ...state.chat.Items,
                        ],
                    },
                };
            }
            return state;

        case C.INCOMING_MESSAGES_READ:
            if (state.currentInfo) {
                if (action.payload.EntityId === state.currentInfo.ContractId) {
                    const collection = state.chat.Items.map((message) => {
                        if (message.From !== action.payload.UserId) {
                            return { ...message, Status: 1 };
                        }
                        return message;
                    });

                    return {
                        ...state,
                        chat: {
                            ...state.chat,
                            Items: collection,
                        },
                    };
                }
                return state;
            }
            return state;

        case C.SENT_MESSAGES_READ:
            if (state.currentInfo) {
                if (action.payload.EntityId === state.currentInfo.ContractId) {
                    const collection = state.chat.Items.map((message) => {
                        if (message.From === action.payload.UserId) {
                            return { ...message, Status: 1 };
                        }
                        return message;
                    });

                    return {
                        ...state,
                        chat: {
                            ...state.chat,
                            Items: collection,
                        },
                    };
                }
                return state;
            }
            return state;

        case C.DEAL_FEES_FETCHED:
            return {
                ...state,
                fees: action.payload,
            };

        case C.DEAL_TRANSACTIONS_FETCHED:
            return {
                ...state,
                transactions: action.payload,
            };

        case C.CLEAR_DEAL_DETAILED:
            return initialState;
        default:
            return state;
    }
};
