import i18next from 'i18next';
import { notify } from 'reapop';

export const errorNotifier =
    (msg, title = i18next.t('error'), position = 'br') =>
    (dispatch) =>
        dispatch(
            notify({
                title,
                message: msg,
                status: 'error',
                dismissible: true,
                dismissAfter: 5000,
                closeButton: true,
                allowHTML: true,
                position,
            })
        );

export const successNotifier =
    (msg, title = i18next.t('attention'), position = 'br', buttons = []) =>
    (dispatch) => {
        dispatch(
            notify({
                title,
                message: msg,
                status: 'success',
                dismissible: true,
                dismissAfter: 20000,
                position,
                allowHTML: true,
                closeButton: true,
                buttons,
            })
        );
    };

export const messageNotifier =
    (msg, title, buttons = []) =>
    (dispatch) => {
        dispatch(
            notify({
                title,
                message: msg,
                status: 'info',
                dismissible: true,
                dismissAfter: 20000,
                position: 'br',
                allowHTML: true,
                closeButton: true,
                buttons,
            })
        );
    };

export const warningNotifier = (msg) => (dispatch) => {
    dispatch(
        notify({
            message: msg,
            status: 'warning',
            dismissible: true,
            dismissAfter: 0,
            position: 'br',
            allowHTML: true,
            closeButton: true,
        })
    );
};

export const warningNotifierWithTimeOut = (msg) => (dispatch) => {
    dispatch(
        notify({
            message: msg,
            status: 'warning',
            dismissible: true,
            dismissAfter: 10000,
            position: 'br',
            allowHTML: true,
            closeButton: true,
        })
    );
};

export const errorNotifierWithRemove =
    (msg, removeAction, title = i18next.t('error'), position = 'br') =>
    (dispatch) =>
        dispatch(
            notify({
                title,
                message: msg,
                status: 'error',
                dismissible: true,
                dismissAfter: 5000,
                closeButton: true,
                allowHTML: true,
                onRemove() {
                    if (
                        typeof removeAction === 'function' &&
                        removeAction !== 'undefined'
                    ) {
                        removeAction();
                    }
                },
                position,
            })
        );

export const handleError = (error) => (dispatch) => {
    const { ErrorResourceKey, Code } = error;

    if (Code) {
        dispatch(errorNotifier(i18next.t(ErrorResourceKey)));
    } else {
        dispatch(errorNotifier(error));
    }
};

export const handleErrorWithRemove = (error, removeAction) => (dispatch) => {
    const { ErrorResourceKey, Code } = error;

    if (Code) {
        dispatch(
            errorNotifierWithRemove(i18next.t(ErrorResourceKey), removeAction)
        );
    } else {
        dispatch(errorNotifierWithRemove(error, removeAction));
    }
};
