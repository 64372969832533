import React from 'react';
import { render } from 'react-dom';
import './assets/toolbox/theme.css';
import './assets/css/index.scss';
import 'react-toggle/style.css';
import { Provider } from 'react-redux';
import { unregister } from 'registerServiceWorker';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { initWrapper } from 'methods/helpers/common';
import NotificationsSystem from 'reapop';
import theme from 'reapop-theme-wybo';
import store, { history } from './store/store';
import Layout from './App';
import InternalServerError from './components/StatusPages/500';

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.substr(position, searchString.length) === searchString;
    };
}
const renderError = () => {
    render(<InternalServerError />, document.getElementById('root'));
};

const renderApplication = () => {
    render(
        <Provider store={store}>
            <div>
                <NotificationsSystem theme={theme} />

                <ConnectedRouter history={history}>
                    <Route path="/" component={Layout} />
                </ConnectedRouter>
            </div>
        </Provider>,
        document.getElementById('root')
    );
};

initWrapper(store, renderApplication, renderError);

unregister();

// if (module.hot) {
//     module.hot.accept([
//         './App'
//     ], renderApplication);
// }

// registerServiceWorker();
