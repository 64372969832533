import React from 'react';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { dealStatusesDictionary } from './common';

export const getDealStatus = (deal) => {
    const { Status, InProgressState, ClosingType, DisputeReason } = deal;
    switch (Status) {
        case 0:
            return 'new';
        case 1:
            switch (InProgressState) {
                case 1:
                    return 'confirmedByPayee';
                case 2:
                    return 'confirmedByPayer';
                case 3:
                    return 'closing';
                default:
                    return null;
            }
        case 2:
            return 'disputed';
        //TODO: нижерасположенный код мне понадобится для реализации в новом эскроу
        // if (!DisputeReason) return 'disputed';
        // switch (DisputeReason) {
        //     //TODO: needs to be rewritten with numbers
        //     case 'ByInitiator':
        //         return 'disputedByInitiator';
        //     case 'ByPartner':
        //         return 'disputedByPartner';
        //     case 'Timeout':
        //         return 'disputedExpired';
        // }
        // break;
        case 3:
            if (!ClosingType) return 'resolved';
            switch (ClosingType) {
                case 11:
                    return 'arbitraryClosed';
                case 12:
                    return 'arbitraryCanceled';
                case 13:
                    return 'dealClosedInFavorOfPayee';
                case 14:
                    return 'dealClosedInFavorOfPayer';
            }
            break;
        case 4:
            if (!ClosingType || ClosingType === 1) return 'closed';
            return 'dealCanceled';
        case 5:
            return 'timeout';
        case 6:
            if (!ClosingType) return 'documentsRequired';
            switch (ClosingType) {
                case 1:
                    return 'payeeDocumentsRequiredDealIsClosing';
                case 11:
                case 13:
                    return 'payeeDocumentsRequiredArbitraryClosing';
                case 14:
                    return 'payerDocumentsRequiredArbitraryClosing';
                case 21:
                    return 'payeeDocumentsRequiredExpiredDealIsClosing';
            }
            break;
        case 9:
            if (!ClosingType) return 'returningMoney';
            switch (ClosingType) {
                case 1:
                    return 'returningMoneyDealClosing';
                case 2:
                    return 'returningMoneyDealCancelProcessing';
                case 11:
                case 13:
                case 14:
                    return 'returningMoneyArbitraryClosing';
                case 12:
                    return 'returningMoneyArbitraryCanceling';
                case 21:
                    return 'returningMoneyExpiredDealClosing';
                case 22:
                    return 'returningMoneyExpiredDealCanceling';
            }
    }
};

export const getContractStatus = (contract) => {
    const { Status, InProgressState } = contract;

    switch (Status) {
        case 1:
            return 'initiatorsContract';
        case 2:
            return 'partnersContract';
        case 4:
            return 'dealCreated';
        case 5:
            return 'closed';
        case 6:
            return 'expired';
        case 7:
            return 'returningMoney';
        default:
            switch (InProgressState) {
                case 1:
                    return 'acceptedByInitiator';
                case 5:
                    return 'paidByInitiator';
                case 7:
                    return 'partnerDepositRequired';
                case 2:
                    return 'acceptedByPartner';
                case 10:
                    return 'paidByPartner';
                case 11:
                    return 'initiatorDepositRequired';
                case 15:
                    return 'totallyAccepted';
            }
    }
};

export const getContractStatusDetailed = (contract, currentUser) => {
    const status = getContractStatus(contract);

    const initiatedByCurrentUser =
        currentUser.UserLogin === contract.Initiator.UserLogin;
    const isPublicOffer = contract.Partner === null;
    const hasTransactionsInProcess = contract.Transactions
        ? contract.Transactions.filter(
              (trans) => trans.Status === 2 || trans.Status === 0
          ).length !== 0
        : false;

    const userDeposit = initiatedByCurrentUser
        ? contract.DepositInitiator
        : contract.DepositPartner;
    const partnerDeposit = initiatedByCurrentUser
        ? contract.DepositPartner
        : contract.DepositInitiator;

    const { AllowNewConditions } = contract;

    if (isPublicOffer) {
        if (contract.Status !== 5) {
            if (initiatedByCurrentUser) {
                return (
                    <p className="message message_info">
                        {i18next.t('new_public_offer_detailed_text_2')}
                    </p>
                );
            }
            return (
                <p className="message message_info">
                    {i18next.t('new_public_offer_detailed_text')}
                </p>
            );
        }

        return null;
    }
    if (initiatedByCurrentUser) {
        switch (status) {
            case 'initiatorsContract':
                return (
                    <p className="message message_info">
                        {i18next.t('user_deal_offer_created')}
                    </p>
                );
            case 'partnersContract':
                return (
                    <p className="message message_info">
                        {AllowNewConditions
                            ? i18next.t('partner_created_new_deal_offer_2')
                            : i18next.t('partner_created_new_deal_offer')}
                    </p>
                );
            case 'returningMoney':
                return (
                    <p className="message message_info">
                        {i18next.t('return_in_progress')}
                    </p>
                );
            case 'acceptedByInitiator':
                return (
                    <p className="message message_info">
                        {i18next.t('offer_accepted_by_user_detailed')}
                    </p>
                );
            case 'acceptedByPartner':
                return (
                    <p className="message message_info">
                        {i18next.t('offer_accepted_by_partner_detailed')}
                    </p>
                );
            case 'paidByInitiator':
                return (
                    <p className="message message_info">
                        {userDeposit > 0
                            ? i18next.t('your_deposit_received_detailed')
                            : i18next.t('offer_accepted_by_user_detailed')}
                        &nbsp;
                        {partnerDeposit > 0 &&
                            i18next.t('partner_deposit_required_text')}
                        {hasTransactionsInProcess &&
                            i18next.t('user_payment_processing')}
                    </p>
                );
            case 'paidByPartner':
                return (
                    <p className="message message_info">
                        {partnerDeposit > 0
                            ? i18next.t('offer_paid_by_partner_detailed')
                            : i18next.t('offer_accepted_by_partner_detailed')}
                        &nbsp;
                        {userDeposit > 0 &&
                            i18next.t('user_deposit_required_text')}
                        {hasTransactionsInProcess &&
                            i18next.t('partner_payment_processing')}
                    </p>
                );
            case 'initiatorDepositRequired':
                return (
                    <p className="message message_info">
                        {i18next.t('user_deposit_required_detailed')}
                        &nbsp;
                        {hasTransactionsInProcess &&
                            i18next.t('partner_payment_processing')}
                    </p>
                );
            case 'partnerDepositRequired':
                return (
                    <p className="message message_info">
                        {i18next.t('partner_deposit_required_detailed')}
                        &nbsp;
                        {hasTransactionsInProcess &&
                            i18next.t('user_payment_processing')}
                    </p>
                );
            case 'totallyAccepted':
            case 'dealCreated':
                return (
                    <p className="message message_info">
                        {i18next.t('totally_accepted_detailed')}
                    </p>
                );
            case 'closed':
                return (
                    <p className="message message_info">
                        {i18next.t('contract_declined_detailed')}
                    </p>
                );
            default:
                return null;
        }
    } else {
        // isPartner
        switch (status) {
            case 'partnersContract':
                return (
                    <p className="message message_info">
                        {i18next.t('user_deal_offer_created')}
                    </p>
                );
            case 'initiatorsContract':
                return (
                    <p className="message message_info">
                        {AllowNewConditions
                            ? i18next.t('partner_created_new_deal_offer_2')
                            : i18next.t('partner_created_new_deal_offer')}
                    </p>
                );
            case 'returningMoney':
                return (
                    <p className="message message_info">
                        {i18next.t('return_in_progress')}
                    </p>
                );
            case 'acceptedByPartner':
                return (
                    <p className="message message_info">
                        {i18next.t('offer_accepted_by_user_detailed')}
                    </p>
                );
            case 'acceptedByInitiator':
                return (
                    <p className="message message_info">
                        {i18next.t('offer_accepted_by_partner_detailed')}
                    </p>
                );

            case 'paidByInitiator':
                return (
                    <p className="message message_info">
                        {partnerDeposit > 0
                            ? i18next.t('offer_paid_by_partner_detailed')
                            : i18next.t('offer_accepted_by_partner_detailed')}
                        &nbsp;
                        {userDeposit > 0 &&
                            i18next.t('user_deposit_required_text')}
                        {hasTransactionsInProcess &&
                            i18next.t('partner_payment_processing')}
                    </p>
                );

            case 'paidByPartner':
                return (
                    <p className="message message_info">
                        {userDeposit > 0
                            ? i18next.t('your_deposit_received_detailed')
                            : i18next.t('offer_accepted_by_user_detailed')}
                        &nbsp;
                        {partnerDeposit > 0 &&
                            i18next.t('partner_deposit_required_text')}
                        {hasTransactionsInProcess &&
                            i18next.t('user_payment_processing')}
                    </p>
                );

            case 'initiatorDepositRequired':
                return (
                    <p className="message message_info">
                        {i18next.t('partner_deposit_required_detailed')}
                        &nbsp;
                        {hasTransactionsInProcess &&
                            i18next.t('user_payment_processing')}
                    </p>
                );

            case 'partnerDepositRequired':
                return (
                    <p className="message message_info">
                        {i18next.t('user_deposit_required_detailed')}
                        &nbsp;
                        {hasTransactionsInProcess &&
                            i18next.t('partner_payment_processing')}
                    </p>
                );

            case 'totallyAccepted':
            case 'dealCreated':
                return (
                    <p className="message message_info">
                        {i18next.t('totally_accepted_detailed')}
                    </p>
                );
            case 'closed':
                return (
                    <p className="message message_info">
                        {i18next.t('contract_declined_detailed')}
                    </p>
                );
            default:
                return null;
        }
    }
};

export const getDealStatusDetailed = (deal, currentUser) => {
    const status = getDealStatus(deal);
    const isInitiator = currentUser.UserLogin === deal.Initiator.UserLogin;
    const isPayee =
        (isInitiator && deal.InitiatorRole === 'Payee') ||
        (!isInitiator && deal.InitiatorRole === 'Payer');

    const { DepositToPayee } = deal;

    switch (status) {
        case 'new':
            return (
                <p className="message message_info">
                    {i18next.t('deal_status_detailed')}
                </p>
            );
        case 'confirmedByPayee':
            if (isPayee) {
                return (
                    <p className="message message_info">
                        {i18next.t('deal_confirmed_by_payee_status')}
                    </p>
                );
            }
            return (
                <p className="message message_info">
                    {i18next.t('deal_confirmed_by_payee_status_2')}
                </p>
            );
        case 'confirmedByPayer':
            if (!isPayee) {
                return (
                    <p className="message message_info">
                        {i18next.t('deal_confirmed_by_payer_status')}
                    </p>
                );
            }
            return (
                <p className="message message_info">
                    {i18next.t('deal_confirmed_by_payer_status_2')}
                    {!DepositToPayee &&
                        i18next.t('deal_confirmed_by_payer_status_3')}
                </p>
            );

        case 'disputed':
            if (
                (isInitiator && deal.DisputeReason === 'ByInitiator') ||
                (!isInitiator && deal.DisputeReason === 'ByPartner')
            ) {
                return (
                    <p className="message message_info">
                        {i18next.t('disputed_status_detailed')}
                    </p>
                );
            }
            return (
                <p className="message message_info">
                    {i18next.t('disputed_status_detailed_2')}
                </p>
            );

        case 'timeout':
            return (
                <p className="message message_info">
                    {i18next.t('deal_timeout_detailed_status')}
                </p>
            );
        case 'resolved':
            return (
                <p className="message message_info">
                    {i18next.t('deal_resolved_detailed_status')}
                </p>
            );
        case 'closed':
            return (
                <p className="message message_info">
                    {i18next.t('deal_closed_detailed_status')}
                </p>
            );

        case 'documentsRequired':
        case 'payeeDocumentsRequiredDealIsClosing':
        case 'payeeDocumentsRequiredArbitraryClosing':
        case 'payerDocumentsRequiredArbitraryClosing':
        case 'payeeDocumentsRequiredExpiredDealIsClosing':
            if (isPayee) {
                return (
                    <div className="message message_info">
                        <p>
                            {i18next.t('documents_required_detailed_status')}
                            &nbsp;
                            <div>
                                Перейдите в&nbsp;
                                <Link to="/profile" className="underlined">
                                    профиль
                                </Link>
                                , чтобы добавить паспортные данные
                            </div>
                        </p>
                    </div>
                );
            }
            return (
                <p className="message message_info">
                    {i18next.t('documents_required_detailed_status_2')}
                </p>
            );

        case 'returningMoney':
        case 'returningMoneyDealClosing':
        case 'returningMoneyDealCancelProcessing':
        case 'returningMoneyArbitraryClosing':
        case 'returningMoneyArbitraryCanceling':
        case 'returningMoneyExpiredDealClosing':
        case 'returningMoneyExpiredDealCanceling':
            return (
                <p className="message message_info">
                    {dealStatusesDictionary[status]}
                </p>
            );

        default:
            return null;
    }
};
