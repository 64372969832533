import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import i18next from 'i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import v1 from 'uuid/v1';
import { MdArrowDropDown, MdArrowDropUp, MdClose } from 'react-icons/md';
import { handleError } from '../../../methods/helpers/notifications';
import {
    detachAccount,
    fastLoginFailed,
    fastLoginSucceded,
    openModal,
} from '../../../methods/actions/actionCreators';
import {
    changeAccount,
    handleLogout,
    refreshToken,
    userAuthenticated,
} from '../../../methods/api/session';
import {
    getUserAvatar,
    getUserLogin,
    getUserName,
    getDomainType,
} from '../../../methods/helpers/common';
import SocialIconsBlock from '../SocialIconsBlock/SocialIconsBlock';

class UserBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userAuthBlockOpened: false,
        };
        this.userMenuSelect = this.userMenuSelect.bind(this);
        this.renderUserAccountBlock = this.renderUserAccountBlock.bind(this);
        this.changeAccount = this.changeAccount.bind(this);
        this.toggleUserAuthBlock = this.toggleUserAuthBlock.bind(this);
        this.hideUserAuthBlock = this.hideUserAuthBlock.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    userMenuSelect(values) {
        const { openModal, history } = this.props;
        switch (values) {
            case 'profile':
                return history.push('/profile');
            case 'logout':
                return openModal('logout');
            case 'impersonation':
                return openModal('impersonation');
        }
    }

    changeAccount(accountData) {
        const { user, changeAccount } = this.props;
        if (accountData.UserLogin !== user.UserLogin) {
            this.hideUserAuthBlock();

            changeAccount(accountData);
        }
        return null;
    }

    renderUserAccountBlock(accountData) {
        const { user, openModal, detachAccount } = this.props;
        const isCurrentUser = accountData.UserLogin === user.UserLogin;
        const accountClassName = classNames({
            'user-accounts__item': true,
            active: isCurrentUser,
        });

        const isAdmin =
            accountData.Roles && accountData.Roles.includes('SiteAdmin');

        return (
            <div className={accountClassName} key={v1()}>
                {getUserAvatar(
                    accountData.AvatarBase64,
                    accountData.Profile.LoginProvider
                )}

                <div
                    className="user-info"
                    onClick={() =>
                        this.changeAccount({
                            ...accountData,
                            ...accountData.Profile,
                        })
                    }>
                    {/*<p className="user-name">*/}
                    {/*    {getUserName({*/}
                    {/*        ...accountData,*/}
                    {/*        ...accountData.Profile,*/}
                    {/*    })}*/}
                    {/*</p>*/}
                    {/*<p className="user-login">*/}
                    {/*    {getUserLogin({*/}
                    {/*        ...accountData,*/}
                    {/*        ...accountData.Profile,*/}
                    {/*    })}*/}
                    {/*</p>*/}
                    <div className="buttons-block">
                        {accountData.UserLogin === user.UserLogin && [
                            <Link
                                to="/profile"
                                key={v1()}
                                onClick={() => {
                                    this.hideUserAuthBlock();
                                }}
                                className="link blue">
                                {i18next.t('go_to_profile')}
                            </Link>,
                            <a
                                className="link dark"
                                onClick={() => {
                                    this.hideUserAuthBlock();
                                    openModal('logout');
                                }}
                                key={v1()}>
                                {i18next.t('logout_text')}
                            </a>,
                            isAdmin && (
                                <a
                                    className="link impersonation"
                                    key={v1()}
                                    onClick={() => {
                                        this.hideUserAuthBlock();
                                        openModal('impersonation');
                                    }}>
                                    {i18next.t('login_via_another_account')}
                                </a>
                            ),
                        ]}
                    </div>
                </div>
                {!isCurrentUser && (
                    <MdClose
                        onClick={() =>
                            openModal(
                                'confirm-action',
                                accountData,
                                () => detachAccount(accountData),
                                `${i18next.t(
                                    'detach_account'
                                )} <b class="bold">${getUserName({
                                    ...accountData,
                                    ...accountData.Profile,
                                })}</b>?`
                            )
                        }
                    />
                )}
            </div>
        );
    }

    toggleUserAuthBlock() {
        const { userAuthBlockOpened } = this.state;
        this.setState({
            userAuthBlockOpened: !userAuthBlockOpened,
        });
    }

    hideUserAuthBlock() {
        this.setState({
            userAuthBlockOpened: false,
        });
    }

    handleClickOutside(event) {
        if (this.userAuthBlock && !this.userAuthBlock.contains(event.target)) {
            this.hideUserAuthBlock();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    render() {
        const { openModal, user, loginHistory } = this.props;
        const { userAuthBlockOpened } = this.state;

        if (user.loggedIn) {
            return (
                <div className="user-block">
                    {getUserAvatar(
                        user.AvatarBase64,
                        user.Profile.LoginProvider
                    )}

                    <div
                        className="user-accounts-wrapper"
                        ref={(el) => (this.userAuthBlock = el)}>
                        <a onClick={this.toggleUserAuthBlock}>
                            {/*{user.Profile.FirstName*/}
                            {/*    ? getUserName(user.Profile)*/}
                            {/*    : getUserLogin({ ...user, ...user.Profile })}*/}

                            {getUserLogin({ ...user, ...user.Profile })}

                            {userAuthBlockOpened ? (
                                <MdArrowDropUp />
                            ) : (
                                <MdArrowDropDown />
                            )}
                        </a>
                        {userAuthBlockOpened && (
                            <div className="user-accounts animated fadeInUp">
                                {loginHistory.map((el) =>
                                    this.renderUserAccountBlock(el)
                                )}
                            </div>
                        )}
                    </div>
                </div>
            );
        }
        return (
            <div className="user-block">
                {getDomainType() !== 3 && (
                    <SocialIconsBlock type="flat" {...this.props} />
                )}
                {getDomainType() !== 3 && (
                    <button
                        onClick={() => openModal('login')}
                        className="navigation-item login">
                        {i18next.t('login_text')}
                    </button>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ loginHistory }) => ({
    loginHistory: loginHistory.slice().reverse(),
});

export default connect(mapStateToProps, {
    openModal,
    fastLoginSucceded,
    detachAccount,
    fastLoginFailed,
    handleError,
    handleLogout,
    refreshToken,
    userAuthenticated,
    changeAccount,
})(UserBlock);
