import React from 'react';
import BlockLoader from '../../../components/Shared/BlockLoader';

export default function (props) {
    return props.error ? (
        <div>
            Error!
            <button onClick={props.retry}>Retry</button>
        </div>
    ) : (
        <BlockLoader />
    );
}
