import React, { useEffect, useState } from 'react';

import './DropButton.scss';
import { MdKeyboardArrowRight } from 'react-icons/md';

export default function DropButton({ label, children }) {
    const [btnRef, setBtnRef] = useState(undefined);
    const [isDropdownHidden, setIsDropdownHidden] = useState(true);

    useEffect(() => {
        function handleClickOutside(event) {
            if (btnRef && !btnRef.contains(event.target))
                setIsDropdownHidden(true);
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [btnRef]);

    return (
        <div
            className="esc-dropdown-button-wrapper"
            ref={(el) => setBtnRef(el)}>
            <div
                className={`esc-button sm raised${
                    isDropdownHidden ? '' : ' active'
                }`}
                onClick={() => setIsDropdownHidden(!isDropdownHidden)}>
                <span>{label}</span>
            </div>
            <div
                className={`esc-button-dropdown ${
                    isDropdownHidden ? 'hidden' : ''
                }`}>
                {children}
            </div>
        </div>
    );
}

export var DropButtonItem = function ({ children }) {
    return <div className="esc-button-dropdown__item">{children}</div>;
};

export var DropButtonGroup = function ({ label, icon, children }) {
    const render = () => (
        <div className="esc-button-dropdown__item group">
            {icon}
            <span>{label}</span>
            {React.Children.count(children) > 0 && (
                <MdKeyboardArrowRight className="blue" size="24px" />
            )}
            <div className="esc-button-dropdown-horizontal">{children}</div>
        </div>
    );

    return (
        <div className="esc-horizontal-dropdown-button-wrapper">{render()}</div>
    );
};
