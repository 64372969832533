import C from '../../constants/actionTypes';

export const newMessage = (message) => ({
    type: C.NEW_MESSAGE,
    payload: message,
});

export const clearChatMessages = () => ({
    type: C.CLEAR_CHAT_MESSAGES,
});

/**
 *
 * @param data
 * @param {number} data.EntityId
 * @param {Object} data.EntityData
 * @returns {{payload, type: string}}
 */
export const openChat = (data) => ({
    type: C.CHAT_OPENED,
    payload: data,
});

export const closeChat = () => ({
    type: C.CHAT_CLOSED,
});
