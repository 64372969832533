module.exports = {
    RTAutocomplete: {
        autocomplete: '_2ABh4',
        focus: 'UtZg6',
        suggestions: '_1aWmm',
        values: '_33_V_',
        value: '_3PZTI',
        up: '_1FQQg',
        suggestion: 'IsqUe',
        active: '_3-1G8',
        input: '_3ibDW',
    },
    RTChip: {
        chip: '_3hYjZ',
        avatar: 'LDKle',
        deletable: '_1Iko6',
        delete: '_39CMK',
        deleteIcon: '_2uaR7',
        deleteX: 'pzVqK',
    },
    RTAvatar: { avatar: '_38EDJ', image: '_3bYD4', letter: '_2QFuT' },
    RTInput: {
        input: '_2dBwA',
        withIcon: '_2uwUs',
        icon: '_2H9rJ',
        inputElement: '_2WvFs',
        bar: 'fT1WI',
        label: '_3NjcG',
        fixed: '_1ANNN',
        required: 'HMiz1',
        hint: '_1yQnr',
        filled: '_3QmiH',
        error: '_1p4yC',
        counter: '_2dI1B',
        disabled: '_2sOZC',
        errored: 'ZsBmg',
        hidden: '_3Wr_7',
    },
    RTButton: {
        button: '_1T6gd',
        rippleWrapper: '_3rch8',
        squared: 'x_Fgj',
        icon: 't6_L8',
        solid: 'HUuyg',
        raised: '_33l7R _1T6gd x_Fgj HUuyg',
        flat: '_2JPw5 _1T6gd x_Fgj',
        floating: '_2U_a5 _1T6gd HUuyg',
        mini: '_2for7',
        toggle: '_3xRDd _1T6gd',
        primary: '_29RI9',
        accent: 'mWBhu',
        neutral: '_3ViU3',
        inverse: '_1kgaQ',
    },
    RTRipple: {
        rippleWrapper: 'cNe4x',
        ripple: '_1zJTi',
        rippleRestarting: '_3wQEe',
        rippleActive: '_1eRuo',
    },
    RTAppBar: {
        appBar: '_3gTh_',
        scrollHide: '_3S2IT',
        flat: 'tf7de',
        fixed: '_3jqvO',
        inner: 'p2nb7',
        title: '_3Tmun',
        leftIcon: 'HDVfX',
        rightIcon: '_2DC6l',
    },
    RTNavigation: { horizontal: '_1LRT1', vertical: '_1A_xp' },
    RTLink: { icon: '_15_xe', link: '_2EYXG', active: '_5HYX' },
    RTDropdown: {
        dropdown: '_2nYq6',
        active: '_2S3xy',
        values: '_2_qrE',
        label: '_1yoLv',
        value: '_3VvbF',
        up: '_2Kq9b',
        disabled: '_2TqTb',
        field: 'ICLRf',
        errored: 'AwPYj',
        templateValue: 'VVpyX',
        required: '_2FdbD',
        error: '_3OQEk',
        selected: '_2u-08',
    },
    RTDatePicker: {
        input: '_2cfff',
        disabled: '_3PC3_',
        inputElement: 'xD_FE',
        header: 'yZhG0',
        year: 'uhnZd',
        date: '_3wNVO',
        calendarWrapper: '_2zBGJ',
        yearsDisplay: 'SV0v0',
        monthsDisplay: '_2-Gxw',
        dialog: 'lOLkB',
        button: '_50klV',
        calendar: '_1juUq',
        prev: 'PGGSb',
        next: '_3wsgQ',
        title: '_37fII',
        years: '_1yrr_',
        active: '_2jTt2',
        week: '_1wp1K',
        days: '_1JzI7',
        day: '_349-w',
        month: '_1mUHN',
        slideRightEnter: '_1tJhJ',
        slideRightLeave: '_1pz4X',
        slideRightEnterActive: 'fAUzE',
        slideRightLeaveActive: '_33v8X',
        slideLeftEnter: 'mOLI0',
        slideLeftLeave: '_2uLcH',
        slideLeftEnterActive: '_3gdF0',
        slideLeftLeaveActive: '_1lAUa',
    },
    RTDialog: {
        wrapper: '_3niLM',
        dialog: '_17Ijy',
        active: '_1594s',
        small: '_3AQIo',
        normal: '_3wffD',
        large: '_3L5Sk',
        fullscreen: '_2F8R7',
        title: '_27QqA',
        body: '_33od4',
        navigation: 'kA5VY',
        button: '_2r12z',
    },
    RTOverlay: { overlay: '_1kTMH', active: '_3vAcK' },
    RTTable: {
        table: '_2lKPo',
        head: '_3NBPO',
        row: '_2RPRo',
        selected: '_39sOM',
        rowCell: 'm8wFo',
        headCell: '_0571',
        numeric: '_23c5x',
        checkboxCell: '_1OBJ7',
        sorted: '_2IABS',
        sortIcon: '_1Nekt',
        asc: '_1OKJ4',
    },
    RTCheckbox: {
        field: 'IeUsG',
        ripple: '_1Bb8R',
        text: '_1rFrA',
        input: '_3VVqt',
        check: '_2NInN',
        checked: '_2vmMX',
        'checkmark-expand': '_20KUO',
        disabled: '_3UeyZ',
    },
    RTMenu: {
        iconMenu: 'Q-Nf7',
        icon: '_1G8xH',
        menu: '_3P9oU',
        topLeft: '_2HxoV',
        outline: '_2cbFB',
        topRight: 'mk08K',
        bottomLeft: 'jaitc',
        bottomRight: '_1BKDH',
        static: '_22s5Q',
        menuInner: '_3ofqr',
        rippled: '_2qIq5',
        active: '_2Y5eh',
        menuItem: 'gEXk4',
        disabled: '_3P4e_',
        selected: 'vZDAj',
        ripple: '_2BVPP',
        caption: '_3rIMg',
        shortcut: '_2dXIu',
        menuDivider: '_2v2RW',
    },
    RTTabs: {
        tabs: 'hYjnr',
        navigation: '_2mBOU',
        navigationContainer: 'XpGXw',
        arrow: '_2xhCz',
        arrowContainer: 'mXIXt',
        label: '_1dY3Q',
        rippleWrapper: '_28_T4',
        active: '_3YHoU',
        disabled: '_2DjLo',
        hidden: '_3Wexx',
        withIcon: 'IHcax',
        withText: '_1gdOy',
        icon: '_2as-P',
        pointer: 'JDp7A',
        tab: '_1Mp2k',
        fixed: 'qppbf',
        inverse: '_3uJ__',
    },
    RTRadio: {
        radio: '_1OD1h',
        ripple: 'PiAJb',
        radioChecked: '_3TWED _1OD1h',
        field: 'mtpyA',
        text: '_2S6ia',
        input: '_3d0X5',
        disabled: 'Xz2qO mtpyA',
    },
};
