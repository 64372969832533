import { combineReducers } from 'redux';
import C from '../../constants/actionTypes';

const items = (state = [], action) => {
    switch (action.type) {
        case C.PUBLIC_CONTRACT_CREATED:
            return [...state, action.payload];
        case C.CONTRACT_DECLINED_BY_PARTNER:
        case C.CONTRACT_DECLINED_BY_USER:
            // case C.CONTRACT_ACCEPTED:
            return state.filter(
                (contract) => contract.Id !== action.payload.Id
            );
        // case C.PUBLIC_CONTRACT_ACCEPTED:
        //     const acceptedContract = state.filter(contract => contract.Id === action.payload);
        //     if(acceptedContract.length > 0){
        //         const {PublicContractsCount} = acceptedContract[0];
        //         const filteredList = state.filter(contract => contract.Id !== action.payload);
        //
        //         if(PublicContractsCount && PublicContractsCount > 1){
        //             return state;
        //         }else{
        //             return filteredList;
        //         }
        //     }else{
        //         return state;
        //     }

        case C.PUBLIC_CONTRACTS_FETCHED:
            return [...action.payload.Items];

        case C.PUBLIC_CONTRACTS_CLEAR:
            return [];
        default:
            return state;
    }
};
const pagination = (state = {}, action) => {
    switch (action.type) {
        case C.PUBLIC_CONTRACTS_FETCHED:
            return action.payload.Pagination;
        default:
            return state;
    }
};

export default combineReducers({
    items,
    pagination,
});
