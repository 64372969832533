import React, { Component } from 'react';
import { GoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FacebookLogin from 'react-facebook-login';
import { FaFacebookF, FaGoogle, FaVk } from 'react-icons/fa';
import Cookies from 'js-cookie';
import {
    errorNotifier,
    handleError,
} from '../../../methods/helpers/notifications';
import URLS from '../../../constants/urls';
import { openModal, closeModal } from '../../../methods/actions/actionCreators';
import {
    processWMAuthorization,
    handleFacebookAuth,
    googleAuth,
} from '../../../methods/api/session';

class SocialIconsBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            termsAccepted: false,
        };
        this.handleGoogleAuth = this.handleGoogleAuth.bind(this);
        this.handleFacebookAuth = this.handleFacebookAuth.bind(this);
    }

    handleFacebookAuth(response) {
        const { handleFacebookAuth, closeModal, openModal } = this.props;
        const authData = {
            ExternalId: response.userID,
            AccessToken: response.accessToken,
            LoginProvider: 'Facebook',
            Email: response.email,
            EditFormWasShown: false,
            FirstName: response.first_name,
            Surname: response.last_name,
        };
        handleFacebookAuth(authData, openModal, closeModal);
    }

    handleGoogleAuth(response) {
        const { googleAuth } = this.props;

        const authData = {
            ExternalId: response.profileObj.googleId,
            AccessToken: response.accessToken,
            LoginProvider: 'Gmail',
            EditFormWasShown: false,
            Email: response.profileObj.email,
            FirstName: response.profileObj.givenName,
            Surname: response.profileObj.familyName,
        };

        googleAuth(authData);
    }

    componentDidMount() {
        const cookies = Cookies.get();
        if (Object.keys(cookies).length > 0) {
            if (cookies.TERMS_ACCEPTED) {
                this.setState({
                    termsAccepted: true,
                });
            }
        }
    }

    render() {
        const {
            errorNotifier,
            type,
            processWMAuthorization,
            openModal,
            btcRestricted,
        } = this.props;
        const menuClassName = classNames({
            'button-toolbar': true,
            rhombus: type === 'rhombus',
            flat: type === 'flat',
        });

        const { termsAccepted } = this.state;
        return (
            <div className={menuClassName}>
                <span
                    className="button-toolbar__icon active webmoney"
                    onClick={() => {
                        if (termsAccepted) {
                            processWMAuthorization();
                        } else {
                            openModal('terms-acceptance');
                        }
                    }}>
                    <i />
                </span>

                {/* <FacebookLogin appId="1444869405625477" */}
                {/*               textButton={<FaFacebookF/>} */}
                {/*               cssClass="button-toolbar__icon facebook" */}
                {/*               fields="first_name,last_name,email,picture" */}
                {/*               redirectUri={window.location.href} */}
                {/*               callback={this.handleFacebookAuth}/> */}

                {/* <GoogleLogin */}
                {/*    clientId="1086190880513-n1uf8cl49dbkf6tu3250ooebi96ilk7f.apps.googleusercontent.com" */}
                {/*    render={props => <button className="button-toolbar__icon google" onClick={props.onClick}><FaGoogle/> */}
                {/*    </button>} */}
                {/*    buttonText={<FaGoogle/>} */}
                {/*    redirectUri={process.env.NODE_ENV === 'development' ? */}
                {/*        URLS.GMAIL_REDIRECT_URL_LOCAL */}
                {/*        : btcRestricted ? URLS.GMAIL_REDIRECT_URL_PRODUCTION_RU : URLS.GMAIL_REDIRECT_URL_PRODUCTION} */}
                {/*    onFailure={data => errorNotifier(data.error)} */}
                {/*    onSuccess={this.handleGoogleAuth}/> */}

                {/* <a className="button-toolbar__icon active vk" */}
                {/*   href={`https://oauth.vk.com/authorize?client_id=6333866&display=popup&scope=email,first_name,last_name&redirect_uri=${process.env.NODE_ENV === 'development' ? URLS.VK_REDIRECT_URL_LOCAL */}
                {/*       : btcRestricted ? URLS.VK_REDIRECT_URL_PRODUCTION_RU : URLS.VK_REDIRECT_URL_PRODUCTION}&response_code=token&v=5.78`}> */}
                {/*    <FaVk/> */}
                {/* </a> */}
            </div>
        );
    }
}

SocialIconsBlock.propTypes = {
    type: PropTypes.string.isRequired,
};

const mapStateToProps = ({ btcRestricted }) => ({ btcRestricted });

export default connect(mapStateToProps, {
    googleAuth,
    openModal,
    handleFacebookAuth,
    processWMAuthorization,
    errorNotifier,
    closeModal,
    handleError,
})(SocialIconsBlock);
