import C from '../../constants/actionTypes';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case C.CHAT_OPENED:
            return action.payload;

        case C.CHAT_CLOSED:
            return initialState;

        case C.MESSAGES_FETCHED:
            return {
                ...state,
                ...action.payload,
            };
        case C.SENT_MESSAGES_READ:
            if (state.EntityId && state.EntityId === action.payload.EntityId) {
                const collection = state.Items.map((message) => {
                    if (message.From === action.payload.UserId) {
                        return { ...message, Status: 1 };
                    }
                    return message;
                });
                return {
                    ...state,
                    Items: collection,
                };
            }
            return state;

        case C.INCOMING_MESSAGES_READ:
            if (state.EntityId && state.EntityId === action.payload.EntityId) {
                const collection = state.Items.map((message) => {
                    if (message.From !== action.payload.UserId) {
                        return { ...message, Status: 1 };
                    }
                    return message;
                });
                return {
                    ...state,
                    Items: collection,
                };
            }
            return state;

        case C.NEW_MESSAGE:
            if (
                state.EntityId &&
                state.EntityId === action.payload.BindedObjectId
            ) {
                return {
                    ...state,
                    Items: [action.payload, ...state.Items],
                };
            }

            return state;

        default:
            return state;
    }
};
