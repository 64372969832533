import { isEmpty } from 'lodash';
import Cookies from 'js-cookie';
import moment from 'moment';
import { change, destroy, initialize } from 'redux-form';
import i18next from 'i18next';
import param from 'jquery-param';
import C from '../../constants/actionTypes';
import {
    closeModal,
    openModal,
    userLoggedOut,
    fastLoginFailed,
} from '../actions/actionCreators';
import URLS from '../../constants/urls';
import {
    handleDataReceived,
    handleDataReceivedAlt,
    handleServerResponse,
    sendData,
    wsConnect,
} from '../helpers/common';
import {
    errorNotifier,
    handleError,
    successNotifier,
    warningNotifierWithTimeOut,
} from '../helpers/notifications';
import { fetchFormValidations } from './settings';
import { fetchUserProfile, fetchUserTransactions } from './profile';
import { history } from '../../store/store';
import cookiesNames from '../../constants/cookiesNames';

const { $ } = window;

const setRefreshTokenTimer = (userAuthData) => (dispatch) => {
    const timeout = 7200000; // 12hours
    window.refreshTimer = setTimeout(() => {
        dispatch(refreshToken(userAuthData)).catch(() =>
            dispatch(handleLogout())
        );
    }, timeout);
};

const redirectUserToReferrerPage = (url) => (dispatch) => {
    url && history.push(url);
    dispatch({ type: C.CLEAR_REDIRECT_URL });
};

export const userAuthenticated =
    (responseData, isRefreshed = false) =>
    (dispatch) => {
        moment.locale('en');
        const RefreshExpires = responseData.RefreshExpires.replace(/\+/gi, ' ');
        const AccessExpires = responseData.AccessExpires.replace(/\+/gi, ' ');
        const AccessIssued = responseData.AccessIssued.replace(/\+/gi, ' ');
        const RefreshIssued = responseData.RefreshIssued.replace(/\+/gi, ' ');

        const authData = {
            ...responseData,
            RefreshExpires,
            AccessExpires,
            AccessIssued,
            RefreshIssued,
        };

        dispatch(setRefreshTokenTimer(authData));

        if (isRefreshed) {
            dispatch({
                type: C.TOKEN_REFRESHED,
                payload: authData,
            });
        } else {
            dispatch({
                type: C.AUTHENTICATED,
                payload: authData,
            });

            dispatch(openModal('loading'));

            return Promise.all([
                dispatch(wsConnect(authData)),
                dispatch(fetchUserProfile(authData)),
                dispatch(fetchUserTransactions(0, 10)),
                dispatch(fetchFormValidations()),
            ])
                .then(() => {
                    dispatch({
                        type: C.USER_LOGGED_IN,
                    });

                    const lStorage = JSON.parse(localStorage.getItem('state'));
                    const {
                        redirectUrl: { url },
                        publicContractAcceptanceTriggered,
                    } = lStorage;
                    url && dispatch(redirectUserToReferrerPage(url));
                    dispatch(closeModal());

                    if (publicContractAcceptanceTriggered.Id) {
                        dispatch(
                            openModal(
                                'accept-contract',
                                publicContractAcceptanceTriggered
                            )
                        );
                    }
                })
                .catch((err) => {
                    dispatch(handleError(err));
                    dispatch(closeModal());
                });
        }
    };

export const handleLogout = () => (dispatch) => {
    const cookies = Cookies.get();

    if (!isEmpty(cookies)) {
        Object.keys(cookies).map((key) => {
            key !== 'TERMS_ACCEPTED' && Cookies.remove(key);
        });
    }

    if (window.scrollY > 0) {
        window.scrollTo(0, 0);
        sessionStorage.clear();
    }
    if (window.refreshTimer) {
        window.clearTimeout(window.refreshTimer);
    }
    dispatch(userLoggedOut());
    // history.replace('/');
    // setTimeout(()=>{    $.connection.hub.stop();
    // },200);
};

export const updateSession = (userData) => (dispatch) => {
    dispatch(refreshToken(userData))
        .then(() => dispatch(closeModal()))
        .catch((error) => {
            dispatch(handleError(error));
            dispatch(closeModal());
            setTimeout(() => {
                dispatch(handleLogout());
            }, 1500);
        });
};

export const refreshToken = (userAuthData) => (dispatch) => {
    const { RefreshToken, UserLogin } = userAuthData;

    return fetch(URLS.REFRESH_TOKEN_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: param({
            RefreshToken,
            UserLogin,
        }),
    })
        .then(handleServerResponse)
        .then(handleDataReceived)
        .then((refreshedData) => {
            if (refreshedData.error) {
                return Promise.reject();
            }
            dispatch(userAuthenticated(refreshedData, true));
            return refreshedData;
        });
};

export const userVerification = (userAuthData) => (dispatch) =>
    new Promise((resolve, reject) => {
        moment.locale('en');
        const { RefreshExpires, AccessExpires, AccessToken, RefreshToken } =
            userAuthData;
        const currentTime = moment();
        const accessExpirationDate = moment(AccessExpires);
        const refreshExpirationDate = moment(RefreshExpires);

        const accessWillExpireIn = accessExpirationDate.diff(
            currentTime,
            'milliseconds'
        );
        const minDuration = 60000;

        const accessExpired =
            accessExpirationDate.diff(currentTime, 'minutes') <= 1 ||
            AccessToken === null;
        const refreshExpired =
            refreshExpirationDate.diff(currentTime, 'minutes') <= 0 ||
            RefreshToken === null;

        if (refreshExpired) {
            reject();
        } else if (accessExpired) {
            dispatch(refreshToken(userAuthData))
                .then((data) => {
                    resolve(data);
                })
                .catch(() => {
                    console.error('accessExpired, refreshToken failed');
                    reject();
                });
        } else {
            const refreshTimeout =
                accessWillExpireIn > minDuration
                    ? accessWillExpireIn - minDuration / 2
                    : 1000;

            setTimeout(() => {
                dispatch(refreshToken(userAuthData));
            }, refreshTimeout);
            resolve();
        }
    });

export const changeAccount = (accountData) => (dispatch) => {
    const { RefreshToken, UserLogin } = accountData;

    fetch(URLS.REFRESH_TOKEN_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: param({
            RefreshToken,
            UserLogin,
        }),
    })
        .then(handleServerResponse)
        .then(handleDataReceived)
        .then((refreshedData) => {
            $.connection.hub.stop();
            dispatch(handleLogout());
            if (refreshedData.error) {
                return Promise.reject();
            }
            return dispatch(userAuthenticated(refreshedData));
        })
        .catch(() => {
            dispatch(openModal('login', accountData));
            dispatch(fastLoginFailed(accountData));
        });
};

export const loginWithAnotherAccount = (data, props) => (dispatch) =>
    sendData('POST', URLS.LOGIN_WITH_ANOTHER_ACCOUNT, data)
        .then((authData) => {
            const {
                closeModal,
                location: { pathname },
            } = props;
            $.connection.hub.stop();

            closeModal();
            dispatch({
                type: C.SET_REDIRECT_URL,
                payload: pathname,
            });
            dispatch(userAuthenticated(authData, false));
        })
        .catch((err) => dispatch(handleError(err)));

export const handleLoginPassAuth = (authData) => (dispatch) =>
    fetch(URLS.USER_AUTH_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: param(authData),
    })
        .then((data) => handleServerResponse(data))
        .then((data) => handleDataReceived(data))
        .then((data) => {
            dispatch(closeModal());
            dispatch(destroy('LoginForm'));
            dispatch(userAuthenticated(data));
        });

export const processWMAuthorization = () => (dispatch) => {
    dispatch(openModal('loading'));

    const lStorage = JSON.parse(localStorage.getItem('state'));
    const { btcRestricted } = lStorage;

    if (process.env.NODE_ENV === 'development') {
        const url = URLS.WM_LOGIN_LOCAL;
        console.log(url);
        window.location = URLS.WM_LOGIN_LOCAL;
    } else if (btcRestricted) {
        window.location = URLS.WM_LOGIN_PRODUCTION_RU;
    } else {
        window.location = URLS.WM_LOGIN_PRODUCTION;
    }
};

export const handleWMAuthorization =
    (data, handleModalOpen, handleModalClose) => (dispatch) => {
        const authCookie = JSON.parse(decodeURIComponent(data));

        if (authCookie.IsSuccess) {
            dispatch(userAuthenticated(authCookie.Data, false));
            Cookies.remove(cookiesNames.WEBMONEY_AUTH);
        } else {
            return dispatch(errorNotifier(i18next.t('login_fail_text')));
        }
    };

export const handleFacebookAuth = (authData) => (dispatch) => {
    dispatch(openModal('loading'));

    if (!authData.EditFormWasShown) {
        dispatch(initialize('SocialAuthForm', authData));
    }

    return fetch(URLS.USER_EXTERNAL_AUTH_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: param(authData),
    })
        .then(handleServerResponse)
        .then(handleDataReceivedAlt)
        .then((data) => {
            dispatch(closeModal());

            if (!data.IsUserNew) {
                dispatch(userAuthenticated(data.Result, false));
            } else {
                history.push('/user-registration');
            }
        })
        .catch((err) => {
            dispatch(closeModal());

            if (err.Code === 17 || err.Code === 57) {
                dispatch(
                    initialize('SocialAuthForm', {
                        ...authData,
                        ErrorCode: err.Code,
                    })
                );
                history.push('/user-registration');
            } else {
                dispatch(handleError(err));
            }
        });
};

export const vkUserAuthCheckinGroup = (authData) => (dispatch) => {
    dispatch(openModal('loading'));
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const { btcRestricted } = lStorage;
    const checkOwnershipUrl =
        process.env.NODE_ENV === 'development'
            ? URLS.VK_CHECK_OWNERSHIP_URL_LOCAL
            : btcRestricted
            ? URLS.VK_CHECK_OWNERSHIP_URL_PRODUCTION_RU
            : URLS.VK_CHECK_OWNERSHIP_URL_PRODUCTION;

    return sendData('POST', checkOwnershipUrl, authData)
        .then((Data) => {
            dispatch(
                change('CreateContractForm', 'PayeeProvedOwnership', Data)
            );
            dispatch(closeModal());
            Data
                ? dispatch(successNotifier('Группа успешно подтверждена'))
                : dispatch(
                      warningNotifierWithTimeOut(
                          'Не удалось подтвердить группу'
                      )
                  );
        })
        .catch((error) => {
            dispatch(handleError(error));
            dispatch(closeModal());
        });
};

export const vkAuth = (authData) => (dispatch) => {
    dispatch(openModal('loading'));

    return fetch(URLS.USER_EXTERNAL_AUTH_URL_2, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: param(authData),
    })
        .then(handleServerResponse)
        .then(handleDataReceivedAlt)
        .then((data) => {
            dispatch(closeModal());
            if (!data.IsUserNew) {
                dispatch(userAuthenticated(data.Result, false));
                dispatch(destroy('SocialAuthForm'));
            } else {
                dispatch(
                    initialize('SocialAuthForm', {
                        ...authData,
                        ...data.Result,
                        LoginProvider: 'Vkontakte',
                        EditFormWasShown: true,
                    })
                );
                history.push('/user-registration');
            }
        })
        .catch((err) => {
            dispatch(closeModal());
            if (err.Code === 17 || err.Code === 57) {
                switch (err.Code) {
                    case 17:
                        dispatch(
                            initialize('SocialAuthForm', {
                                ...authData,
                                ...err.Data.Result,
                                EditFormWasShown: true,
                                LoginProvider: 'Vkontakte',
                                ErrorCode: err.Code,
                            })
                        );
                        break;
                    case 57:
                        dispatch(
                            initialize('SocialAuthForm', {
                                ...authData,
                                EditFormWasShown: true,
                                LoginProvider: 'Vkontakte',
                                ErrorCode: err.Code,
                            })
                        );
                        break;
                }
                if (authData.EditFormWasShown) {
                    dispatch(handleError(err));
                } else {
                    history.push('/user-registration');
                }
            } else {
                dispatch(handleError(err));
            }
        });
};

export const googleAuth = (authData) => (dispatch) => {
    dispatch(openModal('loading'));

    return fetch(URLS.USER_EXTERNAL_AUTH_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: param(authData),
    })
        .then(handleServerResponse)
        .then(handleDataReceivedAlt)
        .then((data) => {
            dispatch(closeModal());

            if (!data.IsUserNew) {
                dispatch(destroy('SocialAuthForm'));
                dispatch(userAuthenticated(data.Result, false));
            } else {
                dispatch(
                    initialize('SocialAuthForm', {
                        ...authData,
                        ...data.Result,
                        LoginProvider: 'Gmail',
                        EditFormWasShown: true,
                    })
                );
                history.push('/user-registration');
            }
        })
        .catch((err) => {
            dispatch(closeModal());

            if (err.Code === 17 || err.Code === 57) {
                switch (err.Code) {
                    case 17:
                        dispatch(
                            initialize('SocialAuthForm', {
                                ...authData,
                                ...err.Data.Result,
                                EditFormWasShown: true,
                                LoginProvider: 'Gmail',
                                ErrorCode: err.Code,
                            })
                        );
                        break;
                    case 57:
                        dispatch(
                            initialize('SocialAuthForm', {
                                ...authData,
                                EditFormWasShown: true,
                                LoginProvider: 'Gmail',
                                ErrorCode: err.Code,
                            })
                        );
                        break;
                }

                if (authData.EditFormWasShown) {
                    dispatch(handleError(err));
                } else {
                    history.push('/user-registration');
                }
            } else {
                dispatch(handleError(err));
            }
        });
};

export const getIframeToken = (serviceLogin, userLogin, sign) => {
    const body = new URLSearchParams();
    body.append('UserLogin', userLogin);
    body.append('ServiceLogin', serviceLogin);
    body.append('Sign', sign);

    return fetch(URLS.IFRAME_GET_ACCESS_TOKEN_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body,
    })
        .then(handleServerResponse)
        .then(handleDataReceived);
};
