import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import LoadManager from '../../methods/helpers/loader/loadManager';
import {
    DependsOnDomainRoute,
    ProtectedRoute,
} from '../../methods/helpers/routes';

const Landing = LoadManager(() => import('../Landing/LandingLayout'));
const LoginPage = LoadManager(() => import('../LoginPage'));
const AboutPage = LoadManager(() => import('../AboutPage'));
const OfferPage = LoadManager(() => import('../pages/offer/OfferPage'));
const KkbOfferPage = LoadManager(() => import('../pages/offer/KkbOfferPage'));
const TermsOfUse = LoadManager(() => import('../TermsOfUse'));
const DevelopersPage = LoadManager(() =>
    import('../pages/developers/DevelopersPage')
);
const KkbDevelopersPage = LoadManager(() =>
    import('../pages/developers/KkbDevelopersPage')
);
const SiteAPIPage = LoadManager(() => import('../pages/developers/SiteDeveloperPage'))
const FaqPage = LoadManager(() => import('../Faq/FaqPage'));
const Arbitration = LoadManager(() => import('../Arbitration'));
const PublicContracts = LoadManager(() =>
    import('../PublicContracts/PublicContractsList')
);
const PublicContractDetailed = LoadManager(() =>
    import('../PublicContracts/PublicDetailed')
);
const UserDashboard = LoadManager(() => import('./UserDashboard'));
const CardFormExternal = LoadManager(() =>
    import('../CardFormExternal/CardFormExternal')
);
const PassportDetails = LoadManager(() =>
    import('../pages/passportDetails/passportDetails')
);
const SocialUserRegistration = LoadManager(() =>
    import('../SocialUserRegistrationPage')
);
const ShortUserInfo = LoadManager(() => import('../ShortUserInfoPage'));
const SuccessPage = LoadManager(() => import('../StatusPages/Success'));
const SuccessfullyAcceptedPage = LoadManager(() =>
    import('../StatusPages/SuccessfullyAccepted')
);
const FailurePage = LoadManager(() => import('../StatusPages/Failure'));
const CreateWizard = LoadManager(() => import('../CreateWizard/CreateWizard'));
const NewCreateWizard = LoadManager(() =>
    import('../pages/create/CreateWizard')
);
const UserTransactions = LoadManager(() =>
    import('../Profile/UserTransactionsPage')
);
const Page404 = LoadManager(() => import('../StatusPages/404'));
const VkAuthPage = LoadManager(() => import('../VkAuthPage'));
const VkAuthGroup = LoadManager(() => import('../VkAuthGroup'));
const Profile = LoadManager(() => import('../pages/Profile/ProfileWrapper'));
const InDevPage = LoadManager(() => import('../StatusPages/InDevPage'));
const AddCardForm = LoadManager(() => import('../Iframe/AddCardForm'));
const GetCardRecurrentToken = LoadManager(() =>
    import('../Iframe/GetCardRecurrentToken')
);
const AdminRedirect = LoadManager(() => import('../Externals/Admin'));

class Main extends Component {
    render() {
        return (
            <main>
                <Switch>
                    <Route exact path="/" component={Landing} />
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/faq" component={FaqPage} />
                    <Route exact path="/public" component={PublicContracts} />
                    <Route
                        exact
                        path="/public/:id"
                        component={PublicContractDetailed}
                    />
                    <Route
                        exact
                        path="/card-payment"
                        component={CardFormExternal}
                    />
                    <Route exact path="/card-add" component={AddCardForm} />
                    <Route
                        exact
                        path="/card-recurrent"
                        component={GetCardRecurrentToken}
                    />
                    <Route
                        exact
                        path="/passport-details"
                        component={PassportDetails}
                    />
                    <Route
                        exact
                        path="/user-registration"
                        component={SocialUserRegistration}
                    />
                    <Route path="/callback" component={VkAuthPage} />
                    <Route
                        path="/vkcodegroup/callback"
                        component={VkAuthGroup}
                    />
                    /* Depends on domain routes */
                    {/* <DependsOnDomainRoute exact path="/about" component={AboutPage} fallbackComponent={InDevPage}/> */}
                    <DependsOnDomainRoute
                        exact
                        path="/about"
                        component={AboutPage}
                    />
                    <DependsOnDomainRoute
                        exact
                        path="/offer"
                        component={OfferPage}
                        fallbackComponent={KkbOfferPage}
                    />
                    <DependsOnDomainRoute
                        exact
                        path="/developers"
                        component={DevelopersPage}
                        fallbackComponent={KkbDevelopersPage}
                    />
                    <Route path="/site-api" component={SiteAPIPage}/>
                    <DependsOnDomainRoute
                        exact
                        path="/arbitration"
                        component={Arbitration}
                    />
                    <Route exact path="/terms-of-use" component={TermsOfUse} />
                    /* Protected routes*/
                    <ProtectedRoute exact path="/profile" component={Profile} />
                    <ProtectedRoute
                        exact
                        path="/user-info/:id"
                        component={ShortUserInfo}
                    />
                    <ProtectedRoute
                        exact
                        path="/short-user-info/:id"
                        component={ShortUserInfo}
                    />
                    <ProtectedRoute
                        exact
                        path="/payment/success"
                        component={SuccessPage}
                    />
                    <ProtectedRoute
                        exact
                        path="/acceptance/success"
                        component={SuccessfullyAcceptedPage}
                    />
                    <ProtectedRoute
                        exatc
                        path="/payment/fail"
                        component={FailurePage}
                    />
                    <ProtectedRoute
                        exact
                        path="/payment/failure"
                        component={FailurePage}
                    />
                    <ProtectedRoute
                        exact
                        path="/profile/transactions-history"
                        component={UserTransactions}
                    />
                    <ProtectedRoute path="/create" component={CreateWizard} />
                    <ProtectedRoute
                        path="/createnew/:contractTypeId"
                        component={NewCreateWizard}
                    />
                    <ProtectedRoute path="/user" component={UserDashboard} />
                    {/* <Route path="/user" component={UserDashboard} {...this.props}/> */}
                    <Route path="/admin" component={AdminRedirect} />
                    <Route component={Page404} />
                </Switch>
            </main>
        );
    }
}

export default withRouter(Main);
