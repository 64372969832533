import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import reducer from './reducers/index';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

export const history = createBrowserHistory();

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        const parsedState = JSON.parse(serializedState);
        if (
            serializedState === null ||
            typeof parsedState.locale !== 'object'
        ) {
            return undefined;
        }
        return parsedState;
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (err) {
        // Ignore;
    }
};

const consoleMessages = () => (next) => (action) => {
    if (process.env.NODE_ENV === 'development') {
        console.log(`dispatching action => ${action.type}`);
    }
    return next(action);
};

const eventsLogging = (store) => (next) => (action) => {
    let result;
    const appState = store.getState();
    const { wsConnected } = appState;

    if (
        wsConnected &&
        window.userHub !== undefined &&
        action.type &&
        window.userHub.server.writeLog
    ) {
        const data = {
            type: action.type,
            payload: action.payload,
        };
        window.userHub.server.writeLog('info', JSON.stringify(data));
    }
    result = next(action);
    return result;
};

const initialState = loadState();

const middleware = [
    thunk,
    consoleMessages,
    eventsLogging,
    routerMiddleware(history),
];

export const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

if (process.env.NODE_ENV === 'development') {
    window.store = store;
}

store.subscribe(() => {
    saveState(store.getState());
});

export default store;
