import { sendFile, sendData } from '../helpers/common';
import URLS from '../../constants/urls';

export const attachFile = (file) => sendFile(URLS.FILE_UPLOAD_URL, file);

export const detachFile = (Guid) =>
    sendData('DELETE', URLS.FILE_DETACH_URL + Guid);

export const getBlob = (Guid) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const {
        user: { AccessToken },
    } = lStorage;

    return fetch(URLS.FILE_GET_URL + Guid, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${AccessToken}`,
        },
    })
        .then((response) => response.blob())
        .then((data) => {
            const objectURL = URL.createObjectURL(data);
            return objectURL;
        });
};

export const downloadBlob = (blob, fileName) => {
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blob;
    tempLink.setAttribute('download', fileName);
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        window.URL.revokeObjectURL(blob);
    }, 100);
};
