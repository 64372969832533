import moment from 'moment';
import { change, arrayRemoveAll } from 'redux-form';
import C from '../../constants/actionTypes';
import URLS from '../../constants/urls';
import {
    handleServerResponse,
    handleDataReceived,
    sendData,
    getAgreementFields,
} from '../helpers/common';
import { handleError } from '../helpers/notifications';

// FETCH CONTRACT TEMPLATES DATA BY ID
export const fetchTermsTemplate = (templateId, entityType) => (dispatch) => {
    if (templateId !== undefined && templateId !== null) {
        const url = URLS.CONTRACT_TEMPLATE_URL + templateId;

        return fetch(url)
            .then(handleServerResponse)
            .then(handleDataReceived)
            .then((template) => {
                switch (entityType) {
                    case 'deal':
                        dispatch({
                            type: C.DEAL_TEMPLATE_FETCHED,
                            payload: template,
                        });
                        break;
                    case 'contract':
                        dispatch({
                            type: C.CONTRACT_TEMPLATE_FETCHED,
                            payload: template,
                        });
                        break;
                    default:
                        return template;
                }
                return template;
            });
    }
    return Promise.resolve();
};

export const fetchFilledTermsTemplate = (data) => (dispatch) => {
    const formattedData = {
        ...data,
        DealDuration: moment(data.DealDuration).format('MM.DD.YYYY'),
        ContractDuration: moment(data.ContractDuration).format('MM.DD.YYYY'),
    };

    return sendData('POST', URLS.CONTRACT_PREFILLED_TEMPLATES, formattedData)
        .then((data) => data.Templates)
        .catch((error) => dispatch(handleError(error)));
};

// FETCH CONTRACT TEMPLATE DEPENDING ON ENTERED DATA
export const fetchPrefilledTermsTemplate = (data) => (dispatch) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const {
        locale: { lang },
        user,
    } = lStorage;

    const { DepositInitiator, DepositPartner } = data;

    const Name = data.DealName ? data.DealName : data.Name;
    const DealDuration = moment(data.DealDuration).format('MM.DD.YYYY');
    const ContractDuration = moment(data.ContractDuration).format('MM.DD.YYYY');
    const UserLogin = data.Initiator
        ? data.Initiator.UserLogin
        : user.UserLogin;

    const formattedData = {
        ...data,
        DealDuration,
        ContractDuration,
        Name,
        DepositPartner,
        DepositInitiator,
        culture: lang,
        UserLogin,
    };

    const url = URLS.CONTRACT_PREFILLED_TEMPLATES;

    return sendData('POST', url, formattedData)
        .then((data) => data.Templates)
        .catch((error) => dispatch(handleError(error)));
};

// TEMPLATE SELECTION
export const selectContractTemplate =
    (templateData, isCreate = false) =>
    (dispatch) => {
        if (isCreate) {
            dispatch(change('CreateContractForm', 'Terms', templateData.Body));
            dispatch(
                change('CreateContractForm', 'TermTemplateId', templateData.Id)
            );
            // dispatch(change('CreateContractForm', 'SelectedTermsTemplate', templateData));
            // dispatch(change('CreateContractForm', 'Selected', true));
            // dispatch(arrayRemoveAll('CreateContractForm', 'AgreementFields'));
        } else {
            dispatch(change('UpdateContractForm', 'Terms', templateData.Body));
            dispatch(
                change('UpdateContractForm', 'TermTemplateId', templateData.Id)
            );
        }
    };
