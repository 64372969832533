import C from '../../constants/actionTypes';

const initialData = {
    currentInfo: {},
    template: {},
    fees: {},
};

export default (state = initialData, action) => {
    switch (action.type) {
        case C.PUBLIC_CONTRACT_FETCHED:
            return {
                ...state,
                currentInfo: {
                    ...action.payload,
                },
            };
        case C.PUBLIC_CONTRACT_ACCEPTED:
            if (
                !!state.currentInfo &&
                state.currentInfo.Id === action.payload
            ) {
                return {
                    ...state,
                    currentInfo: {
                        ...state.currentInfo,
                        Accepted: true,
                    },
                };
            }
            return state;

        case C.CONTRACT_UPDATED:
        case C.CONTRACT_ACCEPTED_BY_USER:
        case C.CONTRACT_ACCEPTED_BY_PARTNER:
            if (
                !!state.currentInfo &&
                state.currentInfo.Id === parseInt(action.payload.Id)
            ) {
                return {
                    ...state,
                    currentInfo: {
                        ...action.payload,
                    },
                };
            }
            return state;

        case C.CONTRACT_DECLINED_BY_PARTNER:
        case C.CONTRACT_DECLINED_BY_USER:
            if (
                !!state.currentInfo &&
                state.currentInfo.Id === action.payload.Id
            ) {
                return {
                    ...state,
                    currentInfo: {
                        ...state.currentInfo,
                        Status: 5,
                    },
                };
            }
            return state;

        case C.PUBLIC_CONTRACT_FETCH_FAILED:
            return initialData;

        case C.CONTRACT_TEMPLATE_FETCHED:
            return {
                ...state,
                template: action.payload,
            };

        case C.CONTRACT_FEES_FETCHED:
            return {
                ...state,
                fees: action.payload,
            };

        default:
            return state;
    }
};
