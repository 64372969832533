import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import { MdClose } from 'react-icons/md';
import {
    closeModal,
    openModal,
    setRedirectUrl,
} from '../../methods/actions/actionCreators';
import LoadManager from '../../methods/helpers/loader/loadManager';
import BlockLoader from '../Shared/BlockLoader';

const LoginForm = LoadManager(() => import('./User/LoginDialog')); /* + */
const LogOut = LoadManager(() => import('./User/LogOutDialog')); /* + */
const RegistrationForm = LoadManager(() =>
    import('./User/Registration')
); /* + */
const PasswordRecovery = LoadManager(() =>
    import('./User/PasswordRecovery')
); /* + */
const NewPasswordForm = LoadManager(() =>
    import('./User/NewPasswordForm')
); /* + */
const Impersonation = LoadManager(() => import('./User/Impersonation')); /* + */
const SessionTimeout = LoadManager(() => import('./SessionTimeout')); /* + */
const Loading = LoadManager(() => import('./Loading')); /* + */
const AcceptContractDialog = LoadManager(() =>
    import('./AcceptContractDialog')
); /* + */
const DetachPaymentMethodDialog = LoadManager(() =>
    import('./DetachDialog')
); /* + */
const DetachFileDialog = LoadManager(() =>
    import('./DetachFileDialog')
); /* + */
const DeclineContractDialog = LoadManager(() =>
    import('./DeclineContractDialog')
); /* + */
const FileUploading = LoadManager(() => import('./FileUploading')); /* + */
const ConfirmPaymentDialog = LoadManager(() =>
    import('./ConfirmPaymentDialog')
); /* + */
const ConfirmAcceptanceDialog = LoadManager(() =>
    import('./ConfirmAcceptanceDialog')
); /* + */
const OpenDisputeDialog = LoadManager(() =>
    import('./OpenDisputeDialog')
); /* + */
const CancelDealDialog = LoadManager(() =>
    import('./CancelDealDialog')
); /* + */
const CancelDealRejectionDialog = LoadManager(() =>
    import('./CancelDealRejectionDialog')
); /* + */
const CancelDealAcceptDialog = LoadManager(() =>
    import('./CancelDealAcceptDialog')
); /* + */
const ProlongDealRequest = LoadManager(() =>
    import('./ProlongDealRequestDialog')
); /* + */
const ProlongDealConfirmation = LoadManager(() =>
    import('./ProlongDealConfirmationDialog')
); /* + */
const TemplateModal = LoadManager(() => import('./TemplateModal')); /*+*/
const TemplateGenerated = LoadManager(() =>
    import('./TemplateGeneratedDialog')
); /* + */
const TemplateFilled = LoadManager(() => import('./TemplateFilledDialog'));
const SetPassportForm = LoadManager(() =>
    import('../Profile/Forms/SetPassportForm')
); /* + */
const InvoiceRegistration = LoadManager(() =>
    import('../InvoiceRegistration')
); /* + */
const TermsAcceptance = LoadManager(() => import('./TermsAcceptance')); /* + */
const ConfirmModal = LoadManager(() => import('./ConfirmModal')); /* + */
const Help = LoadManager(() => import('./Help'));
const DealClosedDialog = LoadManager(() => import('./DealClosedDialog'));
const WithdrawAssets = LoadManager(() => import('./WithdrawAssets'));
const AddPurse = LoadManager(() => import('./addPurse/AddPurse'));

class Modal extends Component {
    constructor(props) {
        super(props);
        this.renderContent = this.renderContent.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {
            modal: { type },
        } = prevProps;
        if (
            type !== this.props.modal.type &&
            this.props.modal.type === 'login'
        ) {
            const { setRedirectUrl } = this.props;
            setRedirectUrl();
        }
    }

    renderContent() {
        const {
            modal: { type },
        } = this.props;

        switch (type) {
            case 'help':
                return <Help {...this.props} />;
            case 'terms-acceptance':
                return <TermsAcceptance {...this.props} />;
            case 'session-timeout':
                return <SessionTimeout {...this.props} />;
            case 'loading':
                return <Loading {...this.props} />;
            case 'login':
                return <LoginForm {...this.props} />;
            case 'logout':
                return <LogOut {...this.props} />;
            case 'registration':
                return <RegistrationForm {...this.props} />;
            case 'password-recovery':
                return <PasswordRecovery {...this.props} />;
            case 'new-password':
                return <NewPasswordForm {...this.props} />;
            case 'accept-contract':
                return <AcceptContractDialog {...this.props} />;
            case 'decline-contract':
                return <DeclineContractDialog {...this.props} />;
            case 'confirm-acceptance':
                return <ConfirmAcceptanceDialog {...this.props} />;
            case 'confirm-payment':
                return <ConfirmPaymentDialog {...this.props} />;
            case 'cancel-deal':
                return <CancelDealDialog {...this.props} />;
            case 'cancel-deal-rejection':
                return <CancelDealRejectionDialog {...this.props} />;
            case 'cancel-deal-accept':
                return <CancelDealAcceptDialog {...this.props} />;
            case 'open-dispute':
                return <OpenDisputeDialog {...this.props} />;
            case 'invoice-registration':
                return <InvoiceRegistration {...this.props} />;
            case 'contract-agreement':
                return <TemplateModal {...this.props} />;
            case 'contract-agreement-generated':
                return <TemplateGenerated {...this.props} />;
            case 'passport-form':
                return <SetPassportForm {...this.props} />;
            case 'impersonation':
                return <Impersonation {...this.props} />;
            case 'detach':
                return <DetachPaymentMethodDialog {...this.props} />;
            case 'file-detach':
                return <DetachFileDialog {...this.props} />;
            case 'file-uploading':
                return <FileUploading {...this.props} />;
            case 'prolong-deal-request':
                return <ProlongDealRequest {...this.props} />;
            case 'prolong-deal-confirmation':
                return <ProlongDealConfirmation {...this.props} />;
            case 'confirm-action':
                return <ConfirmModal {...this.props} />;
            case 'deal-closed':
                return <DealClosedDialog {...this.props} />;
            case 'withdraw-assets':
                return <WithdrawAssets {...this.props} />;
            case 'add-purse':
                return <AddPurse {...this.props} />;
            case 'template-filled':
                return <TemplateFilled {...this.props} />;
        }
    }

    render() {
        const {
            modal: { opened, type, loading },
            closeModal,
        } = this.props;
        const closeable =
            type !== 'file-uploading' && type !== 'session-timeout';

        return (
            <Dialog
                active={opened}
                className={`dialog ${type}`}
                onEscKeyDown={closeable ? closeModal : null}
                onOverlayClick={closeable ? closeModal : null}>
                <div className="dialog__controls-block">
                    {closeable && (
                        <MdClose
                            className="close-dialog"
                            onClick={closeModal}
                        />
                    )}
                </div>
                {this.renderContent()}
                {loading && (
                    <div className="esc-block-loading">
                        <BlockLoader />
                    </div>
                )}
            </Dialog>
        );
    }
}

const mapStateToProps = ({ modal, user }) => ({
    modal,
    user,
});

const mapDispatchToProps = {
    openModal,
    closeModal,
    setRedirectUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
