import React from 'react';
import { Link } from 'react-router-dom';
import URLS from '../../constants/urls';
import C from '../../constants/actionTypes';
import { sendData, getData } from '../helpers/common';
import { handleError } from '../helpers/notifications';
import { newMessage } from '../actions/chat';

export const fetchChatsList = (userLogin) => (dispatch) =>
    getData(`${URLS.FETCH_CHATS_URL}?userLogin=${userLogin}`).then((data) => {
        dispatch({
            type: C.CHATS_LIST_FETCHED,
            payload: data,
        });
        return data;
    });

export const readChat = (entityId) => getData(URLS.READ_CHAT_URL + entityId);

export const getIncomingMessages = (chat) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const { user } = lStorage;

    return chat.Items.filter((message) => message.From !== user.Id);
};

export const getUnreadMessages = (chat) => {
    const incomingMessages = getIncomingMessages(chat);
    return incomingMessages.length > 0
        ? incomingMessages.filter(
              (message) => message.Status === 0 && message.To !== null
          )
        : [];
};

export const markIncomingMessagesRead =
    (EntityId, UnreadMsgsCount) => (dispatch) => {
        readChat(EntityId)
            .then(() => {
                dispatch({
                    type: C.INCOMING_MESSAGES_READ,
                    payload: {
                        EntityId,
                        UnreadMsgsCount,
                    },
                });
            })
            .catch((err) => dispatch(handleError(err)));
    };

export const fetchChatMessages = (entityId, lastMsgId, pageSize = 200) =>
    getData(
        `${URLS.FETCH_CHAT_MESSAGES_URL}?chatType=1&bindedObjectId=${entityId}&pageSize=${pageSize}&lastMsgId=${lastMsgId}&srch`
    );

export const deleteChat = (entityId) => (dispatch) =>
    sendData('DELETE', URLS.DELETE_CHAT_URL + entityId).then(() => {
        dispatch({
            type: C.CHAT_DELETED,
            payload: entityId,
        });
    });

export const sendMessage = (data) => (dispatch) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const { user } = lStorage;
    return sendData('POST', URLS.ADD_CHAT_MESSAGE, data).then((message) => {
        dispatch(
            newMessage({
                ...message,
                CurrentUserId: user.Id,
            })
        );
        return message;
    });
};
