import C from '../../constants/actionTypes';

export const chatsList = (state = [], action) => {
    switch (action.type) {
        case C.CHATS_LIST_FETCHED:
            return action.payload;

        case C.NEW_MESSAGE:
            const updatedChat = state.map((chat) => {
                if (chat.EntityId !== action.payload.BindedObjectId) {
                    return chat;
                }
                return {
                    ...chat,
                    Items: [action.payload, ...chat.Items],
                };
            });

            return updatedChat;
        case C.CHAT_DELETED:
            return state.filter((chat) => chat.EntityId !== action.payload);
        default:
            return state;
    }
};

export const chatDetailed = (state = {}, action) => {
    switch (action.type) {
        case C.CHAT_ENTITY_FETCHED:
            return {
                entity: action.payload,
            };

        case C.MESSAGES_FETCHED:
            if (state.entity) {
                const isDeal = state.entity.ContractId;

                if (
                    (isDeal &&
                        action.payload.EntityId === state.entity.ContractId) ||
                    (!isDeal && action.payload.EntityId === state.entity.Id)
                ) {
                    return {
                        ...state,
                        chat: {
                            Items: action.payload.Items.slice().reverse(),
                            Count: action.payload.Count,
                        },
                    };
                }
                return state;
            }
            return state;

        case C.MESSAGES_LAZY_LOADED:
            if (state.entity) {
                const isDeal = state.entity.ContractId;

                if (
                    (isDeal &&
                        state.entity.ContractId === action.payload.EntityId) ||
                    (!isDeal && state.entity.Id === action.payload.EntityId)
                ) {
                    return {
                        ...state,
                        chat: {
                            ...state.chat,
                            Items: [
                                ...action.payload.Items.slice().reverse(),
                                ...state.chat.Items,
                            ],
                        },
                    };
                }
                return state;
            }
            return state;

        case C.NEW_MESSAGE:
            if (state.entity) {
                const isDeal = state.entity.ContractId;

                if (
                    (isDeal &&
                        action.payload.BindedObjectId ===
                            state.entity.ContractId) ||
                    (!isDeal &&
                        action.payload.BindedObjectId === state.entity.Id)
                ) {
                    return {
                        ...state,
                        chat: {
                            Count: state.chat.Count + 1,
                            Items: [...state.chat.Items, action.payload],
                        },
                    };
                }
                return state;
            }
            return state;

        case C.INCOMING_MESSAGES_READ:
            if (state.entity && state.chat) {
                const isDeal = state.entity.ContractId;
                if (
                    (isDeal &&
                        action.payload.EntityId === state.entity.ContractId) ||
                    (!isDeal && action.payload.EntityId === state.entity.Id)
                ) {
                    const collection = state.chat.Items.map((message) => {
                        if (message.From !== action.payload.UserId) {
                            return { ...message, Status: 1 };
                        }
                        return message;
                    });

                    return {
                        ...state,
                        chat: {
                            ...state.chat,
                            Items: collection,
                        },
                    };
                }
                return state;
            }
            return state;

        case C.SENT_MESSAGES_READ:
            if (state.entity && state.chat) {
                const isDeal = state.entity.ContractId;
                if (
                    (isDeal &&
                        action.payload.EntityId === state.entity.ContractId) ||
                    (!isDeal && action.payload.EntityId === state.entity.Id)
                ) {
                    const collection = state.chat.Items.map((message) => {
                        if (message.From === action.payload.UserId) {
                            return { ...message, Status: 1 };
                        }
                        return message;
                    });
                    return {
                        ...state,
                        chat: {
                            ...state.chat,
                            Items: collection,
                        },
                    };
                }
                return state;
            }
            return state;
        case C.CLEAR_CHAT_MESSAGES:
            return {};

        default:
            return state;
    }
};
