import C from '../../constants/actionTypes';

export default (state = [], action) => {
    switch (action.type) {
        case C.AUTHENTICATED:
            const {
                UserLogin,
                RefreshIssued,
                RefreshExpires,
                RefreshToken,
                Roles,
            } = action.payload;
            const alreadyAdded =
                state.filter(
                    (item) => item.UserLogin === action.payload.UserLogin
                ).length > 0;

            if (alreadyAdded) {
                const filteredArray = state.filter(
                    (item) => item.UserLogin !== action.payload.UserLogin
                );
                return [
                    ...filteredArray,
                    {
                        UserLogin,
                        RefreshIssued,
                        RefreshExpires,
                        RefreshToken,
                        Roles,
                    },
                ];
            }
            return [
                ...state,
                {
                    UserLogin,
                    RefreshIssued,
                    RefreshExpires,
                    RefreshToken,
                    Roles,
                },
            ];

        case C.TOKEN_REFRESHED:
            return state.map((entry) => {
                if (entry.UserLogin === action.payload.UserLogin) {
                    const {
                        UserLogin,
                        RefreshIssued,
                        RefreshExpires,
                        RefreshToken,
                        Roles,
                    } = action.payload;
                    return {
                        ...entry,
                        UserLogin,
                        RefreshIssued,
                        RefreshExpires,
                        RefreshToken,
                        Roles,
                    };
                }
                return entry;
            });

        case C.USER_PROFILE_FETCHED:
            return state.map((entry) => {
                if (entry.UserLogin === action.payload.UserLogin) {
                    return { ...entry, ...action.payload };
                }
                return entry;
            });

        case C.DETACH_ACCOUNT:
        case C.ACCOUNT_REFRESH_EXPIRED:
        case C.FAST_LOGIN_FAILED:
            const { Id } = action.payload;
            return state.filter((entry) => entry.Id !== Id);
        default:
            return state;
    }
};
