import moment from 'moment';
import sanitizeHtml from 'sanitize-html';
import param from 'jquery-param';
import i18next from 'i18next';
import React from 'react';
import {
    getData,
    handleDataReceived,
    handleServerResponse,
    sendData,
} from '../helpers/common';
import {
    handleError,
    messageNotifier,
    successNotifier,
} from '../helpers/notifications';
import {
    contractDeclined,
    contractUpdated,
    publicContractAccepted,
} from '../actions/actionCreators';
import { fetchChatMessages, getUnreadMessages } from './chat';
import { fetchDeals } from './deals';
import C from '../../constants/actionTypes';
import URLS from '../../constants/urls';
import { getContractStatus } from '../helpers/statuses';
import { format } from 'prettier';
import { omit } from 'lodash';

const baseUrl = '/api/contracts';

// CREATE CONTRACT
export const createContract = (data) => {
    const DealDuration = moment(data.DealDuration).format();
    const ContractDuration = moment(data.ContractDuration).format();
    const Name = data.DealName;
    const Tags = data.Tags.join(',');
    const { DepositInitiator = 0, DepositPartner = 0 } = data;

    const formattedData = {
        ...data,
        DealDuration,
        ContractDuration,
        //Tags,
        Name,
        DepositPartner,
        DepositInitiator,
    };

    if (formattedData.PartnerName) {
        formattedData.PublicContractsCount = null;
    }
    omit(formattedData, ['TermTemplateId', 'AgreementFields']);
    return sendData('POST', `/api/site/contracts`, formattedData);
};

export const createContractWithConfirm = (
    data = {
        ConfirmType: 0,
        ConfirmGuid: '',
        ConfirmEmail: '',
        Name: '',
        AgreementFields: [],
        ContractType: 0,
        PartnerName: '',
        InitiatorRole: 'Payee',
        ContractDuration: {},
        Description: '',
        Tags: '',
        AllowNewConditions: true,
        //PublicContractsCount: 0,
        TermTemplateId: 0,
        Culture: '',
        Files: [],
        PayMethod: '',
        Amount: 0,
        Currency: '',
        CommissionsType: '',
        DepositInitiator: 0,
        DepositPartner: 0,
        DepositToPayee: true,
        DealDuration: {},
        Terms: '',
    }
) => {
    omit(data, ['TermTemplateId', 'AgreementFields']);

    return sendData('POST', `${baseUrl}/site`, data);
};

export const checkOwnership = (contract) => (dispatch) =>
    sendData('POST', `${baseUrl}/${contract.Id}/check-ownership`)
        .then(() => {
            const isEmailConfirm = contract.DynamicFields.ConfirmType === 0;
            contract.PayeeProvedOwnership =
                !isEmailConfirm || contract.PayeeProvedOwnership;
            dispatch(contractUpdated(contract));
            let notificationMessage = null;
            switch (contract.DynamicFields.ConfirmType) {
                case 0:
                    notificationMessage = i18next.t(
                        'domain_confirmation_email_sended'
                    );
                    break;
                case 1:
                    notificationMessage = i18next.t('domain_confirmed');
                    break;
                case 2:
                    notificationMessage = i18next.t('vk_confirmed');
                    break;
            }
            dispatch(successNotifier(notificationMessage));
        })
        .catch((error) => dispatch(handleError(error)));

// FETCH SINGLE CONTRACT
export const fetchContract = (id) => {
    const url = `${URLS.CONTRACTS_URL}/site/${id}`;
    return getData(url);
};

// FETCH CONTRACT VERSION
export const fetchContractVersion = (contract) => (dispatch) => {
    if (contract.Version && contract.Version > 1) {
        const url = `${`${URLS.CONTRACTS_URL}/${contract.Id}${
            URLS.CONTRACTS_GET_VERSION_SUFFIX_URL
        }${contract.Version - 1}`}`;

        return getData(url).then((data) => {
            dispatch({
                type: C.PREVIOUS_CONTRACT_VERSION_FETCHED,
                payload: data,
            });
            return data;
        });
    }
    return Promise.resolve();
};

// FETCH CONTRACTS LIST
export const fetchContracts =
    (filters = {}) =>
    (dispatch) => {
        let url = URLS.CONTRACTS_WITH_CONFIRM_URL;
        url += filters.Offset !== null ? `?offset=${filters.Offset}` : '';
        url += filters.Limit !== null ? `&limit=${filters.Limit}` : '';
        url += filters.startDate ? `&startDate=${filters.startDate}` : '';
        url += filters.endDate ? `&endDate=${filters.endDate}` : '';
        url += filters.search ? `&search=${filters.search}` : '';
        url +=
            !!filters.statuses && filters.statuses.length > 0
                ? `&statuses=${filters.statuses}`
                : '';
        url +=
            !!filters.paymethods && filters.paymethods.length > 0
                ? `&paymethods=${filters.paymethods}`
                : '';
        url +=
            !!filters.categories && filters.categories.length > 0
                ? `&categories=${filters.categories.join(',')}`
                : '';
        url +=
            !!filters.tags && filters.tags.length > 0
                ? `&tags=${filters.tags}`
                : '';

        return getData(url).then((contracts) => {
            const {
                Items,
                Pagination: { Total },
            } = contracts;

            dispatch({
                type: C.CONTRACTS_COUNTER_UPDATED,
                payload: Total,
            });

            const chatsRequests = [];
            const transactionsRequests = [];

            Items.map((contract) => {
                chatsRequests.push(fetchChatMessages(contract.Id));
                transactionsRequests.push(
                    dispatch(fetchContractTransactionsList(contract))
                );
            });

            return Promise.all(transactionsRequests).then((transactionsList) =>
                Promise.all(chatsRequests).then((chats) => {
                    const updatedItems = Items.map((contract, i) => {
                        const UnreadMessages = getUnreadMessages(
                            chats[i]
                        ).length;
                        const transactions = transactionsList[i];
                        return {
                            ...contract,
                            UnreadMessages,
                            transactions,
                        };
                    });

                    dispatch({
                        type: C.CONTRACTS_FETCHED,
                        payload: {
                            ...contracts,
                            Items: updatedItems,
                        },
                    });
                })
            );
        });
    };

// UPDATE OFFER
export const updateContract = (data) => (dispatch) =>
    sendData('PUT', `${URLS.CONTRACTS_URL}/${data.Id}/update`, {
        ...data,
    }).then((response) => {
        if (response) {
            dispatch(contractUpdated(response));
        }
    });
// ACCEPT CONTRACT
export const acceptContract =
    (contractInfo, values = {}, isPublic = false) =>
    (dispatch) => {
        const acceptPostFix = '/accept';
        const url = `${URLS.CONTRACTS_URL}/${contractInfo.Id}${acceptPostFix}`;
        const {
            CardHolder = null,
            CardMonth = null,
            CardYear = null,
            CardCode = null,
            UserPurseId = null,
            IsAutoPayment,
        } = values;

        const data = {
            PayeePurse: values.PayeePurse
                ? values.PayeePurse.replace(/\s/g, '')
                : null,
            CardHolder,
            CardMonth,
            CardYear,
            CardCode,
            UserPurseId,
            IsAutoPayment,
        };

        return sendData('POST', url, data).then((response) => {
            if (!!response && !!response.URL) {
                paymentProcess(response);
                return 'accepted';
            }
            if (isPublic) {
                dispatch(publicContractAccepted(contractInfo.Id));
                const message = `<div>
                           ${i18next.t('public_contract_accepted_text')} <br>
                           <a href="/user/contracts">
                           ${i18next.t(
                               'go_to_your_contracts_list'
                           )}</a>            
                    </div>`;

                dispatch(successNotifier(message));
            } else {
                const url = `${URLS.CONTRACTS_URL}/site/${contractInfo.Id}`;

                try {
                    return getData(url).then((updatedContract) => {
                        const status = getContractStatus(updatedContract);

                        dispatch(contractUpdated(updatedContract));

                        if (
                            status === 'totallyAccepted' ||
                            status === 'dealCreated'
                        ) {
                            return Promise.all([
                                dispatch(fetchContracts()),
                                dispatch(fetchDeals()),
                            ]).then(() => {
                                dispatch(
                                    successNotifier(
                                        i18next.t(
                                            `Сделка на основании предложения <b>${`${updatedContract.Id}. ${updatedContract.Name}`}</b> успешно создана!`
                                        )
                                    )
                                );

                                dispatch({
                                    type: C.NEW_DEAL_CREATED,
                                    payload: updatedContract,
                                });
                            });
                        }
                        dispatch(
                            successNotifier(
                                i18next.t(
                                    'Предложение подтверждено. Ожидайте ответа партнера!'
                                )
                            )
                        );
                    });
                } catch (err) {
                    console.log(err);
                }
            }
        });
    };

// DECLINE CONTRACT
export const declineContract = (contract) => (dispatch) => {
    const url = `${URLS.CONTRACTS_URL}/${contract.Id}/decline`;
    const cleanName = sanitizeHtml(contract.Name);

    const message = i18next.t('contract_decline_text', { name: cleanName });

    return sendData('POST', url)
        .then(() => {
            dispatch(contractDeclined(contract));
            dispatch(messageNotifier(message, i18next.t('new_event')));
        })
        .catch((error) => {
            dispatch(handleError(error));
        });
};

// FETCH SINGLE PUBLIC CONTRACT
export const fetchPublicContract = (id) => (dispatch) =>
    fetch(URLS.PUBLIC_CONTRACTS_URL + id)
        .then(handleServerResponse)
        .then(handleDataReceived)
        .then((data) => {
            dispatch({
                type: C.PUBLIC_CONTRACT_FETCHED,
                payload: data,
            });
            return data;
        });

// FETCH PUBLIC CONTRACTS LIST
export const fetchPublicContracts =
    (
        { name, bDate, eDate, payMethods, categories, tags } = {},
        offset = 0,
        limit = 10
    ) =>
    (dispatch) => {
        let url = `${URLS.PUBLIC_CONTRACTS_WITH_CONFIRM_URL}?offset=${offset}&limit=${limit}`;
        if (name) url += `&search=${name}`;
        if (bDate) url += `&startDate=${bDate}`;
        if (eDate) url += `&endDate=${eDate}`;
        if (payMethods) url += `&paymethods=${payMethods}`;
        if (categories) url += `&categories=${categories}`;
        if (tags) url += `&tags=${tags}`;

        return fetch(url)
            .then(handleServerResponse)
            .then(handleDataReceived)
            .then((data) => {
                const { Items, Pagination } = data;
                dispatch({
                    type: C.PUBLIC_CONTRACTS_FETCHED,
                    payload: {
                        Items,
                        Pagination,
                    },
                });
            })
            .catch((err) => {
                dispatch(handleError(err));
                dispatch({
                    type: C.PUBLIC_CONTRACTS_CLEAR,
                });
            });
    };
// FETCH CONTRACT HISTORY
export const fetchContractsHistory = (filters) => (dispatch) => {
    const params = param(filters);
    return getData(`${URLS.CONTRACTS_HISTORY_URL}?${params}`)
        .then((data) => {
            if (data) {
                const { Items, Pagination } = data;

                dispatch({
                    type: C.CONTRACTS_HISTORY_FETCHED,
                    payload: {
                        Items,
                        Pagination,
                    },
                });
                return data;
            }
        })
        .catch((error) => {
            dispatch(handleError(error));
        });
};
// FETCH CONTRACT TRANSACTIONS
export const fetchContractTransactionsList = (contract) => (dispatch) => {
    const url = `${URLS.CONTRACTS_URL}/${contract.Id}/transactions`;
    return getData(url).then((transactions) => {
        dispatch({
            type: C.CONTRACT_TRANSACTIONS_FETCHED,
            payload: transactions,
        });
        return transactions;
    });
};
// FETCH CONTRACT TYPES
export const fetchContractTypes = () => (dispatch) =>
    fetch(URLS.CONTRACT_TYPES_URL)
        .then(handleServerResponse)
        .then(handleDataReceived)
        .then((types) => {
            dispatch({
                type: C.CONTRACT_TYPES_FETCHED,
                payload: types,
            });
        })
        .catch((error) => {
            dispatch(handleError(error));
        });

// DEPOSIT PAYMENT PROCESSING
export const paymentProcess = (data) => {
    try {
        document.charset = 'windows-1251';
    } catch (err) {
        console.log(err);
    }

    const form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.style.cssText = 'visibility:hidden';
    form.setAttribute('accept-charset', 'windows-1251');
    form.setAttribute('action', data.URL);

    for (const key in data) {
        if (key !== 'URL' && key !== 'ContractId') {
            const el = document.createElement('input');
            el.setAttribute('name', key);
            el.setAttribute('value', data[key]);
            form.appendChild(el);
        }
    }

    document.body.appendChild(form);
    form.submit();
};
