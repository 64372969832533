import React from 'react';
import i18next from 'i18next';
import image from '../../assets/images/escrow_500.svg';

export default function () {
    return (
        <div className="page page_500">
            <div className="page__image-wrapper">
                <img src={image} alt="" />
            </div>
            <div className="page__content-wrapper">
                <h5>Internal server error</h5>
                <p>Temporary failure, please try again later.</p>
                <button
                    className="button button_red"
                    onClick={() => window.location.reload()}>
                    Retry
                </button>
            </div>
        </div>
    );
}
