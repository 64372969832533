import React from 'react';
import C from '../../constants/actionTypes';
import param from 'jquery-param';
import { errorNotifier, handleError, successNotifier } from './notifications';
import { handleLogout, userVerification } from '../api/session';
import { fetchSupportMessagesByGuid } from '../api/support';
import {
    closeModal,
    openModal,
    restrictBtc,
    triggerPublicContractAcceptance,
} from '../actions/actionCreators';
import { isEmpty, isEqual } from 'lodash';
import { downloadBlob, getBlob } from '../api/files';
import ReactTooltip from 'react-tooltip';
import {
    FaBtc,
    FaChalkboardTeacher,
    FaCode,
    FaFacebookF,
    FaGoogle,
    FaQuestionCircle,
    FaTimes,
    FaTruck,
    FaVk,
} from 'react-icons/fa';
import {
    MdAccountBox,
    MdArrowBack,
    MdArrowForward,
    MdMonetizationOn,
    MdSave,
    MdSecurity,
    MdVerifiedUser,
    MdVpnKey,
    MdWeb,
    MdVpnLock,
    MdDomain,
} from 'react-icons/md';
import { TiDocument, TiDocumentText, TiImage } from 'react-icons/ti';
import {
    checkWMid,
    fetchFees,
    fetchFormValidations,
    fetchLocale,
    fetchStatistics,
    getPartnerPaymethods,
    getPaymethods,
} from '../api/settings';
import { fetchDealTransactionsList } from '../api/deals';
import {
    fetchContractTransactionsList,
    fetchContractTypes,
    fetchContractVersion,
} from '../api/contracts';
import { fetchUserProfile } from '../api/profile';
import i18next from 'i18next';
import moment from 'moment';
import cards from '../../assets/images/card.svg';
import card from '../../assets/images/card.svg';
import sbp from '../../assets/images/sbp.svg';
import webmoney_blue from '../../assets/images/webmoney.svg';
import webmoney from '../../assets/images/webmoney.svg';
import mir from '../../assets/images/mir.svg';
import yandex from '../../assets/images/yandex.svg';
import qiwi from '../../assets/images/qiwi_logo.svg';
import maestro from '../../assets/images/Maestro_Logo.svg';
import emoney from '../../assets/images/escrow_only_logo.svg';
import URLS from '../../constants/urls';
import { removeNotifications } from 'reapop';
import { getEventMessage } from './events';
import classNames from 'classnames';
import v1 from 'uuid/v1';
import { fetchTermsTemplate } from '../api/templates';
import v4 from 'uuid/v4';
import prettysize from 'prettysize';
import fallbackAvatar from '../../assets/images/avatar.svg';
import googleAvatar from '../../assets/images/google_avatar.svg';
import fbAvatar from '../../assets/images/facebook_avatar.svg';
import vkAvatar from '../../assets/images/vk_avatar.svg';
import webmoneyAvatar from '../../assets/images/webmoney_avatar.svg';
import MediaQuery from 'react-responsive';
import card_vertical from '../../assets/images/bank_card_vertical.svg';
import bitcoin from '../../assets/images/bitcoin.svg';
import StarRatingComponent from 'react-star-rating-component';
import { getContractStatus, getDealStatus } from './statuses';
import { Link } from 'react-router-dom';
import parseDomain from 'parse-domain';
import * as R from 'ramda';
import newPurse from '../../assets/images/dispute/005-credit-card-01.svg';
import validator, { isNumeric } from 'validator';

const os = require('os');
const hostname =
    os.hostname() === 'localhost' ? 'localhost:3000' : os.hostname();
const $ = window.$;

export const preventNonNumericalInput = (value, length) => {
    const val = value.slice(0, length);

    if (!isNumeric(val)) {
        return;
    }

    return val;
};

export const formatBirthDay = (value) => {
    let currentYear = new Date().getFullYear();

    function checkBirthdayValue(str, max) {
        if (str.charAt(0) !== '0' || str === '00') {
            let num = parseInt(str);
            if (isNaN(num) || num <= 0 || num > max) num = 1;
            if (
                num > parseInt(max.toString().charAt(0)) &&
                num.toString().length === 1
            ) {
                str = '0' + num;
            } else {
                str = num.toString();
            }
        }
        return str;
    }

    let input = value;
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    let values = input.split('.').map(function (v) {
        return v.replace(/\D/g, '');
    });
    if (values[0]) values[0] = checkBirthdayValue(values[0], 31); // day check
    if (values[1]) values[1] = checkBirthdayValue(values[1], 12); // month check
    if (values[2]) values[2] = checkBirthdayValue(values[2], currentYear); // year check
    let output = values.map(function (v, i) {
        return v.length === 2 && i < 2 ? v + '.' : v;
    });
    return output.join('').substr(0, 10);
};

export const formatCreditCard = (value) => {
    let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    let matches = v.match(/\d{4,19}/g);
    let match = (matches && matches[0]) || '';
    let parts = [];

    for (let i = 0; i < match.length; i += 4) {
        parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
        return parts.join(' ');
    } else {
        return value;
    }
};

export const formatString = (str, ...args) => {
    return str.replace(/{(\d+)}/g, (match, number) =>
        typeof args[number] != 'undefined' ? args[number] : match
    );
};

export const getPaymentMethodLogo = (method, large = false) => {
    switch (method) {
        case 'SBP':
            return <img src={sbp} alt={`${method}_logo`} />;
        case 'WebMoney':
            return <img src={webmoney} alt={`${method}_logo`} />;
        case 'Cards':
            if (!large) {
                return [
                    <MediaQuery key={v4()} maxWidth={992}>
                        <img src={card_vertical} alt={`${method}_logo`} />
                    </MediaQuery>,
                    <MediaQuery key={v4()} minWidth={992}>
                        <img src={card} alt={`${method}_logo`} />
                    </MediaQuery>,
                ];
            } else {
                return <img src={card_vertical} alt={`${method}_logo`} />;
            }

        case 'Bitcoin':
            return <img src={bitcoin} alt={`${method}_logo`} />;
        case 'Yandex':
            return <img src={yandex} alt={`${method}_logo`} />;
        case 'Qiwi':
            return <img src={qiwi} alt={`${method}_logo`} />;
        case 'Emoney':
            return <img src={emoney} alt={`${method}_logo`} />;
        case 'Mir':
            return <img src={mir} alt={`${method}_logo`} />;
        case 'Maestro':
            return <img src={maestro} alt={`${method}_logo`} />;
        default:
            return '';
    }
};

export const getImgWithDraw = (key) => {
    switch (key) {
        case 'webmoney':
            return webmoney_blue;
        case 'cards':
            return cards;
        case 'bitcoin':
            return bitcoin;
        case 'yandex':
            return yandex;
        case 'qiwi':
            return qiwi;
        case 'emoney':
            return emoney;
        case 'mir':
            return mir;
        case 'newPurse':
            return newPurse;
    }
};

export const renderNamePayMethod = (method) => {
    switch (method) {
        case 'WebMoneyWMP':
            return 'WebMoney';
        case 'Cards':
            return i18next.t('cards');
        case 'SBP':
            return 'СБП';
        case 'Yandex':
            return i18next.t('payment_yandex');
        case 'Qiwi':
            return i18next.t('payment_qiwi');
        case 'Emoney':
            return i18next.t('payment_emoney');
        case 'Bitcoin':
            return i18next.t('payment_bitcoin');
        default:
            return method;
    }
};

export const handleServerResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        throw {
            Code: response.status,
            Data: response.statusText,
        };
    }
};

export const handleDataReceived = (data) => {
    if (!data.IsSuccess || (data.IsSuccess && data.Code)) {
        throw data;
    }
    return data.Data;
};

export const handleDataReceivedAlt = (data) => {
    if (!data.IsSuccess || (data.IsSuccess && data.Code)) {
        throw data;
    }
    return { ...data.Data, ErrorCode: data.Code };
};

export const getData = (url, method = 'GET') => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const {
        user: { AccessToken },
    } = lStorage;

    return fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${AccessToken}`,
        },
    })
        .then(handleServerResponse)
        .then(handleDataReceived);
};

export const sendData = (method, url, data = {}) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const {
        user: { AccessToken },
    } = lStorage;

    return fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${AccessToken}`,
        },
        body: Object.keys(data).length > 0 ? param(data) : JSON.stringify(data),
    })
        .then(handleServerResponse)
        .then(handleDataReceived);
};

export const sendFile = (url, file) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const {
        user: { AccessToken },
    } = lStorage;

    const data = new FormData();
    data.append('file', file);
    data.append('ObjectType', 1);

    return fetch(url, {
        method: 'POST',
        headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${AccessToken}`,
        },
        body: data,
    })
        .then(handleServerResponse)
        .then(handleDataReceived);
};

export const wsConnect = (userData) => (dispatch) => {
    const { AccessToken, Id } = userData;

    window.userHub = $.connection.connectUserHub;
    $.connection.hub.qs = { bearer: AccessToken };

    window.userHub.client.addNewMessageToPage = (data) => {
        dispatch(getEventMessage(data));
    };

    window.userHub.client.setRead = (entityId) => {
        dispatch({
            type: C.SENT_MESSAGES_READ,
            payload: {
                EntityId: entityId,
                UserId: Id,
            },
        });
    };

    window.userHub.client.unreadCount = (count) => {
        dispatch({
            type: C.UPDATE_MESSAGES_COUNTER,
            payload: count,
        });
    };

    $.connection.hub
        .start()
        .done((data) => {
            console.log('Hub started...', data);
            // dispatch({
            //     type: C.WS_CONNECTED
            // });

            window.userHub.server.join();
        })
        .fail(function () {
            console.log('WS connection failed!');
        });
};

export const initWrapper = (store, renderApplication, renderError) => {
    const { dispatch, getState } = store;

    const url = window.location.hostname;
    const urlInfo = parseDomain(url);
    console.log('urlInfo', urlInfo);
    if (!!urlInfo && urlInfo.tld === 'ru') {
        dispatch(restrictBtc(true));
    } else {
        dispatch(restrictBtc(false));
    }

    dispatch(removeNotifications());
    dispatch(closeModal());

    dispatch({
        type: C.WS_DISCONNECT,
    });

    Promise.all([
        dispatch(fetchLocale()),
        dispatch(fetchStatistics()),
        dispatch(getPaymethods()),
        dispatch(fetchContractTypes()),
    ])
        .then(() => {
            const appState = store.getState();
            const {
                locale: { localizationData, lang },
                user,
            } = appState;

            dispatch({
                type: C.LANGUAGE_CHANGED,
                payload: 'ru-RU',
            });

            i18next.init({
                // lng: lang,
                lng: 'ru-RU',
                debug: true,
                resources: { ...localizationData },
            });

            if (user.loggedIn) {
                dispatch(userVerification(user))
                    .then(() => {
                        //Load all user data
                        dispatch(wsConnect(user));

                        return Promise.all([
                            dispatch(fetchFormValidations()),
                            dispatch(fetchUserProfile(user)),
                        ])
                            .then(() => {
                                const state = getState();
                                const {
                                    user: { SupportTicketGuid },
                                } = state;

                                if (SupportTicketGuid) {
                                    dispatch(
                                        fetchSupportMessagesByGuid(
                                            SupportTicketGuid
                                        )
                                    );
                                }

                                const { loginHistory } = appState;

                                if (loginHistory && loginHistory.length > 0) {
                                    loginHistory.map((entry) => {
                                        const { RefreshExpires } = entry;
                                        const d = new Date();
                                        const currentTime = d.getTime();

                                        const d2 = new Date(RefreshExpires);
                                        const refreshExpirationDate =
                                            d2.getTime();

                                        const refreshExpired =
                                            refreshExpirationDate -
                                                currentTime <=
                                            60000;

                                        if (refreshExpired) {
                                            dispatch({
                                                type: C.ACCOUNT_REFRESH_EXPIRED,
                                                payload: entry,
                                            });
                                        }
                                    });
                                }

                                renderApplication();
                            })
                            .catch((error) => {
                                const state = getState();
                                const { loadFailed } = state;

                                console.log('loadFailed', error);

                                if (loadFailed) {
                                    renderError();
                                } else {
                                    dispatch({
                                        type: C.LOAD_FAILED,
                                    });
                                    // window.location.reload();
                                }
                            });
                    })
                    .catch((error) => {
                        dispatch(handleLogout());
                        renderApplication();
                    });
            } else {
                renderApplication();
            }
        })
        .catch((err) => {
            dispatch(handleError(err));
            renderError();
        });
};

export const getEntityUrl = (entity) => {
    const isContract = !entity.ContractId;
    return isContract
        ? `/user/contracts/${entity.Id}`
        : `/user/deals/${entity.ContractId}`;
};

export const getTransactionEntityUrl = (transaction) => {
    const isContract = transaction.DealId === null;
    return isContract
        ? `/user/contracts/${transaction.ContractId}`
        : `/user/deals/${transaction.DealId}`;
};

export const copyTextToClipboard = (shareLink) => (dispatch) => {
    function fallbackCopyTextToClipboard(shareLink) {
        const textArea = document.createElement('textarea');
        textArea.value = shareLink;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
            dispatch(successNotifier(null, i18next.t('url_copied'), 'bc'));
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            dispatch(errorNotifier(i18next.t('url_copy_failed'), null, 'bc'));
        }

        document.body.removeChild(textArea);
    }

    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(shareLink);
        return;
    }

    navigator.clipboard.writeText(shareLink).then(
        function () {
            dispatch(successNotifier(null, i18next.t('url_copied'), 'bc'));
        },
        function () {
            dispatch(errorNotifier(i18next.t('url_copy_failed'), null, 'bc'));
        }
    );
};

export const arrayReorder = (arr, item) => {
    let from = 0;

    arr.map((element) => {
        if (
            element.hasOwnProperty('Id')
                ? element.Id === item
                : element === item
        ) {
            from = arr.indexOf(element);
        }
    });

    if (from > 2) {
        const element = arr.splice(from, 1)[0];
        arr.splice(0, 0, element);

        return arr;
    }

    return arr;
};

export const getUserRoleContractLabel = (contractType, userRole, type) => {
    const contractCategory = getContractCategory(contractType);

    return i18next.t(
        `${contractCategory[userRole]['ResourcesKey']}_${type}_label`
    );
};

export const getUserLabel = (category, userRole, type = 'deposit') => {
    return i18next.t(`${category[userRole]['ResourcesKey']}_${type}_label`);
};
export const getUserLabelName = (
    userRole,
    contractType,
    currentUserRole,
    type = 'deposit'
) => {
    return getUserRoleContractLabel(contractType, userRole, type);
};

export const replaceOnComma = (value = '') => String(value).replace('.', ',');

export const isChanged = (version, version2) => !isEqual(version, version2);

export const getPayerDepositBlock = (entity, currentUser) => {
    const isInitiator = entity.InitiatorRole === 'Payer';
    const payerDeposit = isInitiator
        ? entity.DepositInitiator
        : entity.DepositPartner;
    const { PayerCommission } = entity.fees;

    const hasVersion =
        !!entity.previousVersion && entity.previousVersion !== null;

    const prevPayerDeposit =
        hasVersion &&
        (isInitiator
            ? entity.previousVersion.DepositInitiator
            : entity.previousVersion.DepositPartner);
    const initiatedByCurrentUser =
        entity.Initiator.UserLogin === currentUser.UserLogin;
    const currentUserRole = initiatedByCurrentUser
        ? entity.InitiatorRole
        : entity.InitiatorRole === 'Payee'
        ? 'Payer'
        : 'Payee';

    return (
        <div className="deal-info__item">
            <label>
                {getUserLabelName(
                    'Payer',
                    entity.ContractType,
                    currentUserRole
                )}
            </label>
            <p className="deal-info__value">
                {hasVersion && isChanged(payerDeposit, prevPayerDeposit) && (
                    <span className="previous-value">
                        {formatSum(prevPayerDeposit)}
                    </span>
                )}
                {entity.DepositToPayee && i18next.t('payment_by_deposit')}
                {!entity.DepositToPayee && formatSum(payerDeposit)}
                &nbsp;
                {hasVersion &&
                    !entity.DepositToPayee &&
                    isChanged(
                        entity.Currency,
                        entity.previousVersion.Currency
                    ) && (
                        <span className="previous-value">
                            {getCurrencyName(entity.previousVersion)}
                        </span>
                    )}
                {!entity.DepositToPayee && getCurrencyName(entity)}
            </p>
            {PayerCommission > 0 && (
                <p className="tip">
                    {i18next.t('commission')}
                    <b>
                        +
                        {formatSum(PayerCommission) +
                            ' ' +
                            getCurrencyName(entity)}
                    </b>
                    <ReactTooltip
                        place="left"
                        className="tooltip"
                        id="commission-left"
                    />
                    <FaQuestionCircle
                        className="tooltip-icon"
                        data-for="commission-left"
                        data-tip={i18next.t('commission_text')}
                    />
                </p>
            )}
        </div>
    );
};

export const getPayeeDepositBlock = (entity, currentUser) => {
    const hasVersion = !!entity.previousVersion;
    const isInitiator = entity.InitiatorRole === 'Payee';
    const payeeDeposit = isInitiator
        ? entity.DepositInitiator
        : entity.DepositPartner;
    const prevPayeeDeposit =
        hasVersion &&
        (isInitiator
            ? entity.previousVersion.DepositInitiator
            : entity.previousVersion.DepositPartner);
    const { PayeeCommission } = entity.fees;
    const initiatedByCurrentUser =
        entity.Initiator.UserLogin === currentUser.UserLogin;
    const currentUserRole = initiatedByCurrentUser
        ? entity.InitiatorRole
        : entity.InitiatorRole === 'Payee'
        ? 'Payer'
        : 'Payee';

    return (
        <div className="deal-info__item">
            <label>
                {getUserLabelName(
                    'Payee',
                    entity.ContractType,
                    currentUserRole
                )}
            </label>
            <p className="deal-info__value">
                {hasVersion && isChanged(payeeDeposit, prevPayeeDeposit) && (
                    <span className="previous-value">
                        {formatSum(prevPayeeDeposit)}
                    </span>
                )}
                {formatSum(payeeDeposit)}&nbsp;
                {hasVersion &&
                    isChanged(
                        entity.Currency,
                        entity.previousVersion.Currency
                    ) && (
                        <span className="previous-value">
                            {getCurrencyName(entity.previousVersion)}
                        </span>
                    )}
                {getCurrencyName(entity)}
            </p>
            {PayeeCommission > 0 && (
                <p className="tip">
                    <ReactTooltip
                        place="right"
                        className="tooltip"
                        id="commission-right"
                    />
                    Комиссия:
                    <b>
                        {formatSum(PayeeCommission) +
                            ' ' +
                            getCurrencyName(entity)}
                    </b>
                    <FaQuestionCircle
                        data-for="commission-right"
                        data-tip={i18next.t('commission_tooltip')}
                        className="tooltip-icon"
                    />
                </p>
            )}
        </div>
    );
};

export const renderDepositBlock = (
    contract,
    prev,
    user,
    fees,
    initiatorBlock
) => {
    const isInitiator = user.UserLogin === contract.Initiator.UserLogin;

    const PartnerRole = contract.InitiatorRole === 'Payee' ? 'Payer' : 'Payee';

    const userRole = initiatorBlock ? contract.InitiatorRole : PartnerRole;
    const isPayee = userRole === 'Payee';

    const commission = isPayee ? fees.PayeeCommission : fees.PayerCommission;

    const hasVersion = !isEmpty(prev);

    const renderFee = () => {
        if (isPayee || (!isPayee && !contract.DepositToPayee)) {
            return (
                <small className="commission-amount">
                    <ReactTooltip place="right" className="tooltip" />
                    <span>
                        {`Комиссия: ${
                            formatSum(commission) +
                            ' ' +
                            getCurrencyName(contract)
                        }`}
                        <FaQuestionCircle
                            data-tip={`${shortCommissionTypeText(
                                contract.CommissionsType
                            )}
                Комиссия будет вычтена с залоговых средств`}
                            className="tooltip-icon"
                        />
                    </span>
                </small>
            );
        } else {
            return (
                <small className="commission-amount">
                    <ReactTooltip place="right" className="tooltip" />

                    {'+ ' +
                        formatSum(commission) +
                        ' ' +
                        getCurrencyName(contract)}
                    <FaQuestionCircle
                        className="tooltip-icon"
                        data-tip={shortCommissionTypeText(
                            contract.CommissionsType
                        )}
                    />
                </small>
            );
        }
    };
    const deposit = initiatorBlock ? 'DepositInitiator' : 'DepositPartner';

    return (
        <li>
            <label>
                {(initiatorBlock && isInitiator) ||
                (!initiatorBlock && !isInitiator)
                    ? 'Ваш залог:'
                    : getUserLabelName(userRole, contract.ContractType)}
            </label>
            <div>
                {hasVersion &&
                    isChanged(
                        contract[deposit] + contract.Currency,
                        prev[deposit] + prev.Currency
                    ) && (
                        <div className="previous-value">
                            {/*<MdWarning className="warning" data-tip="Партнер предложил новое условие"/>*/}
                            <span className="bold">
                                {formatSum(prev[deposit])}&nbsp;
                                {getCurrencyName(contract)}
                                <FaQuestionCircle
                                    data-tip="Условие изменено"
                                    className="tooltip-icon"
                                />
                            </span>
                        </div>
                    )}

                <span className="bold">
                    {initiatorBlock
                        ? formatSum(contract.DepositInitiator)
                        : formatSum(contract.DepositPartner)}
                    &nbsp;
                    {getCurrencyName(contract)}
                </span>

                {commission !== 0 && renderFee()}
            </div>
        </li>
    );
};

export const shortCommissionTypeText = (type) => {
    switch (type) {
        case 'FiftyFifty':
            return `Комиссию сервиса участники сделки оплачивают поровну`;
        case 'Payee':
            return `Комиссию сервиса оплачивает: ${getUserRole(
                type,
                'Payee'
            )} `;
        case 'Payer':
            return `Комиссию сервиса оплачивает: ${getUserRole(
                type,
                'Payer'
            )} `;
    }
};

export const getDepositSum = (data, userData) => {
    const isInitiator = userData.UserLogin === data.Initiator.UserLogin;
    const userDeposit = isInitiator
        ? data.DepositInitiator
        : data.DepositPartner;

    if (data.DepositToPayee) {
        return i18next.t('payment_by_deposit');
    } else {
        return formatSum(userDeposit) + ' ' + getCurrencyName(data);
    }
};

export const formatSum = (amount) =>
    parseFloat(amount).toLocaleString(undefined, {
        maximumFractionDigits: 6,
    });

export const formatAmount = (amount) =>
    parseFloat(amount).toLocaleString(undefined, {
        maximumFractionDigits: 2,
    });

export const getUserRolesList = (contractType) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const { contractTypes } = lStorage;
    const contractTypeData = Object.values(contractTypes).filter(
        (item) => item.Id === contractType
    )[0];
    const types = ['Payee', 'Payer', 'FiftyFifty'];

    return types.map((type) => {
        if (type !== 'FiftyFifty') {
            return {
                value: type,
                label: i18next.t(contractTypeData[type]['ResourcesKey']),
            };
        }
        return {
            value: type,
            label: 'Участниками сделки поровну',
        };
    });
};

export const getCurrencies = (selectedPaymentMethod) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const {
        paymentMethods: { clientList: paymentMethods },
    } = lStorage;

    const currenciesArr = paymentMethods[selectedPaymentMethod]?.Currencies;

    return currenciesArr.map((currency) => ({
        value: currency.Value,
        label: currency.Name,
    }));
};

export const getDiffCurrencies = (entity, userRole) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const { diffPaymentMethods } = lStorage;
    const { Paymethod, PartnerPaymethod, InitiatorRole } = entity;
    const sourcePaymethod =
        InitiatorRole === 'Payee' ? PartnerPaymethod : Paymethod;
    const destinationPaymethod =
        InitiatorRole === 'Payee' ? Paymethod : PartnerPaymethod;

    return Object.values(diffPaymentMethods)
        .filter(
            (item) =>
                item.SourcePayMethod === sourcePaymethod &&
                item.DestinationPayMethod === destinationPaymethod
        )
        .map((currency) => {
            return userRole === 'Payer'
                ? {
                      value: currency.DestinationCurrency.Value,
                      label: currency.DestinationCurrency.Value,
                  }
                : {
                      value: currency.SourceCurrency.Value,
                      label: currency.SourceCurrency.Value,
                  };
        });
};

export const getDiffCurrencyName = (entity, userRole) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const {
        diffPaymentMethods,
        paymentMethods: { defaultList: paymentMethods },
    } = lStorage;

    const { Paymethod, PartnerPaymethod, Currency, InitiatorRole } = entity;
    const sourcePaymethod =
        InitiatorRole === 'Payer' ? Paymethod : PartnerPaymethod;
    const destinationPaymethod =
        InitiatorRole === 'Payee' ? Paymethod : PartnerPaymethod;

    const currencyName = Object.values(diffPaymentMethods).filter(
        (item) =>
            item.SourcePayMethod === sourcePaymethod &&
            item.DestinationPayMethod === destinationPaymethod &&
            item.DestinationCurrency.Value === Currency
    );
    const currencySourcePayMethod =
        paymentMethods[sourcePaymethod]?.Currencies.filter(
            (cur) => cur.Value === Currency
        ) ?? [];

    const currencyDestinationPayMethod =
        paymentMethods[destinationPaymethod]?.Currencies.filter(
            (cur) => cur.Value === Currency
        ) ?? [];

    return currencyName.length
        ? userRole === 'Payee'
            ? currencyName[0].DestinationCurrency.Name
            : currencyName[0].SourceCurrency.Name
        : userRole === 'Payee'
        ? currencySourcePayMethod.length
            ? currencySourcePayMethod[0].Name
            : Currency
        : currencyDestinationPayMethod.length
        ? currencyDestinationPayMethod[0].Name
        : Currency;
};

export const getImage = (key) => {
    switch (key) {
        case 'webmoney':
        case 'webmoneywmp':
            return webmoney_blue;
        case 'cards':
            return cards;
        case 'bitcoin':
            return bitcoin;
        case 'yandex':
            return yandex;
        case 'qiwi':
            return qiwi;
        case 'emoney':
            return emoney;
        case 'mir':
            return mir;
        case 'sbp':
            return sbp;
    }
};

export const getPaymentMethodsList = (currentInfo, currentUser) => {
    if (!currentUser) return;
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const { diffPaymentMethods } = lStorage;
    const initiatedByCurrentUser =
        currentUser &&
        currentInfo &&
        currentInfo.Initiator.UserLogin === currentUser.UserLogin;
    const filterPayMethod = initiatedByCurrentUser
        ? currentInfo.PartnerPaymethod === null
            ? currentInfo.Paymethod
            : currentInfo.PartnerPaymethod
        : currentInfo.Paymethod;
    const currentUserRole = initiatedByCurrentUser
        ? currentInfo.InitiatorRole
        : currentInfo.InitiatorRole === 'Payee'
        ? 'Payer'
        : 'Payee';
    const defaultCurrentPayMethod = {
        value: filterPayMethod,
        label:
            filterPayMethod === 'Cards' ? 'Банковская карта' : filterPayMethod,
        imageUrl: getImage(filterPayMethod.toLowerCase()),
    };

    const payerPayMethods = Object.values(diffPaymentMethods)
        .filter((item) =>
            currentUserRole === 'Payer'
                ? item.DestinationPayMethod === filterPayMethod
                : item.SourcePayMethod === filterPayMethod
        )
        .map(({ DestinationPayMethod, SourcePayMethod }) => {
            return {
                value:
                    currentUserRole === 'Payer'
                        ? SourcePayMethod
                        : DestinationPayMethod,
                label:
                    currentUserRole === 'Payer'
                        ? SourcePayMethod === 'Cards'
                            ? 'Банковская карта'
                            : SourcePayMethod
                        : DestinationPayMethod === 'Cards'
                        ? 'Банковская карта'
                        : DestinationPayMethod,
                imageUrl: getImage(
                    currentUserRole === 'Payer'
                        ? SourcePayMethod.toLowerCase()
                        : DestinationPayMethod.toLowerCase()
                ),
            };
        });

    return Object.values(
        [defaultCurrentPayMethod, ...payerPayMethods].reduce(
            (unique, current) =>
                Object.assign(unique, { [current.value]: current }),
            {}
        )
    );
};

export const getSelectedPaymentMethod = (paymentMethod) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const {
        paymentMethods: { defaultList: paymentMethods },
    } = lStorage;

    return (
        !!paymentMethods[paymentMethod] && (
            <div className="select-option">
                <p>
                    <img
                        src={getImage(paymentMethod.toLowerCase())}
                        alt={paymentMethod}
                    />
                    <span>{renderNamePayMethod(paymentMethod)}</span>
                </p>
            </div>
        )
    );
};

export const getTransactionStatus = (status) => {
    switch (status) {
        case 0:
            return (
                <span className="badge badge_blue">
                    {i18next.t('transaction_processing')}
                </span>
            );
        case 2:
            return (
                <span className="badge badge_dark-gray">
                    {i18next.t('new_one')}
                </span>
            );
        case 1:
            return (
                <span className="badge badge_green">
                    {i18next.t('transaction_completed')}
                </span>
            );
        case -1:
            return (
                <span className="badge badge_red">
                    {i18next.t('transaction_failed')}
                </span>
            );
    }
};
export const dealStatusesDictionary = {
    new: i18next.t('new_deal_status_text'),
    paid: i18next.t('paid'),
    confirmedByPayer: i18next.t('confirmed_by_payer'),
    confirmedByPayee: i18next.t('confirmed_by_payee'),
    closed: i18next.t('deal_closed'),
    dealCanceled: 'Сделка отменена',
    resolved: i18next.t('dispute_closed'),
    documentsRequired:
        'Ожидается подтверждение паспортных данных продавца/исполнителя',
    userDocumentsRequiredDealIsClosing:
        'Ожидаются ваши паспортные данные. Закрытие сделки в процессе',
    partnerDocumentsRequiredDealIsClosing:
        'Ожидаются паспортные данные партнёра. Закрытие сделки в процессе',

    userDocumentsRequiredArbitraryClosing:
        'Ожидаются ваши паспортные данные. Закрытие сделки от арбитража в процессе',
    partnerDocumentsRequiredArbitraryClosing:
        'Ожидаются паспортные данные партнёра. Закрытие сделки от арбитража в процессе',

    userDocumentsRequiredExpiredDealIsClosing:
        'Ожидаются ваши паспортные данные. Закрытие истёкшей сделки в процессе',
    partnerDocumentsRequiredExpiredDealIsClosing:
        'Ожидаются паспортные данные партнёра. Закрытие истёкшей сделки в процессе',
    disputed: 'Открыт спор',
    disputedExpired: 'Просрочено выполнение. Открыт спор',
    disputedByUser:
        'Вы открыли спор по данной сделке. Сделке была отправлена на рассмотрение в арбитраж. Ожидайте решения арбитра.',
    disputedByPartner:
        'Ваш партнер открыл спор по данной сделке. Сделка была отправлена на рассмотрение в арбитраж. Ожидайте решения арбитра.',
    timeout: 'Срок действия сделки истек',
    returningMoney: 'Производится выплата средств',
    returningMoneyDealClosing:
        'Производится выплата средств. Закрытие сделки в процессе',
    returningMoneyDealCancelProcessing:
        'Производится выплата средств. Отмена сделки в процессе',
    returningMoneyArbitraryClosing:
        'Производится выплата средств. Закрытие сделки от арбитража в процессе',
    returningMoneyArbitraryCanceling:
        'Производится выплата средств. Отмена сделки от арбитража в процессе',
    returningMoneyExpiredDealClosing:
        'Производится выплата средств. Закрытие истёкшей сделки в процессе',
    returningMoneyExpiredDealCanceling:
        'Производится выплата средств. Отмена истёкшей сделки в процессе',
};
const dealStatusesDictionaryShort = {
    new: i18next.t('new_deal_status_text'),
    paid: i18next.t('paid'),
    confirmedByPayer: i18next.t('confirmed_by_payer'),
    confirmedByPayee: i18next.t('confirmed_by_payee'),
    closed: i18next.t('deal_closed'),
    dealCanceled: 'Сделка отменена',
    resolved: i18next.t('dispute_closed'),
    documentsRequired:
        'Ожидается подтверждение паспортных данных продавца/исполнителя',
    userDocumentsRequiredDealIsClosing:
        'Ожидаются ваши паспортные данные. Закрытие сделки в процессе',
    partnerDocumentsRequiredDealIsClosing:
        'Ожидаются паспортные данные партнёра. Закрытие сделки в процессе',
    userDocumentsRequiredArbitraryClosing:
        'Ожидаются ваши паспортные данные. Закрытие сделки от арбитража в процессе',
    partnerDocumentsRequiredArbitraryClosing:
        'Ожидаются паспортные данные партнёра. Закрытие сделки от арбитража в процессе',
    userDocumentsRequiredExpiredDealIsClosing:
        'Ожидаются ваши паспортные данные. Закрытие истёкшей сделки в процессе',
    partnerDocumentsRequiredExpiredDealIsClosing:
        'Ожидаются паспортные данные партнёра. Закрытие истёкшей сделки в процессе',
    disputed: 'Открыт спор',
    disputedExpired: 'Просрочено выполнение. Открыт спор',
    disputedByUser: 'Вы открыли спор',
    disputedByPartner: 'Партнер открыл спор',
    timeout: 'Срок действия истек',
    returningMoney: 'Производится выплата средств',
};

export const getDealStatusBadge = (deal, user) => {
    const isInitiator = user.UserLogin === deal.Initiator.UserLogin;
    const dealStatus = getDealStatus(deal);

    switch (dealStatus) {
        case 'new':
            return (
                <span className="badge badge_dark-gray">
                    {i18next.t('new_deal_status_text')}
                </span>
            );
        case 'confirmedByPayer':
        case 'confirmedByPayee':
            return (
                <span className="badge badge_blue">
                    {dealStatusesDictionaryShort[dealStatus]}
                </span>
            );
        case 'closed':
            return (
                <span className="badge badge_green">
                    {dealStatusesDictionaryShort.closed}
                </span>
            );
        case 'resolved':
            return (
                <span className="badge badge_orange">
                    {dealStatusesDictionaryShort.resolved}
                </span>
            );
        case 'documentsRequired':
        case 'payeeDocumentsRequiredDealIsClosing':
        case 'payeeDocumentsRequiredArbitraryClosing':
        case 'payerDocumentsRequiredArbitraryClosing':
        case 'payeeDocumentsRequiredExpiredDealIsClosing':
            return (
                <span className="badge badge_orange">
                    {dealStatusesDictionaryShort.documentsRequired}
                </span>
            );
        case 'disputed':
            let text;
            if (!deal.DisputeReason)
                text = dealStatusesDictionaryShort.disputed;
            if (
                (deal.DisputeReason === 'ByInitiator' && isInitiator) ||
                (deal.DisputeReason === 'ByPartner' && !isInitiator)
            ) {
                text = dealStatusesDictionaryShort.disputedByUser;
            } else if (
                (deal.DisputeReason === 'ByInitiator' && !isInitiator) ||
                (deal.DisputeReason === 'ByPartner' && isInitiator)
            ) {
                text = dealStatusesDictionaryShort.disputedByPartner;
            } else if (deal.DisputeReason === 'Timeout') {
                text = dealStatusesDictionaryShort.disputedExpired;
            }

            return (
                <span className="badge badge_red">
                    {dealStatusesDictionaryShort.disputed}
                </span>
            );

        case 'timeout':
            return (
                <span className="badge badge_light-gray">
                    {dealStatusesDictionaryShort.timeout}
                </span>
            );
        case 'returningMoney':
        case 'returningMoneyDealClosing':
        case 'returningMoneyDealCancelProcessing':
        case 'returningMoneyArbitraryClosing':
        case 'returningMoneyArbitraryCanceling':
        case 'returningMoneyExpiredDealClosing':
        case 'returningMoneyExpiredDealCanceling':
            return (
                <span className="badge badge_light-gray">
                    {dealStatusesDictionaryShort.returningMoney}
                </span>
            );
    }
};

export const getContractStatusBadge = (contract, isInitiator) => {
    const status = getContractStatus(contract);

    switch (status) {
        case 'initiatorsContract':
        case 'partnersContract':
            if (contract.Version > 1) {
                return (
                    <span className="badge badge_orange">
                        {i18next.t('counter_offer')}
                    </span>
                );
            }
            return (
                <span className="badge badge_light-gray">
                    {i18next.t('new_contract')}
                </span>
            );

        case 'dealCreated':
        case 'totallyAccepted':
            return (
                <span className="badge badge_dark-gray">
                    {i18next.t('contract_status_4')}
                </span>
            );
        case 'closed':
            return (
                <span className="badge badge_dark-gray">
                    {i18next.t('canceled')}
                </span>
            );
        case 'expired':
            return (
                <span className="badge badge_orange">
                    {i18next.t('expired_contract_status_text')}
                </span>
            );
        case 'returningMoney':
            return (
                <span className="badge badge_dark-gray">
                    {i18next.t('return_in_progress')}
                </span>
            );

        case 'acceptedByInitiator':
        case 'paidByInitiator':
            if (isInitiator) {
                return (
                    <span className="badge badge_green">
                        {i18next.t('contract_status_3')}
                    </span>
                );
            } else {
                return (
                    <span className="badge badge_green">
                        {i18next.t('contract_status_2')}
                    </span>
                );
            }

        case 'acceptedByPartner':
        case 'paidByPartner':
            if (!isInitiator) {
                return (
                    <span className="badge badge_green">
                        {i18next.t('contract_status_3')}
                    </span>
                );
            } else {
                return (
                    <span className="badge badge_green">
                        {i18next.t('contract_status_2')}
                    </span>
                );
            }

        case 'initiatorDepositRequired':
            if (isInitiator) {
                return (
                    <span className="badge badge_green">
                        {i18next.t('contract_status_1')}
                    </span>
                );
            } else {
                return (
                    <span className="badge badge_green">
                        {i18next.t('contract_status_1')}
                    </span>
                );
            }

        case 'partnerDepositRequired':
            if (!isInitiator) {
                return (
                    <span className="badge badge_green">
                        {i18next.t('contract_status_1')}
                    </span>
                );
            } else {
                return (
                    <span className="badge badge_green">
                        {i18next.t('contract_status_1')}
                    </span>
                );
            }
    }
};

export const getContractCategory = (contractType) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const { contractTypes } = lStorage;

    return (
        R.prop(contractType, contractTypes) || R.head(R.values(contractTypes))
    );
};

export const getCommissionPayer = (
    contractCategory,
    CommissionsType,
    isChanged = false
) => {
    const typeClassName = classNames({
        'previous-value': isChanged,
    });

    switch (CommissionsType) {
        case 'FiftyFifty':
            return (
                <span className={typeClassName}>
                    {i18next.t('fifty_fifty')}
                </span>
            );

        case 'Payee':
            if (isChanged) {
                return (
                    <span className={typeClassName}>
                        {' '}
                        {i18next.t(contractCategory['Payee']['ResourcesKey'])}
                    </span>
                );
            } else {
                return [
                    <span className={typeClassName} key={v1()}>
                        {' '}
                        {i18next.t(contractCategory['Payee']['ResourcesKey'])}
                    </span>,
                    <ReactTooltip className="tooltip" key={v1()} />,
                    <FaQuestionCircle
                        className="tooltip-icon"
                        key={v1()}
                        data-tip={i18next.t('commission_tooltip')}
                    />,
                ];
            }

        case 'Payer':
            if (isChanged) {
                return (
                    <span className={typeClassName}>
                        {i18next.t(contractCategory['Payer']['ResourcesKey'])}
                    </span>
                );
            } else {
                return [
                    <span className={typeClassName} key={v1()}>
                        {i18next.t(contractCategory['Payer']['ResourcesKey'])}
                    </span>,
                    <ReactTooltip className="tooltip" key={v1()} />,
                    <FaQuestionCircle
                        className="tooltip-icon"
                        key={v1()}
                        data-tip={i18next.t('commission_text')}
                    />,
                ];
            }
    }
};

export const renderCommissionBlock = (entity) => {
    const { fees, previousVersion } = entity;
    const contractCategory = getContractCategory(entity.ContractType);
    const commissionTotal = fees.PayeeCommission + fees.PayerCommission;

    return (
        <div className="two column row">
            <div className="column">
                <div className="deal-info__item amount">
                    <label>{i18next.t('service_commission')}:</label>
                    <div className="deal-info__value">
                        {commissionTotal + ' ' + getCurrencyName(entity)}
                    </div>
                </div>
            </div>
            <div className="column">
                <div className="deal-info__item">
                    <label>{i18next.t('commission_is_paid_by')}:</label>
                    <div className="deal-info__value">
                        {previousVersion &&
                            isChanged(
                                entity.CommissionsType,
                                previousVersion.CommissionsType
                            ) &&
                            getCommissionPayer(
                                contractCategory,
                                previousVersion.CommissionsType,
                                true
                            )}
                        {getCommissionPayer(
                            contractCategory,
                            entity.CommissionsType
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const renderPaymentMethodIco = (method, theme = 'white') => {
    if (theme === 'white') {
        switch (method) {
            case 'WebMoney':
            case 'WebMoneyWMP':
                return webmoney;
            case 'Bitcoin':
                return bitcoin;
            case 'Cards':
                return card;
            case 'SBP':
                return sbp;
        }
    } else if (theme === 'colored') {
        switch (method) {
            case 'WebMoney':
            case 'WebMoneyWMP':
                return webmoney_blue;
            case 'Bitcoin':
                return bitcoin;
            case 'Cards':
                return card;
            case 'SBP':
                return sbp;
        }
    }
};

export const getArrow = (entity, type, user) => {
    const initiatedByUser = user.UserLogin === entity.Initiator.UserLogin;

    if (type !== 'deal') {
        const InitiatorsContract =
            entity.Status === 1 ||
            (entity.Status === 3 && entity.InProgressState % 2);

        const PartnersContract =
            entity.Status === 2 ||
            (entity.Status === 3 && entity.InProgressState % 2 === 0);

        if (
            (InitiatorsContract && initiatedByUser) ||
            (PartnersContract && !initiatedByUser)
        ) {
            return <div className="arrow-container pointing-right" />;
        } else {
            return <div className="arrow-container pointing-left" />;
        }

        // if (InitiatorsContract && InitiatorRole === 'Payee' || PartnersContract && InitiatorRole === 'Payer') {
        //     return <div className="arrow-container pointing-right"/>;
        // }
        // else if (InitiatorsContract && InitiatorRole === 'Payer' || PartnersContract && InitiatorRole === 'Payee') {
        //     return <div className="arrow-container pointing-left"/>
        // } else {
        //     return null;
        // }
    } else {
        // if(initiatedByUser){
        //     return <div className="arrow-container pointing-right"/>;
        // }else{
        //     return <div className="arrow-container pointing-left"/>;
        // }
        // switch (InitiatorRole) {
        //     case 'Payer':
        //         return <div className="arrow-container pointing-left"/>;
        //     case 'Payee':
        //         return <div className="arrow-container pointing-right"/>;
        // }
    }
};

export const getUserRole = (contractType, userRole) => {
    const contractCategory = getContractCategory(contractType);
    return i18next.t(contractCategory[userRole]['ResourcesKey']);
};

export const getUserBadge = (entity, userRole) => {
    const contractCategory = getContractCategory(entity.ContractType);

    switch (userRole) {
        case 'Payee':
            return (
                <span className="badge badge_light-gray">
                    {i18next.t(contractCategory['Payee']['ResourcesKey'])}
                </span>
            );
        case 'Payer':
            return (
                <span className="badge badge_light-gray">
                    {i18next.t(contractCategory['Payer']['ResourcesKey'])}
                </span>
            );
        default:
            return null;
    }
};

export const getUserDepositSumBlock = (data, userData) => {
    const isInitiator = userData.UserLogin === data.Initiator.UserLogin;

    const userRole = isInitiator
        ? data.InitiatorRole
        : data.InitiatorRole === 'Payee'
        ? 'Payer'
        : 'Payee';

    const userDeposit = isInitiator
        ? data.DepositInitiator
        : data.DepositPartner;

    if (data.DepositToPayee && userRole === 'Payer') {
        return (
            <span className="amount">{i18next.t('payment_by_deposit')}</span>
        );
    } else {
        return (
            <span className="amount">
                <label>{i18next.t('deposit')}:</label>
                {formatSum(userDeposit) + ' ' + getCurrencyName(data)}
            </span>
        );
    }
};

export const getDaytimeCases = (daysNum) => {
    let metering = 0;
    let lastDigits = parseInt(daysNum) % 100;

    lastDigits = lastDigits >= 20 ? lastDigits % 10 : lastDigits;

    if (lastDigits === 0 || (lastDigits >= 5 && lastDigits <= 20)) {
        metering = 0;
    } else if (lastDigits === 1) {
        metering = 1;
    } else {
        metering = 2;
    }

    switch (metering) {
        case 0:
            return i18next.t('days_genitive');
        case 1:
            return i18next.t('day_objective');
        case 2:
            return i18next.t('day_genitive');
    }
};

export const getUserExternalProfileUrl = (userData) => {
    switch (userData.LoginProvider) {
        case 'WebMoney':
            return URLS.WMID_PASSPORT_URL + userData.UserLogin;
        case 'Gmail':
            return URLS.GOOGLE_USER_PROFILE_URL + userData.UserLogin;
        case 'Facebook':
            return URLS.FACEBOOK_USER_PROFILE_URL + userData.UserLogin;
        case 'Vkontakte':
            return URLS.VK_USER_PROFILE_URL + userData.UserLogin;
        default:
            return null;
    }
};

export const getUserProfileUrl = (userData, secured = false) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const { user } = lStorage;
    const isUser = user.UserLogin === userData.UserLogin;

    if (secured) {
        return isUser ? '/profile' : '/user-info/' + userData.Id + '?short=1';
    } else {
        return isUser ? '/profile' : '/user-info/' + userData.Id;
    }
};

export const getUserName = (userData, secured = false) => {
    if (userData.FirstName) {
        // if (secured) {
        //     return (
        //         userData.FirstName + ' ' + userData.Surname.slice(0, 1) + '.'
        //     );
        // }
        return userData.UserLogin;
    } else {
        return `User_${userData.Id}`;
    }
};

// export const getUserName = (userData) => {
//
//     switch (userData.LoginProvider) {
//         case 'WebMoney':
//             return <a href={getUserProfileUrl(userData)}
//                       className="user-profile-link"
//                       target='_blank'>
//
//                 <span className="name">
//                       {
//                           userData.FirstName ? userData.FirstName + ' ' + userData.Surname : `User${userData.Id}`
//                       }
//                 </span>
//             </a>;
//
//
//         case 'Gmail':
//             return <a target='_blank'
//                       className="user-profile-link"
//                       href={getUserProfileUrl(userData, isUser)}>
//             <span className="name">
//                       {
//                           userData.FirstName ? userData.FirstName + ' ' + userData.Surname : `User${userData.Id}`
//                       }
//                 </span>
//                 {
//                     isUser &&
//                     <span className="badge badge_green">{i18next.t('you')}</span>
//                 }
//             </a>;
//         case 'Facebook':
//             return <a target='_blank'
//                       className="user-profile-link"
//                       href={getUserProfileUrl(userData, isUser)}>
//                <span className="name">
//                       {
//                           userData.FirstName ? userData.FirstName + ' ' + userData.Surname : `User${userData.Id}`
//                       }
//                 </span>
//                 {
//                     isUser &&
//                     <span className="badge badge_green">{i18next.t('you')}</span>
//                 }
//             </a>;
//         case 'Vkontakte':
//
//             return <a target='_blank'
//                       className="user-profile-link"
//                       href={getUserProfileUrl(userData, isUser)}>
//         <span className="name">
//                       {
//                           userData.FirstName ? userData.FirstName + ' ' + userData.Surname : `User${userData.Id}`
//                       }
//                 </span>
//                 {
//                     isUser &&
//                     <span className="badge badge_green">{i18next.t('you')}</span>
//                 }
//             </a>;
//
//         default:
//             return <a href={getUserProfileUrl(userData, isUser)}
//                       target='_blank'
//                       className="user-profile-link">
//            <span className="name">
//                       {
//                           userData.FirstName ? userData.FirstName + ' ' + userData.Surname : `User${userData.Id}`
//                       }
//                 </span>
//                 {
//                     isUser &&
//                     <span className="badge badge_green">{i18next.t('you')}</span>
//                 }
//             </a>;
//     }
// };

export const getUserLogin = (userData) => {
    switch (userData.LoginProvider) {
        case 'Gmail':
        case 'Facebook':
        case 'Vkontakte':
            return userData.Email;

        default:
            return userData.UserLogin;
    }
};

// export const renderTimerBlock = (contract, contractType, prev, openModal) => {
//     const now = moment();
//
//     switch (contractType) {
//         case 'deal':
//             return getDealTimer(contract, openModal);
//         // const duration = moment(contract.Duration).locale('ru').fromNow('d');
//         // let dealExpired = moment(contract.Duration).diff(now) < 0;
//         //
//         // return !dealExpired ? <ul className="timer-block">
//         //         <li>
//         //             <label>Сделка действительна еще:</label>
//         //             <p>
//         //                 <b>{duration}</b>
//         //                 &nbsp;до&nbsp;
//         //                 <b>
//         //                     {moment(contract.Duration).format('DD.MM.YYYY')}
//         //                 </b>
//         //             </p>
//         //
//         //         </li>
//         //     </ul> :
//         //     <ul className="timer-block timer-block_expired">
//         //         <li>
//         //             <p>Срок действия сделки истек</p>
//         //         </li>
//         //     </ul>;
//         default :
//             const contractDuration = moment(contract.ContractDuration).locale('ru').fromNow('d');
//             const dealDuration = moment(contract.DealDuration).locale('ru').from(moment(contract.ContractDuration), 'd');
//             const hasVersion = !isEmpty(prev);
//
//             const expired = moment(contract.ContractDuration).diff(now) < 0;
//
//             return !expired ? <ul className="timer-block">
//                     <li>
//                         <label>Предложение действительно еще:</label>
//                         <p>
//                             <b>{contractDuration}</b>
//                             &nbsp;до&nbsp;
//                             <b>
//                                 {moment(contract.ContractDuration).format('DD.MM.YYYY')}
//                             </b>
//                         </p>
//                     </li>
//                     <li>
//                         <label>После принятия предложения на сделку будет отведено:</label>
//                         {
//                             ( hasVersion && prev.DealDuration !== contract.DealDuration)
//                             &&
//                             <p className="prev">
//                                 <b>
//                                     {
//                                         moment(prev.DealDuration).locale('ru').from(moment(prev.ContractDuration), 'd')
//                                     }
//                                 </b>
//                                 &nbsp;до&nbsp;
//                                 <b>{moment(prev.DealDuration).format('DD.MM.YYYY')}</b>
//                                 <FaQuestionCircle data-tip="Условие изменено"
//                                                   className="tooltip-icon"/>
//                             </p>
//                         }
//                         <p>
//                             <b>{dealDuration}</b>
//                             &nbsp;до&nbsp;
//                             <b>{moment(contract.DealDuration).format('DD.MM.YYYY')}</b>
//                         </p>
//                     </li>
//
//                 </ul> :
//                 <ul className="timer-block timer-block_expired">
//                     <li>
//                         <p>Срок действия предложения истек</p>
//                     </li>
//                 </ul>
//     }
// };

// export const getPaymentHelperTip = (allowed, isPayer) => {
//     if (!allowed.includes('Cards')) {
//         if (isPayer) {
//             return <div className="ui info tiny message">Для совершения сделок с использованием карт вашему партнеру по
//                 сделке необходимо заполнить паспортные данные в профиле</div>;
//         } else {
//             return <div className="ui info tiny message">Для совершения сделок с использованием карт вам необходимо
//                 заполнить паспортные данные в профиле</div>;
//         }
//
//     }
// };

export const getShortUserInfoLabel = (userInfo) => {
    switch (userInfo.LoginProvider) {
        case 'Site':
        case 'WebMoney':
            if (userInfo.FirstName && userInfo.Surname) {
                return [
                    <p key="0">
                        {userInfo.FirstName + ' ' + userInfo.Surname}
                    </p>,
                    <span key="1">
                        {i18next.t('login')}: {userInfo.UserLogin}
                    </span>,
                ];
            } else {
                return [
                    <p key="2">{userInfo.UserLogin}</p>,
                    userInfo.UserLogin !== userInfo.Email && (
                        <span key="4">{userInfo.Email}</span>
                    ),
                ];
            }
        default:
            return <p>{userInfo.Email}</p>;
    }
};

export const renderDealStatusText = (deal, user) => {
    const status = getDealStatus(deal);

    const userRole =
        deal.Initiator.UserLogin === user.UserLogin
            ? deal.InitiatorRole
            : deal.InitiatorRole === 'Payee'
            ? 'Payer'
            : 'Payee';

    switch (status) {
        case 'new':
            return (
                <div className="message message_primary message_centered">
                    <p>
                        Новая сделка. Ожидается выполение сторонами условий
                        сделки
                    </p>
                </div>
            );
        case 'confirmedByPayee':
            return userRole === 'Payee' ? (
                <div className="message message_primary message_centered">
                    <p>Вы подтвердили выполнение условий сделки</p>
                </div>
            ) : (
                <div className="message message_primary message_centered">
                    <p>
                        Ваш партнер подтвердил выполнение условий сделки.
                        Подтвердите сделку после получения товара/выполнения
                        партнером ее условий
                    </p>
                </div>
            );

        case 'confirmedByPayer':
            return userRole === 'Payer' ? (
                <div className="message message_primary message_centered">
                    <p>Вы подтвердили выполнение условий сделки</p>
                </div>
            ) : (
                <div className="message message_primary message_centered">
                    <p>
                        {!deal.DepositToPayee
                            ? 'Подтвердите сделку после получения оплаты'
                            : 'Ваш партнер подтвердил сделку.'}
                    </p>
                </div>
            );

        case 'disputed':
            return (
                <div className="message message_warning message_centered">
                    <p>
                        По сделке открыт спор. Ожидайте решения арбитража в
                        чате.
                    </p>
                </div>
            );

        case 'closed':
            return (
                <div className="message message_success message_centered">
                    <p>Сделка подтверждена партнерами</p>
                </div>
            );

        case 'documentsRequired':
            return (
                <div className="message message_warning message_centered">
                    <p>Ожидается подтверждение паспортных данных продавца</p>
                    {userRole === 'Payee' && (
                        <p>
                            Перейдите в <Link to="/profile">профиль</Link>,
                            чтобы добавить паспортные данные
                        </p>
                    )}
                </div>
            );

        case 'returningMoney':
            return (
                <div className="message message_primary message_centered">
                    <p>Идет передача средств...</p>
                </div>
            );

        default:
            return null;
    }
};

export const renderContractStatusText = (contract, user) => {
    const { Transactions: transactions } = contract;
    const hasTransactionsInProcess = transactions
        ? transactions.filter(
              (trans) => trans.Status === 2 || trans.Status === 0
          ).length !== 0
        : false;
    const isBtc = contract.Paymethod === 'Bitcoin';

    const status = getContractStatus(contract);
    console.log('status', status);

    const isInitiator = user.UserLogin === contract.Initiator.UserLogin;
    const getText = () => {
        switch (status) {
            case 'initiatorsContract':
                return isInitiator ? (
                    <p className="message message_info">
                        {i18next.t('deal_offer_created_text')}
                    </p>
                ) : (
                    <p className="message message_info">
                        {i18next.t('new_offer_received_text')}
                    </p>
                );

            case 'partnersContract':
                return !isInitiator ? (
                    <p className="message message_info">
                        {i18next.t('deal_offer_created_text')}
                    </p>
                ) : (
                    <p className="message message_info">
                        {i18next.t('new_offer_received_text')}
                    </p>
                );

            case 'returningMoney':
                return <p>{i18next.t('return_in_progress')}</p>;

            case 'acceptedByInitiator':
                return isInitiator ? (
                    <p>{i18next.t('offer_accepted')}</p>
                ) : (
                    <p>{i18next.t('partner_accepted_offer')}</p>
                );

            case 'acceptedByPartner':
                return !isInitiator ? (
                    <p>{i18next.t('offer_accepted')}</p>
                ) : (
                    <p>{i18next.t('partner_accepted_offer')}</p>
                );

            case 'paidByInitiator':
                return isInitiator ? (
                    <p>
                        {i18next.t('your_deposit_received')}
                        {hasTransactionsInProcess &&
                            isBtc &&
                            'payment processing'}
                    </p>
                ) : (
                    <p>
                        {i18next.t('partner_deposit_received')}
                        {hasTransactionsInProcess &&
                            isBtc &&
                            'payment processing'}
                    </p>
                );

            case 'paidByPartner':
                return !isInitiator ? (
                    <p className="message message_info">
                        {i18next.t('offer_accepted')}. &nbsp;
                        {hasTransactionsInProcess &&
                            i18next.t('payment_processing_2')}
                    </p>
                ) : (
                    <p className="message message_info">
                        {i18next.t('partner_accepted_offer')}. &nbsp;
                        {hasTransactionsInProcess &&
                            i18next.t('payment_processing')}
                    </p>
                );

            case 'initiatorDepositRequired':
                return isInitiator ? (
                    <p>
                        {i18next.t('contract_status_text_1')}
                        {hasTransactionsInProcess &&
                            isBtc &&
                            'payment processing'}
                    </p>
                ) : (
                    <p>
                        {i18next.t('contract_status_text_2')}
                        {hasTransactionsInProcess &&
                            isBtc &&
                            'payment processing'}
                    </p>
                );

            case 'partnerDepositRequired':
                return !isInitiator ? (
                    <p>
                        {i18next.t('contract_status_text_1')}
                        {hasTransactionsInProcess &&
                            isBtc &&
                            'payment processing'}
                    </p>
                ) : (
                    <p>
                        {i18next.t('contract_status_text_2')}
                        {hasTransactionsInProcess &&
                            isBtc &&
                            'payment processing'}
                    </p>
                );

            case 'totallyAccepted':
                return <p>{i18next.t('contract_status_text_3')}</p>;

            case 'closed':
                return <p>{i18next.t('contract_status_text_4')}</p>;
            case 'dealCreated':
                return <p>Партнеры приняли соглашение по условиям сделки.</p>;
            default:
                return null;
        }
    };

    return <div className="status-badge primary">{getText()}</div>;
};

const getUserRequests = () => {
    const lStorage = JSON.parse(localStorage.getItem('state'));

    const OutgoingDealProlongRequests = lStorage.user.Requests
        ? lStorage.user.Requests.OutputLists.DealsProlongation
        : [];
    const IncomingDealProlongRequests = lStorage.user.Requests
        ? lStorage.user.Requests.InputLists.DealsProlongation
        : [];

    return {
        OutgoingDealProlongRequests,
        IncomingDealProlongRequests,
    };
};

export const getOutgoingDealProlongRequests = () => {
    const total = getUserRequests();
    return total['OutgoingDealProlongRequests'];
};

export const getIncomingDealProlongRequests = () => {
    const total = getUserRequests();
    return total['IncomingDealProlongRequests'];
};

export const getDealDaysCounter = (contractDuration, dealDuration) => {
    return moment(dealDuration)
        .startOf('day')
        .diff(moment(contractDuration).startOf('day'), 'days');
};

export const getContractDaysCounter = (startDate, contractDuration) => {
    return moment(contractDuration).diff(
        moment(startDate).startOf('day'),
        'days'
    );
};

export const getDealNamePlaceholder = (contractType) => {
    switch (contractType) {
        case 0:
            return i18next.t('domain_address');
        case 2:
        case 3:
            return i18next.t('product_name');
        case 4:
            return i18next.t('service_offer_name');
        case 5:
            return i18next.t('project_name');
        default:
            return i18next.t('specify_deal_name');
    }
};

export const getAgreementFieldsTotalData = (structure, data) => {
    const items = structure.map((field, i) => {
        let fieldData = data.filter((el) => el.FieldId === field.Id);

        if (fieldData.length > 0) {
            return {
                [field.Name]: fieldData[0]['Value'],
                ...structure[i],
            };
        }
        return {
            [field.Name]: null,
            ...structure[i],
        };
    });

    return items;
};

export const getAgreementFields = (structure, data) => {
    return data.map((dataField, i) => {
        const dataFieldInfo = structure.filter(
            (el) => el.Id === dataField.FieldId
        )[0];

        return { ...dataField, ...dataFieldInfo };
    });
};

export const getAgreementFieldsFormInitial = (structure, data) => {
    const items = structure.map((field, i) => {
        let fieldData = data.filter((el) => el.FieldId === field.Id);

        if (fieldData.length > 0) {
            return { [field.Name]: fieldData[0]['Value'] };
        }
        return { [field.Name]: null };
    });

    return items;
};

export const getAgreementFieldsData = (structure, data) => {
    const items = structure.map((field) => {
        let fieldData = data.filter((el) => el.FieldId === field.Id);

        if (fieldData.length > 0) {
            return { [field.ResourcesKey]: fieldData[0]['Value'] };
        }
    });

    return (
        <div className="ui list">
            {items.map((item) =>
                Object.keys(item).map((key) =>
                    item[key].length ? (
                        <div className="item" key={v1()}>
                            <label>{i18next.t(key)}:</label>
                            <span>{item[key]}</span>
                        </div>
                    ) : null
                )
            )}
        </div>
    );
};
export const maskCards = (cards) => {
    return cards.map((card) => {
        const number = card.Purse.replace(/\s/g, '');
        const substString = number.slice(4, -4);
        return {
            ...card,
            Purse: number.replace(substString, ' ****  **** **** '),
        };
    });
};

export const maskCard = (card) => {
    if (card.Paymethod !== 'Cards') {
        return;
    }
    //const number = card.Purse.replace(/\s/g, '');
    const substString = card.Purse.slice(7, -5);
    return card.Purse.replace(substString, 'XX XXXX ');
};

export const showNewOfferBlock = (entity) => {
    return (
        <div className="info-message-block">
            {/*<p>*/}
            {/*<b className="bold">{entity.Initiator.UserLogin}</b> {i18next.t('new_offer_text_2')}.*/}
            {/*</p>*/}
            <p>{i18next.t('new_offer_text')}</p>
        </div>
    );
};

const fetchEntityTransactions = (entity) => (dispatch) => {
    const entityType = entity.ContractId ? 'deal' : 'contract';
    if (entityType === 'contract') {
        return dispatch(fetchContractTransactionsList(entity));
    } else {
        return dispatch(fetchDealTransactionsList(entity));
    }
};

export const getSelectPayMethodInLocalStorage = () => {
    const lsParseDataPayMethod = JSON.parse(
        localStorage.getItem('currentPayMethod')
    );
    return (
        !!lsParseDataPayMethod && {
            ...JSON.parse(localStorage.getItem('currentPayMethod')),
        }
    );
};

export const getFullEntityInfo = (entity) => (dispatch) => {
    const entityType = entity.ContractId
        ? 'deal'
        : entity.Id
        ? 'contract'
        : null;

    const {
        Currency,
        InitiatorRole,
        Paymethod,
        PartnerPaymethod,
        CommissionsType,
        Amount,
    } = entity;

    //TODO: отрефакторить эту мазафаку, вынести в универсальный метод для генерации параметров

    const feesParams = {
        SourcePayMethod:
            (InitiatorRole === 'Payer' ? Paymethod : PartnerPaymethod) ||
            Paymethod,
        DestinationPayMethod:
            (InitiatorRole === 'Payee' ? Paymethod : PartnerPaymethod) ||
            Paymethod,
        Currency,
        Amount,
        CommissionType: CommissionsType,
        IsPaymentFromPayerDeposit: true,
    };

    return Promise.all([
        dispatch(fetchTermsTemplate(entity.TermTemplateId, entityType)),
        dispatch(fetchContractVersion(entity)),
        dispatch(fetchFees(feesParams)).then((fees) => {
            if (entityType === 'deal') {
                dispatch({
                    type: C.DEAL_FEES_FETCHED,
                    payload: fees,
                });
            } else {
                dispatch({
                    type: C.CONTRACT_FEES_FETCHED,
                    payload: fees,
                });
            }
        }),
        // dispatch(fetchEntityTransactions(entity))
    ]);
};
export const getLimitedEntityInfo = (currentInfo) => (dispatch) => {
    const entityType = currentInfo.ContractId
        ? 'deal'
        : currentInfo.Id
        ? 'contract'
        : null;

    return Promise.all([
        dispatch(fetchTermsTemplate(currentInfo.TermTemplateId, entityType)),
    ]).then(() => dispatch({ type: C.LIMITED_CONTRACT_DATA_FETCHED }));
};

export const getScoringDetailed = (scoring) => {
    const {
        DisputedDealsCount,
        SuccessDealsCount,
        SuccessDealsOverallAmount,
        DateOfRegistration,
    } = scoring;

    return (
        <ul className="scoring-detailed">
            <li className="successful">
                <span className="number">
                    <MdVerifiedUser />
                    &nbsp;
                    {SuccessDealsCount}
                </span>
                <span className="text">{i18next.t('score_text_5')}</span>
            </li>
            <li className="dispute">
                <span className="number">
                    <MdSecurity />
                    &nbsp;
                    {DisputedDealsCount}
                </span>
                <span className="text">{i18next.t('score_text_4')}</span>
            </li>
            <li className="money">
                <span className="number">
                    <MdMonetizationOn />
                    &nbsp;
                    {SuccessDealsOverallAmount}
                </span>
                <span className="text">{i18next.t('score_text_3')}</span>
            </li>
        </ul>
    );
};

const getScoringShort = (scoring) => {
    const { DisputedDealsCount, SuccessDealsCount, SuccessDealsOverallAmount } =
        scoring;

    return (
        <ul className="scoring-short">
            <ReactTooltip place="top" className="tooltip" />

            <li className="successful" data-tip={i18next.t('score_text_1')}>
                <span>
                    <MdVerifiedUser />
                </span>
                <span>{SuccessDealsCount}</span>
            </li>
            <li className="dispute" data-tip={i18next.t('score_text_2')}>
                <span>
                    <MdSecurity />
                </span>
                <span>{DisputedDealsCount}</span>
            </li>
            <li className="money" data-tip={i18next.t('score_text_3')}>
                <span>
                    <MdMonetizationOn />
                </span>
                <span>{SuccessDealsOverallAmount}</span>
            </li>
        </ul>
    );
};

export const getUserInfoBlock = (
    userData,
    role,
    type,
    isPartnerBlock,
    entity
) => {
    const isInitiator = userData.UserLogin === entity.Initiator.UserLogin;
    const userRating = Math.ceil(userData.Scoring.Score / 2);
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const { user } = lStorage;
    const isUser = user.UserLogin === userData.UserLogin;

    return (
        <div className="deal-info__item user">
            <ReactTooltip place="top" className="tooltip" />

            <div className="image-wrapper">
                {getUserAvatar(
                    userData.AvatarBase64,
                    userData.Profile
                        ? userData.Profile.LoginProvider || null
                        : null
                )}
                {isInitiator && (
                    <span
                        className="initiator-badge"
                        data-tip={i18next.t('initiator')}>
                        {isPartnerBlock ? <MdArrowBack /> : <MdArrowForward />}
                    </span>
                )}
            </div>

            <div className="user__info">
                <a
                    target="_blank"
                    className="user__profile-link"
                    href={isUser ? '/profile' : getUserProfileUrl(userData)}>
                    <span className="name">
                        {getUserName(userData, entity.Partner === null)}
                    </span>
                    {isUser && (
                        <span className="badge badge_green">
                            {i18next.t('you')}
                        </span>
                    )}
                </a>
                <div className="user__score">
                    <StarRatingComponent
                        name="rate1"
                        starCount={5}
                        emptyStarColor="#ccc"
                        value={userRating}
                    />
                </div>
                {/*{entity.Partner !== null && (*/}
                {/*    <p className="user__login">{getUserLogin(userData)}</p>*/}
                {/*)}*/}
            </div>
        </div>
    );

    // return <div className="deal-info__item user">
    //     <ReactTooltip place="top" className="tooltip"/>
    //
    //     <div className="image-wrapper">
    //         {
    //             getUserAvatar(userData)
    //         }
    //         {
    //             isInitiator &&
    //             <span className="initiator-badge" data-tip={i18next.t('initiator')}>
    //                             <MdStars/>
    //                         </span>
    //         }
    //     </div>
    //
    //     <div className="user__info">
    //         <a target='_blank'
    //            className="user__profile-link"
    //            href={isUser ? '/profile' : getUserProfileUrl(userData)}>
    //         <span className="name">
    //
    //                   {
    //                       getUserName(userData)
    //                   }
    //             </span>
    //             {
    //                 isUser &&
    //                 <span className="badge badge_green">{i18next.t('you')}</span>
    //             }
    //         </a>
    //
    //         <div  data-tip={i18next.t('user_rating')}
    //               className="user__score">
    //             <StarRatingComponent name="rate1"
    //                                  starCount={5}
    //                                  emptyStarColor="#ccc"
    //                                  value={userRating}/>
    //         </div>
    //
    //
    //
    //         <p className="user__login">
    //             {
    //                 getUserLogin(userData)
    //             }
    //         </p>
    //
    //
    //
    //         {/*{*/}
    //         {/*Scoring && getScoringShort(Scoring)*/}
    //         {/*}*/}
    //         <div>
    //
    //         </div>
    //     </div>
    // </div>
};

export const getLoginProviderIcon = (loginProvider) => {
    switch (loginProvider) {
        case 'Vkontakte':
            return (
                <span className="login-provider-icon vk">
                    <FaVk />
                </span>
            );

        case 'Gmail':
            return (
                <span className="login-provider-icon gmail">
                    <FaGoogle />
                </span>
            );

        case 'Facebook':
            return (
                <span className="login-provider-icon facebook">
                    <FaFacebookF />
                </span>
            );

        case 'WebMoney':
            return (
                <span className="login-provider-icon webmoney">
                    <img src={webmoney_blue} alt="webmoney_logo" />
                </span>
            );
    }
};

export const getUserAvatar = (avatar, loginProvider) => {
    if (!avatar) {
        switch (loginProvider) {
            case 'Gmail':
                return (
                    <img
                        src={googleAvatar}
                        alt="user avatar"
                        className="user-pic"
                    />
                );
            case 'Facebook':
                return (
                    <img
                        src={fbAvatar}
                        alt="user avatar"
                        className="user-pic"
                    />
                );
            case 'Vkontakte':
                return (
                    <img
                        src={vkAvatar}
                        alt="user avatar"
                        className="user-pic"
                    />
                );
            case 'WebMoney':
                return (
                    <img
                        src={webmoneyAvatar}
                        alt="user avatar"
                        className="user-pic"
                    />
                );
            default:
                return (
                    <img
                        src={fallbackAvatar}
                        alt="user avatar"
                        className="user-pic"
                    />
                );
        }
    } else {
        return (
            <img
                src={`data:image/jpeg;base64,${avatar}`}
                alt="user avatar"
                className="user-pic"
            />
        );
    }
};

export const gatCategoryImage = (type) => {
    switch (type) {
        case 'contractType_1':
            return <MdVpnLock />;
        case 'contractType_2':
            return <MdSave />;
        case 'contractType_3':
            return <FaTruck />;
        case 'contractType_4':
            return <FaChalkboardTeacher />;
        case 'contractType_5':
            return <FaCode />;
        case 'contractType_6':
            return <MdAccountBox />;
        case 'contractType_7':
            return <FaBtc />;
        case 'contractType_8':
            return <MdVpnKey />;
        case 'contractType_11':
            return <MdDomain />;
        case 'contractType_12':
            return <MdWeb />;
    }
};

const getMimeTypeIcon = (type) => {
    if (type && type.includes('pdf')) {
        return <TiDocumentText className="attachment-type" />;
    } else if (type && type.includes('image')) {
        return <TiImage className="attachment-type" />;
    } else {
        return <TiDocument className="attachment-type" />;
    }
};

export const renderAttachedFile = (file, detach, editable = true) => {
    return (
        <p className="attachment" key={file.Guid}>
            {getMimeTypeIcon(file.MimeType)}

            <a
                onClick={(e) => {
                    getBlob(file.Guid).then((blob) =>
                        downloadBlob(blob, file.Name)
                    );
                }}
                className="attachment-name"
                target="_blank">
                {file.Name ? file.Name : i18next.t('document')}
            </a>
            {file.Size && file.Size > 0 && (
                <span className="attachment-size">
                    ({prettysize(file.Size)})
                </span>
            )}

            {editable && <FaTimes className="detach" onClick={detach} />}
        </p>
    );
};

export const processUnauthorizedContractAcceptance =
    (contract) => (dispatch) => {
        dispatch(triggerPublicContractAcceptance(contract));
        dispatch(openModal('login'));
    };

export const getWebMoneyPurseType = (Currency) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const {
        paymentMethods: { defaultList: paymentMethods },
    } = lStorage;

    const pmCurrencies = paymentMethods?.WebMoney?.Currencies || [];
    const currency = pmCurrencies.filter((cur) => cur.Value === Currency)[0];

    return currency?.Name;
};

export const getCurrencyName = (entity) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const {
        paymentMethods: { defaultList: paymentMethods },
    } = lStorage;
    const { Paymethod, Currency } = entity;

    if (!paymentMethods[Paymethod]) {
        return Currency;
    }

    const paymethodCurrencies = paymentMethods[Paymethod]['Currencies'];
    const element = paymethodCurrencies.filter(
        (cur) => cur.Value === Currency
    )[0];
    return element?.Name ?? Currency;
};

export const getCurrencyNameForUserPaymethods = (entity, userPayMethod) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const {
        paymentMethods: { defaultList: paymentMethods },
    } = lStorage;
    const { Currency } = entity;

    if (!paymentMethods[userPayMethod]) {
        return Currency;
    }

    const paymethodCurrencies = paymentMethods[userPayMethod]['Currencies'];
    const element = paymethodCurrencies.filter(
        (cur) => cur.Value === Currency
    )[0];

    return element?.Name ?? Currency;
};

export const getCurrencyValue = (entity) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const {
        paymentMethods: { defaultList: paymentMethods },
    } = lStorage;
    let { Paymethod, Currency } = entity;

    if (Currency === 'WMP') return 'RUB';

    if (!paymentMethods[Paymethod]) {
        return Currency;
    }

    const payMethodCurrencies = paymentMethods[Paymethod]['Currencies'];
    const element = payMethodCurrencies.filter(
        (cur) => cur.Value === Currency
    )[0];

    return element.Value;
};

export const getWebMoneyPurseLetter = (entity, userPayMethod) => {
    const currencyName = getCurrencyNameForUserPaymethods(
        entity,
        userPayMethod
    );
    return currencyName.slice(-1).toUpperCase();
};

export const getContractIcon = (contractType) => {
    const parsed = parseInt(contractType);
    if (typeof parsed !== 'number') return null;

    switch (parsed) {
        case 11:
            return <MdVpnLock color="#D9D9D9" size="24px" />;
        default:
            return null;
    }
};

export const lowerStr = (str = '') => str.toLowerCase();
export const findEmailInStr = (value = '') =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

export const isLoginMe = (arr = [], value = '') => {
    if (!value) {
        return;
    }

    const isEmail = findEmailInStr(value);

    if (isEmail) {
        return arr.includes(value);
    }

    return arr.includes(lowerStr(value));
};

/**
 * Return 1, 2 or 3 depends on current domain
 * 1 if domain is not kkb and domain zone is not ru (or if it local run/can not get domain)
 * 2 if domain is not kkb and domain is in ru zone
 * 3 if domain is kkb or erp
 * @return {number}
 */
export const getDomainType = () => {
    const domain = window.location.hostname;

    // change it if you want to test kkb/.ru site
    if (process.env.NODE_ENV === 'development' || !domain) return 1;

    const parsed = parseDomain(domain);
    if (parsed?.domain === 'kkb' || parsed?.domain === 'nkoerp') {
        return 3;
    } else if (parsed?.tld === 'ru') {
        return 2;
    } else {
        return 1;
    }
};

export const tryGetItemFromLocalStorage = (item) => {
    try {
        console.log('Try to get LocalStorage Item #' + item);
        var result = localStorage.getItem(item);
        return result;
    } catch {
        console.log('Error whole getting LocalStorage Item #' + item);
        return null;
    }
};

export const getInitialCurrency = (paymentMethods, currentMethod) => {
    return paymentMethods[currentMethod]?.Currencies[0]?.Value ?? '';
};

export const getInitialCommissionTypes = (paymentMethods, currentMethod) => {
    return paymentMethods[currentMethod]?.CommissionTypes ?? [];
};

export const partnerValidate = (values) => {
    const { PartnerName } = values;
    if (PartnerName) {
        const isEmail = validator.isEmail(PartnerName);
        if (!isEmail) {
            const isWmid = /[0-9]{12}/gim.test(PartnerName);

            if (!isWmid) {
                return new Promise((resolve, reject) =>
                    reject({ PartnerName: i18next.t('incorrect_partner') })
                );
            } else {
                return checkWMid(PartnerName).then((exists) => {
                    if (!exists) {
                        throw {
                            PartnerName: i18next.t('incorrect_partner'),
                        };
                    }
                });
            }
        }
    }
    return new Promise((resolve) => resolve());
};
