import * as R from 'ramda';
import C from '../../constants/actionTypes';
import * as types from '../../methods/api/profile.types';

const initialState = {
    loggedIn: false,
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case C.UPDATE_MESSAGES_COUNTER:
            return {
                ...state,
                UnreadMessages: action.payload,
            };
        case C.DEALS_COUNTER_UPDATED:
            return {
                ...state,
                DealsTotal: action.payload,
            };

        case C.CONTRACTS_COUNTER_UPDATED:
            return {
                ...state,
                ContractsTotal: action.payload,
            };

        case C.CONTRACT_DECLINED_BY_USER:
            return {
                ...state,
                ContractsTotal: state.ContractsTotal - 1,
            };

        case C.INCOMING_MESSAGES_READ:
            return {
                ...state,
                UnreadMessages:
                    state.UnreadMessages >= action.payload.UnreadMsgsCount
                        ? state.UnreadMessages - action.payload.UnreadMsgsCount
                        : state.UnreadMessages,
            };

        case C.DEAL_PROLONGATION_REQUEST_SENT:
            return {
                ...state,
                Requests: {
                    ...state.Requests,
                    OutputLists: {
                        DealsProlongation: [
                            ...state.Requests.OutputLists.DealsProlongation,
                            action.payload,
                        ],
                    },
                },
            };

        case C.DEAL_PROLONGATION_REQUESTED:
            return {
                ...state,
                Requests: {
                    ...state.Requests,
                    InputLists: {
                        DealsProlongation: [
                            ...state.Requests.InputLists.DealsProlongation,
                            action.payload,
                        ],
                    },
                },
            };

        case C.DEAL_PROLONGED:
            const {
                Requests: { InputLists, OutputLists },
            } = state;

            const filteredInputDealsProlongations =
                InputLists.DealsProlongation.filter(
                    (request) => request.DealId !== action.payload.Id
                );

            const filteredOutputDealsProlongations =
                OutputLists.DealsProlongation.filter(
                    (request) => request.DealId !== action.payload.Id
                );

            return {
                ...state,
                Requests: {
                    ...state.Requests,
                    OutputLists: {
                        DealsProlongation: filteredOutputDealsProlongations,
                    },
                    InputLists: {
                        DealsProlongation: filteredInputDealsProlongations,
                    },
                },
            };

        case C.DEAL_PROLONGATION_REJECTED:
        case C.DEAL_PROLONGATION_CONFIRMED:
        case C.DEAL_PROLONGATION_REQUEST_REJECTED:
            const filteredInputDealsProlongationList =
                state.Requests.InputLists.DealsProlongation.filter(
                    (request) => request.RequestId !== action.payload.RequestId
                );

            const filteredOutputDealsProlongationList =
                state.Requests.OutputLists.DealsProlongation.filter(
                    (request) => request.RequestId !== action.payload.RequestId
                );

            return {
                ...state,
                Requests: {
                    ...state.Requests,
                    OutputLists: {
                        ...state.Requests.OutputLists,
                        DealsProlongation: filteredOutputDealsProlongationList,
                    },
                    InputLists: {
                        ...state.Requests.InputLists,
                        DealsProlongation: filteredInputDealsProlongationList,
                    },
                },
            };

        case 'DEAL_CANCEL_REQUESTED':
            return {
                ...state,
                Requests: {
                    ...state.Requests,
                    OutputLists: {
                        ...state.Requests.OutputLists,
                        DealsCancel: [
                            ...state.Requests.OutputLists.DealsCancel,
                            action.payload,
                        ],
                    },
                },
            };

        case 'DEAL_CANCEL_REJECTED':
        case 'DEAL_CANCEL_CONFIRMED':
            const filteredInputList =
                state.Requests.InputLists.DealsCancel.filter(
                    (request) => request.DealId !== action.payload
                );

            const filteredOutputList =
                state.Requests.OutputLists.DealsCancel.filter(
                    (request) => request.DealId !== action.payload
                );

            return {
                ...state,
                Requests: {
                    ...state.Requests,
                    OutputLists: {
                        ...state.Requests.OutputLists,
                        DealsCancel: filteredOutputList,
                    },
                    InputLists: {
                        ...state.Requests.InputLists,
                        DealsCancel: filteredInputList,
                    },
                },
            };

        case C.AUTHENTICATED:
            return {
                ...action.payload,
            };

        case C.TOKEN_REFRESHED:
            return {
                ...state,
                ...action.payload,
            };
        case C.SESSION_OUTDATED:
            return {
                ...state,
                AccessToken: null,
                RefreshToken: null,
            };
        case C.USER_LOGGED_IN:
            return {
                ...state,
                loggedIn: true,
            };

        case C.USER_TRANSACTIONS_FETCHED: {
            const { entity } = action.payload;
            const transactions = R.pipe(
                R.propOr({}, 'transactions'),
                R.assoc('pagination', entity.Pagination),
                R.assoc('items', entity.Items)
            )(state);

            return R.assoc('transactions', transactions, state);
        }

        case C.USER_PURSE_ADDED:
            return {
                ...state,
                Profile: {
                    ...state.Profile,
                    PaymentAccounts: [
                        ...state.Profile.PaymentAccounts,
                        action.payload,
                    ],
                },
            };

        case C.USER_ACCOUNT_SAVING_WITHDRAW_UPDATE:
            return {
                ...state,
                AccountSavingInfo: {
                    ...state.AccountSavingInfo,
                    ...action.payload,
                },
            };

        case C.USER_PROFILE_UPDATED:
            const Profile = action.payload;
            return { ...state, Profile };

        case C.USER_PASSPORT_ADDED:
        case C.SMEV_STATUS_CHANGED:
            return {
                ...state,
                Profile: {
                    ...state.Profile,
                    ...action.payload,
                },
            };

        case C.USER_PURSE_REMOVED:
            const PaymentAccounts = state.Profile.PaymentAccounts.filter(
                (purse) => purse.Id !== action.payload
            );
            return {
                ...state,
                Profile: {
                    ...state.Profile,
                    PaymentAccounts,
                },
            };
        case C.USER_PURSES_LOADED:
            return {
                ...state,
                Profile: {
                    PaymentAccounts: action.payload,
                },
            };
        case C.USER_LOGGED_OUT:
            return initialState;

        case C.USER_PROFILE_FETCHED:
            return {
                ...state,
                ...action.payload,
            };

        case types.TOGGLE_ACCUMULATE_ASSETS: {
            const { entity } = action.payload;

            return {
                ...state,
                Profile: {
                    ...state.Profile,
                    AccumulateAssets: entity,
                },
            };
        }

        default:
            return state;
    }
};
export default user;
