import { combineReducers } from 'redux';
import C from '../../constants/actionTypes';

const items = (state = [], action) => {
    switch (action.type) {
        case C.DEALS_HISTORY_FETCHED:
            return action.payload.Items;
        default:
            return state;
    }
};

const filtersInitial = {
    statuses: [],
    paymethods: [],
    search: null,
    startDate: null,
    endDate: null,
    categories: [],
};

const filters = (state = filtersInitial, action) => {
    switch (action.type) {
        case C.SET_DEALS_HISTORY_FILTERS:
            return action.payload;
        case C.UNSET_DEALS_HISTORY_FILTERS:
            return filtersInitial;
        default:
            return state;
    }
};

const pagination = (state = { Limit: 10, Offset: 0 }, action) => {
    switch (action.type) {
        case C.DEALS_HISTORY_FETCHED:
            return action.payload.Pagination;
        default:
            return state;
    }
};

export default combineReducers({
    items,
    filters,
    pagination,
});
