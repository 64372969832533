import english from '../assets/images/english.png';
import russian from '../assets/images/russian.png';
import vietnamese from '../assets/images/vietnam.svg';

const locales = [
    // {value: 'en-EN', label: 'English', img: english},
    // {value: 'vi-VN', label: 'Vietnamese', img: vietnamese},
    { value: 'ru-RU', label: 'Русский', img: russian },
];

export default locales;
