export default {
    REFRESH_TOKEN_URL: '/api/authentication/refresh',
    USER_EXTERNAL_AUTH_URL: 'api/authentication/external/v1',
    USER_EXTERNAL_AUTH_URL_2: 'api/authentication/external/v2',
    USER_AUTH_URL: '/api/authentication',
    USER_REGISTER_URL: '/api/registration',
    USER_INVOICE_REGISTRATION_URL: '/api/registration/invoice-user',

    USER_SHORT_INFO_URL: '/api/users/search-short-info?name=',
    USER_SHORT_PROFILE_URL: '/api/users/get-short-info?name=',
    USER_SHORT_PROFILE_BY_ID_URL: '/api/users/get-short-info-for-view?id=',

    WMID_CHECK_URL: '/api/users/check-unregistered-user?userWMID=',

    USER_PROFILE_URL: '/api/profile',
    X11_INFO_URL: '/api/profile/x11-info',
    USER_TRANSACTIONS_URL: '/api/profile/transactions/',
    USER_SAVING_ACCOUNT_WITHDRAW_URL: '/api/profile/saving-account-withdraw',
    USER_EMAIL_URL: 'api/profile/emails/',
    USER_PAYMENT_ACCOUNTS_URL: '/api/profile/payment-accounts',
    USER_PASSWORD_URL: '/api/profile/password',
    USER_PASSPORT_URL: '/api/profile/passport',
    PASSWORD_RECOVERY_SEND_EMAIL_URL:
        'api/registration/password-recovery/send-mail',
    SUBMIT_RESTORED_PASSWORD_URL: 'api/registration/password-recovery',

    DEALS_URL: '/api/deals',
    DEALS_FOR_SITE_URL: '/api/deals/list',
    DEAL_PAY_SUFFIX_URL: '/pay-data',
    DEALS_HISTORY_URL: '/api/deals/history',
    PUBLIC_CONTRACTS_URL: '/api/contracts/public-site/',
    PUBLIC_CONTRACTS_WITH_CONFIRM_URL: '/api/contracts/public-site/',
    CONTRACTS_URL: '/api/contracts',
    CONTRACTS_DIFF_PAYMETHODS_URL: '/api/contracts/diff-paymethods',
    CONTRACTS_WITH_CONFIRM_URL: '/api/contracts/list/',
    CONTRACTS_GET_VERSION_SUFFIX_URL: '/versions/',
    CONTRACTS_HISTORY_URL: '/api/contracts/history',
    WMID_PASSPORT_URL: 'https://passport.webmoney.ru/asp/certView.asp?wmid=',
    VK_USER_PROFILE_URL: 'https://vk.com/id',
    GOOGLE_USER_PROFILE_URL: 'https://plus.google.com/u/0/',
    FACEBOOK_USER_PROFILE_URL: 'https://www.facebook.com/profile.php?id=',

    LOCALIZATION_URL: '/api/settings/localization',
    PAYMENT_METHODS_URL: '/api/settings/paymethods',
    PAYMENT_DIFF_METHODS_URL: '/api/settings/diff-paymethods',
    FEES_URL: '/api/commissions/calculate',
    FEES_DETAILING_URL: '/api/commissions/detailing',
    FEES_URL_DIFF: '/api/commissions/diff-calculate',
    CONTRACT_TYPES_URL: '/api/contracts/templates/contract-types',
    CONTRACTOR_TYPES_URL: '/api/contracts/templates/contragent-types',

    CONTRACT_TEMPLATES_URL: '/api/contracts/templates/contract-agreements',
    CONTRACT_TEMPLATE_URL: '/api/contracts/templates/contract-agreement/',
    CONTRACT_PREFILLED_TEMPLATES: '/api/contracts/templates/term-process',

    FETCH_CHAT_MESSAGES_URL: '/api/chatmessage/ChatMessagesPagedGet',
    FETCH_CHATS_URL: '/api/chatmessage/chats',
    ADD_CHAT_MESSAGE: '/api/chatmessage/add',
    DELETE_CHAT_URL: '/api/chatmessage/delete-chat?contractId=',
    FORM_VALIDATORS_URL: '/api/settings/creating-validations',
    LOGIN_WITH_ANOTHER_ACCOUNT: '/api/authentication/admin',
    READ_CHAT_URL: '/api/chatmessage/read-chat/',

    WM_LOGIN_PRODUCTION:
        'https://login.wmtransfer.com/GateKeeper.aspx?RID=9C452019-C803-4FFB-B78A-A9D0009E919B',
    WM_LOGIN_PRODUCTION_RU:
        'https://login.wmtransfer.com/GateKeeper.aspx?RID=3398AF4D-725F-4C0F-8DD5-AA0000ED670B',
    WM_LOGIN_LOCAL:
        'https://login.wmtransfer.com/GateKeeper.aspx?RID=264AF817-5C77-427A-B877-A9D0009F292B',

    GMAIL_REDIRECT_URL_PRODUCTION: 'https://guarantee.money',
    GMAIL_REDIRECT_URL_PRODUCTION_RU: 'https://guarantee.money',
    GMAIL_REDIRECT_URL_LOCAL: 'http://localhost:3000',

    VK_REDIRECT_URL_PRODUCTION: 'https://guarantee.money/callback',
    VK_REDIRECT_URL_PRODUCTION_RU: 'https://guarantee.money/callback',
    VK_REDIRECT_URL_LOCAL: 'http://localhost:3000/callback',
    VK_GROUP_REDIRECT_URL_PRODUCTION:
        'https://guarantee.money/vkcodegroup/callback',
    VK_GROUP_REDIRECT_URL_PRODUCTION_RU:
        'https://guarantee.money/vkcodegroup/callback',
    VK_GROUP_REDIRECT_URL_LOCAL: 'http://localhost:3000/vkcodegroup/callback',

    FILE_UPLOAD_URL: '/api/files/upload',
    FILE_GET_URL: '/api/files/get/',
    FILE_DETACH_URL: '/api/files/delete?guid=',

    STATISTICS_URL: '/api/settings/statistics',
    SITEFILES_URL: '/api/settings/files',
    SUPPORT_MESSAGE_ADD_URL: '/api/support/add',
    SUPPORT_MESSAGE_GET_URL: '/api/support/get?guid=',

    IFRAME_GET_ACCESS_TOKEN_URL: '/api/authentication/iframe',

    VK_CHECK_OWNERSHIP_URL_PRODUCTION:
        'https://guarantee.money/api/social/vk-check-ownership?',
    VK_CHECK_OWNERSHIP_URL_PRODUCTION_RU:
        'https://guarantee.money/api/social/vk-check-ownership?',
    VK_CHECK_OWNERSHIP_URL_LOCAL:
        'http://localhost:3000/api/social/vk-check-ownership?',
};
