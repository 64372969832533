import C from '../../constants/actionTypes';

const supportInitial = {
    opened: false,
    Guid: null,
    Email: null,
    Messages: [],
};

export const support = (state = supportInitial, action) => {
    switch (action.type) {
        case C.SUPPORT_WINDOW_OPENED:
            return {
                ...state,
                opened: true,
            };

        case C.SUPPORT_WINDOW_CLOSED:
            return {
                ...state,
                opened: false,
            };

        case C.NEW_SUPPORT_MESSAGE:
            return {
                ...state,
                Guid: action.payload.Guid,
                Email: action.payload.Email,
                Messages: [...state.Messages, action.payload],
            };

        case C.AUTHENTICATED:
            return {
                ...supportInitial,
                Messages: [],
            };

        case C.USER_LOGGED_OUT:
            return {
                ...supportInitial,
                opened: false,
            };
        case C.SUPPORT_MESSAGES_FETCHED:
            return {
                ...state,
                Messages: action.payload.Items,
            };

        default:
            return state;
    }
};
