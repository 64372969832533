import URLS from '../../constants/urls';
import { supportMessagesFetched } from '../actions/actionCreators';
import {
    getData,
    sendData,
    handleServerResponse,
    handleDataReceived,
} from '../helpers/common';
import { handleError } from '../helpers/notifications';
import { getContractStatus } from '../helpers/statuses';

export const fetchSupportMessagesByGuid = (guid) => (dispatch) => {
    const url = URLS.SUPPORT_MESSAGE_GET_URL + guid;
    return getData(url)
        .then((data) => {
            dispatch(supportMessagesFetched(data));
        })
        .catch((error) => dispatch(handleError(error)));
};
//
// export const fetchSupportMessagesByUserLogin = userLogin => {
//     const url = link + userLogin;
//     getData(url);
// };

export const sendSupportMessage = (data) =>
    sendData('POST', URLS.SUPPORT_MESSAGE_ADD_URL, data);
