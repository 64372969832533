import { combineReducers } from 'redux';
import C from '../../constants/actionTypes';
// import {sortData} from '../actions/helpers';

const items = (state = [], action) => {
    switch (action.type) {
        case C.DEALS_FETCHED:
            return action.payload.Items;

        case C.DEAL_CLOSED:
            return state.filter((deal) => deal.Id !== action.payload.Id);

        // case C.NEW_DEAL_CREATED:
        //     const alreadyAdded = state.filter(item => item.Id === action.payload.Id).length > 0;
        //
        //     if(alreadyAdded){
        //         return state;
        //     }else{
        //         return [action.payload, ...state];
        //     }

        case C.INCOMING_MESSAGES_READ:
            return state.map((deal) => {
                if (deal.ContractId === action.payload.EntityId) {
                    return { ...deal, UnreadMessages: 0 };
                }
                return deal;
            });
        case C.NEW_MESSAGE:
            return state.map((deal) => {
                if (
                    deal.ContractId === action.payload.BindedObjectId &&
                    action.payload.From !== action.payload.CurrentUserId
                ) {
                    return {
                        ...deal,
                        UnreadMessages: deal.UnreadMessages + 1,
                    };
                }
                return deal;
            });

        case C.DISPUTE_OPENED:
            const updatedList = state.map((deal) => {
                if (deal.Id === action.payload) {
                    return { ...deal, Status: 2 };
                }
                return deal;
            });
            return updatedList;

        case 'DEAL_CANCEL_CONFIRMED':
            return state.map((deal) => {
                if (deal.Id === action.payload) {
                    return { ...deal, Status: 9 };
                }
                return deal;
            });

        case C.DEAL_PROLONGATION_CONFIRMED:
            return state.map((deal) => {
                if (deal.Id === action.payload.Id) {
                    return { ...deal, ...action.payload };
                }
                return deal;
            });
        case C.DEAL_UPDATED:
        case C.DEAL_PROLONGATION_REJECTED:
            if (action.payload.Status !== 4) {
                return state.map((deal) => {
                    if (deal.Id === action.payload.Id)
                        return { ...deal, ...action.payload };

                    return deal;
                });
            }
            return state.filter((deal) => deal.Id !== action.payload.Id);

        // case C.DEAL_PAYMENT_CONFIRMED:
        //     const deal = state.filter(deal => deal.Id === action.payload);
        //     const clearedList = state.filter(deal => deal.Id !== action.payload);
        //     return [...clearedList, {...deal, InProgressState: 1}];

        case C.DEALS_FETCH_FAILED:
            return [];

        default:
            return state;
    }
};

const filtersInitial = {
    statuses: [],
    paymethods: [],
    search: null,
    startDate: null,
    endDate: null,
    categories: [],
};

const filters = (state = filtersInitial, action) => {
    switch (action.type) {
        case C.SET_DEALS_FILTERS:
            return action.payload;
        case C.UNSET_DEALS_FILTERS:
            return {};
        default:
            return state;
    }
};

const pagination = (state = { Limit: 10, Offset: 0 }, action) => {
    switch (action.type) {
        case C.DEALS_FETCHED:
            return action.payload.Pagination;
        default:
            return state;
    }
};

export default combineReducers({
    items,
    filters,
    pagination,
});
