import { createSelector } from 'reselect';
import * as R from 'ramda';

export const selectUserState = (state) => state.user;

export const selectIsLoggedIn = createSelector(
    selectUserState,
    (user) => user.loggedIn || false
);

export const selectEmail = createSelector(
    selectUserState,
    (user) => user.Email || ''
);

export const selectProfile = createSelector(
    selectUserState,
    (user) => user.Profile || {}
);

export const selectUserPaymentAccounts = createSelector(
    selectProfile,
    R.propOr([], 'PaymentAccounts')
);

export const selectTransactions = createSelector(
    selectUserState,
    (user) => user.transactions || {}
);

export const selectTransactionsItems = createSelector(
    selectTransactions,
    R.propOr([], 'items')
);

export const selectTotalTransactions = createSelector(
    selectTransactions,
    R.pathOr(0, ['pagination', 'Total'])
);

export const selectScoring = createSelector(
    selectUserState,
    (user) => user.Scoring || {}
);

export const selectUserLogin = createSelector(
    selectUserState,
    (user) => user.UserLogin || ''
);

export const selectEspBalance = createSelector(
    selectUserState,
    (user) => user.EspBalance || 0
);

export const selectAvatar = createSelector(
    selectUserState,
    (user) => user.AvatarBase64 || ''
);

export const selectLoginProvider = createSelector(
    selectProfile,
    (profile) => profile.LoginProvider || ''
);

export const selectAccumulateAssets = createSelector(
    selectProfile,
    (profile) => profile.AccumulateAssets || false
);
