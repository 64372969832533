import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import logo from '../../../assets/images/logo_escrow.svg';
import UserBlock from './UserBlock';
import MenuBlock from './MenuBlock';

const { $ } = window;

class HeaderMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 0,
        };
        this.setDropdownActive = this.setDropdownActive.bind(this);
        this.setDropdownInactive = this.setDropdownInactive.bind(this);
        this.scrollToSection = this.scrollToSection.bind(this);
    }

    setDropdownActive(num) {
        this.setState({
            active: num,
        });
    }

    setDropdownInactive() {
        if (this.state.active !== 0) {
            this.setState({
                active: 0,
            });
        }
    }

    scrollToSection(type) {
        const {
            location: { pathname },
            history,
        } = this.props;
        const path = pathname.slice(1);
        const $html = $('html');

        if (path.length === 0) {
            const $targetBlock = $(`#${type}`);
            const offset = $targetBlock.offset().top;
            $html.animate({ scrollTop: `${offset}px` }, 500);
        }
        {
            history.push('/');
            setTimeout(() => {
                const $targetBlock = $(`#${type}`);
                const offset = $targetBlock.offset().top;
                $html.animate({ scrollTop: `${offset}px` }, 700);
            }, 150);
        }
    }

    render() {
        return (
            <nav className="header-menu">
                <div className="logo-wrapper">
                    <Link to="/" className="logo">
                        <img src={logo} alt="logo" />
                    </Link>
                </div>
                <MenuBlock {...this.props} />
                <MediaQuery minWidth={992}>
                    <UserBlock {...this.props} />
                </MediaQuery>
            </nav>
        );
    }
}

const mapStateToProps = ({ user, locale }) => ({
    user,
    locale,
});
export default withRouter(connect(mapStateToProps)(HeaderMenu));
