import C from '../../constants/actionTypes';

export const changeLocale = (locale) => ({
    type: C.LANGUAGE_CHANGED,
    payload: locale,
});

export const disputeOpened = (id) => ({
    type: C.DISPUTE_OPENED,
    payload: id,
});

export const userLoggedOut = () => ({
    type: C.USER_LOGGED_OUT,
});

export const clearContractDetailedData = () => ({
    type: C.CLEAR_CONTRACT_DETAILED,
});
export const clearDealDetailedData = () => ({
    type: C.CLEAR_DEAL_DETAILED,
});

export const clearNewContract = () => ({
    type: C.CLEAR_NEW_CONTRACT,
});

export const validationsFetched = (data) => ({
    type: C.VALIDATIONS_FETCHED,
    payload: data,
});

export const dealProlongationRequestSent = (data) => {
    const { DealId, RequestId, NewDuration } = data;
    return {
        type: C.DEAL_PROLONGATION_REQUEST_SENT,
        payload: {
            DealId,
            RequestId,
            NewDuration,
        },
    };
};

export const dealProlongationRequested = (data) => {
    const { DealId, RequestId, NewDuration } = data;
    return {
        type: C.DEAL_PROLONGATION_REQUESTED,
        payload: {
            DealId,
            RequestId,
            NewDuration,
        },
    };
};

export const dealProlongationConfirmed = (data) => ({
    type: C.DEAL_PROLONGATION_CONFIRMED,
    payload: data,
});

export const dealProlongationRejected = (data) => {
    const { Id, RequestId } = data;
    return {
        type: C.DEAL_PROLONGATION_REJECTED,
        payload: {
            Id,
            RequestId,
        },
    };
};

export const setRedirectUrl = () => {
    const redirectUrl = window.location.pathname;
    return {
        type: C.SET_REDIRECT_URL,
        payload: redirectUrl,
    };
};

export const sessionHasOutdated = () => ({
    type: C.SESSION_OUTDATED,
});

// CONTRACT DECLINED
export const contractDeclined = (contract) => ({
    type: C.CONTRACT_DECLINED_BY_USER,
    payload: contract,
});

// UPDATE CONTRACT
export const contractUpdated = (data) => ({
    type: C.CONTRACT_UPDATED,
    payload: data,
});

// PUBLIC CONTRACT ACCEPTED
export const publicContractAccepted = (id) => ({
    type: C.PUBLIC_CONTRACT_ACCEPTED,
    payload: id,
});

// NEW CONTRACT
export const contractCreated = (data) => ({
    type: C.CONTRACT_CREATED,
    payload: data,
});

export const contractFetched = (contract) => ({
    type: C.CONTRACT_FETCHED,
    payload: contract,
});

export const contractFetchFailed = () => ({
    type: C.CONTRACT_FETCH_FAILED,
});

export const contractsFetchFailed = () => ({
    type: C.CONTRACTS_FETCH_FAILED,
});

export const setContractsFilters = (filters) => ({
    type: C.SET_CONTRACTS_FILTERS,
    payload: filters,
});
export const unsetContractsFilters = () => ({
    type: C.UNSET_CONTRACTS_FILTERS,
});

// DEAL ACTIONS

export const dealsFetched = (deals) => ({
    type: C.DEALS_FETCHED,
    payload: deals,
});

export const dealsFetchFailed = () => ({
    type: C.DEALS_FETCH_FAILED,
});

export const dealFetched = (deal) => ({
    type: C.DEAL_FETCHED,
    payload: deal,
});

export const dealsHistoryFetched = (data) => ({
    type: C.DEALS_HISTORY_FETCHED,
    payload: data,
});
export const dealUpdated = (deal) => ({
    type: C.DEAL_UPDATED,
    payload: deal,
});

export const dealPaymentConfirmed = (deal) => ({
    type: C.DEAL_PAYMENT_CONFIRMED,
    payload: deal,
});

export const dealFetchFailed = () => ({
    type: C.DEAL_FETCH_FAILED,
});

export const setDealsFilters = (filters) => ({
    type: C.SET_DEALS_FILTERS,
    payload: filters,
});
export const unsetDealsFilters = () => ({
    type: C.UNSET_DEALS_FILTERS,
});

// Modal
export const openModal = (type, data, callback, dialogText) => ({
    type: C.OPEN_MODAL,
    payload: {
        type,
        data,
        callback,
        dialogText,
    },
});

export const closeModal = (data) => ({
    type: C.CLOSE_MODAL,
});

export const modalLoading = () => ({ type: C.MODAL_LOADING });

export const modalLoaded = () => ({ type: C.MODAL_LOADED });

export const restrictBtc = (value) => ({
    type: C.RESTRICT_BTC,
    payload: value,
});

export const globalContractType = (value) => ({
    type: C.GLOBAL_CONTRACT_TYPE,
    payload: value,
});

export const detachAccount = (data) => ({
    type: C.DETACH_ACCOUNT,
    payload: data,
});

export const clearPublicContractAcceptanceData = (data) => ({
    type: C.CLEAR_PUBLIC_CONTRACT_ACCEPTANCE_DATA,
});

export const triggerPublicContractAcceptance = (contract) => ({
    type: C.UNAUTHORIZED_ACCEPTANCE_TRIGGERED,
    payload: contract,
});
export const publicContractFetchFailed = () => ({
    type: C.PUBLIC_CONTRACT_FETCH_FAILED,
});

export const openSupportWindow = () => ({
    type: C.SUPPORT_WINDOW_OPENED,
});

export const closeSupportWindow = () => ({
    type: C.SUPPORT_WINDOW_CLOSED,
});

export const newSupportMessage = (data) => ({
    type: C.NEW_SUPPORT_MESSAGE,
    payload: data,
});

export const supportMessagesFetched = (data) => ({
    type: C.SUPPORT_MESSAGES_FETCHED,
    payload: data,
});

export const fastLoginFailed = (data) => ({
    type: C.FAST_LOGIN_FAILED,
    payload: data,
});
export const fastLoginSucceded = (data) => ({
    type: C.FAST_LOGIN_SUCCEEDED,
});

export const onFetchingWithDrawMoney = (value) => ({
    type: C.USER_FETCHING_WITHDRAW_MONEY,
    payload: value,
});

export const onFetchedWithDrawMoney = (value) => ({
    type: C.USER_FETCHED_WITHDRAW_MONEY,
    payload: value,
});
