import { combineReducers } from 'redux';
import C from '../../constants/actionTypes';
// import {sortData} from '../actions/helpers';

const items = (state = [], action) => {
    switch (action.type) {
        case C.CONTRACT_DECLINED_BY_USER:
        case C.CONTRACT_ACCEPTED_BY_USER:
            return state.filter(
                (contract) => contract.Id !== action.payload.Id
            );

        case C.CONTRACT_UPDATED:
        case C.COUNTER_OFFER:
            return state.map((contract) => {
                if (contract.Id === action.payload.Id) {
                    return { ...contract, ...action.payload };
                }
                return contract;
            });

        case C.CONTRACTS_FETCHED:
            return action.payload.Items;

        case C.INCOMING_MESSAGES_READ:
            return state.map((contract) => {
                if (contract.Id === action.payload.EntityId) {
                    return { ...contract, UnreadMessages: 0 };
                }
                return contract;
            });
        case C.NEW_MESSAGE:
            return state.map((contract) => {
                if (
                    contract.Id === action.payload.BindedObjectId &&
                    action.payload.From !== action.payload.CurrentUserId
                ) {
                    return {
                        ...contract,
                        UnreadMessages: contract.UnreadMessages + 1,
                    };
                }
                return contract;
            });

        case C.NEW_CONTRACT_OFFER:
            // case C.CONTRACT_CREATED:
            const alreadyAdded =
                state.filter((item) => item.Id === action.payload.Id).length >
                0;

            if (alreadyAdded) {
                return state;
            }
            return [action.payload, ...state];

        case C.CONTRACTS_LOADED_MORE:
            return [...state, ...action.payload.Items];

        case C.CONTRACTS_FETCH_FAILED:
            return [];

        case C.DEAL_CREATED:
            console.log('deal created, reducer message', action.payload);
            return state.filter(
                (contract) => contract.Id !== action.payload.Id
            );
        default:
            return state;
    }
};

const filtersInitial = {
    statuses: [],
    paymethods: [],
    search: null,
    tags: [],
    startDate: null,
    endDate: null,
    categories: [],
};

const filters = (state = filtersInitial, action) => {
    switch (action.type) {
        case C.SET_CONTRACTS_FILTERS:
            return action.payload;
        case C.UNSET_CONTRACTS_FILTERS:
            return filtersInitial;
        default:
            return state;
    }
};

const newContract = (state = {}, action) => {
    switch (action.type) {
        case C.CONTRACT_CREATED:
            return action.payload;

        case C.CLEAR_NEW_CONTRACT:
            return {};
        case C.FEES_FETCHED:
            return {
                ...state,
                fees: action.payload,
            };
        default:
            return state;
    }
};

const pagination = (state = { Limit: 10, Offset: 0 }, action) => {
    switch (action.type) {
        case C.CONTRACTS_FETCHED:
        case C.CONTRACTS_LOADED_MORE:
            return action.payload.Pagination;

        case C.CONTRACT_CREATED:
            return {
                ...state,
                Total: state.Total + 1,
            };

        case C.CONTRACT_DECLINED_BY_USER:
        case C.CONTRACT_ACCEPTED_BY_USER:
            return {
                ...state,
                Total: state.Total - 1,
            };

        default:
            return state;
    }
};

const history = (state = { items: [], pagination: {} }, action) => {
    switch (action.type) {
        case C.CONTRACTS_HISTORY_FETCHED:
            return {
                items: action.payload.Items,
                pagination: action.payload.Pagination,
            };
        default:
            return state;
    }
};

const contractTemplates = (state = [], action) => {
    switch (action.type) {
        case C.CONTRACT_TEMPLATES_FETCHED:
            return action.payload.Terms;
        default:
            return state;
    }
};

const defaultSortingOptions = {
    type: 'date',
    order: 'asc',
};

const sorting = (state = defaultSortingOptions, action) => {
    switch (action.type) {
        case C.CONTRACTS_SORT:
            return action.payload;
        default:
            return state;
    }
};

export default combineReducers({
    items,
    filters,
    newContract,
    contractTemplates,
    pagination,
    history,
    sorting,
});
