import i18next from 'i18next';
import sanitizeHtml from 'sanitize-html';
import { messageNotifier, errorNotifier } from './notifications';
import { dealProlongationRequested } from '../actions/actionCreators';
import { fetchContracts } from '../api/contracts';
import { fetchDeals } from '../api/deals';
import { fetchUserProfile } from '../api/profile';
import C from '../../constants/actionTypes';

export const messageReceived = (data) =>
    function (dispatch) {
        const lStorage = JSON.parse(localStorage.getItem('state'));
        const {
            user,
            chat: { opened, ContractId },
        } = lStorage;
        const { BindedObjectId } = data;

        dispatch(fetchUserProfile(user)).then(() => {
            dispatch({
                type: C.NEW_MESSAGE,
                payload: {
                    ...data,
                    CurrentUserId: user.Id,
                },
            });
        });
        if (opened && ContractId === BindedObjectId) {
            return null;
        }
        const message = `<div>
                    <b class="senderName">${
                        data.FromUserInfo.UserLogin
                    }</b> ${i18next.t('sent_message')}.
                    <br>
                    <a href="/user/messages/">${i18next.t('go_to_messages')}</a>
                    </div>`;

        dispatch(messageNotifier(message, i18next.t('new_event')));
    };

const getDealEventMessage = (StatusChangeInfo, Entity) => (dispatch) => {
    const button = {
        name: i18next.t('go_to'),
        primary: true,
        onClick: () => {
            window.location = `/user/deals/${Entity.Id}`;
        },
    };

    const cleanName = sanitizeHtml(Entity.Name);

    switch (StatusChangeInfo.NewStatus) {
        case 0:
            if (StatusChangeInfo.NewStatus === StatusChangeInfo.OldStatus) {
                dispatch(
                    messageNotifier(
                        i18next.t('deal_created_text', {
                            name: cleanName,
                            id: Entity.Id,
                        }),
                        i18next.t('new_event'),
                        [button]
                    )
                );

                dispatch({
                    type: C.NEW_DEAL_CREATED,
                    payload: Entity,
                });
            } else {
                dispatch(
                    messageNotifier(
                        i18next.t('deal_status_change', {
                            name: cleanName,
                            id: Entity.Id,
                        }),
                        i18next.t('new_event'),
                        [button]
                    )
                );

                dispatch({
                    type: C.DEAL_UPDATED,
                    payload: Entity,
                });
            }
            break;

        case 1:
            dispatch(
                messageNotifier(
                    i18next.t('deal_status_change', {
                        name: cleanName,
                        id: Entity.Id,
                    }),
                    i18next.t('new_event'),
                    [button]
                )
            );

            dispatch({
                type: C.DEAL_UPDATED,
                payload: Entity,
            });
            break;

        case 2:
            dispatch(
                messageNotifier(
                    `${i18next.t(
                        'event_text_1'
                    )} <b class="bold">${`${Entity.Id}. ${cleanName}`}</b>`,
                    i18next.t('new_event'),
                    [button]
                )
            );
            dispatch({
                type: C.DISPUTE_OPENED,
                payload: Entity.Id,
            });

            break;

        case 3:
            dispatch(
                messageNotifier(
                    i18next.t('deal_closed_arbitrary', {
                        name: cleanName,
                        id: Entity.Id,
                    }),
                    i18next.t('new_event'),
                    [button]
                )
            );

            dispatch(fetchDeals()).then(() => {
                dispatch({
                    type: C.DEAL_CLOSED,
                    payload: Entity,
                });
            });

            break;

        case 4:
            dispatch(
                messageNotifier(
                    i18next.t('deal_closed_text', {
                        name: cleanName,
                        id: Entity.Id,
                    }),
                    i18next.t('new_event'),
                    [button]
                )
            );

            dispatch(fetchDeals()).then(() => {
                dispatch({
                    type: C.DEAL_CLOSED,
                    payload: Entity,
                });
            });
            break;

        case 5:
            dispatch(
                messageNotifier(
                    i18next.t('event_text_4', {
                        dealName: `${Entity.Id}. ${cleanName}`,
                    }),
                    i18next.t('new_event'),
                    [button]
                )
            );
            dispatch({
                type: C.DEAL_UPDATED,
                payload: Entity,
            });
            break;

        case 9:
            dispatch(
                messageNotifier(
                    `${i18next.t(
                        'event_text_5'
                    )} <b class="bold">${`${Entity.Id}. ${cleanName}`}</b>`,
                    i18next.t('new_event'),
                    [button]
                )
            );
            dispatch({
                type: C.DEAL_UPDATED,
                payload: Entity,
            });
            break;
    }
};

const getContractEventMessage = (StatusChangeInfo, Entity) => (dispatch) => {
    const button = {
        name: i18next.t('go_to'),
        primary: true,
        onClick: () => {
            window.location = `/user/contracts/${Entity.Id}`;
        },
    };
    const cleanName = sanitizeHtml(Entity.Name);

    if (
        StatusChangeInfo.NewStatus === 1 &&
        StatusChangeInfo.NewStatus === StatusChangeInfo.OldStatus
    ) {
        dispatch(
            messageNotifier(
                i18next.t('new_contract_event', {
                    contractName: cleanName,
                    id: Entity.Id,
                }),
                i18next.t('new_event'),
                [button]
            )
        );
        dispatch(fetchContracts());
    }

    if (
        StatusChangeInfo.NewStatus !== StatusChangeInfo.OldStatus &&
        StatusChangeInfo.NewStatus < 3
    ) {
        dispatch(
            messageNotifier(
                i18next.t('contract_updated_text', {
                    contractName: cleanName,
                    id: Entity.Id,
                }),
                i18next.t('new_event'),
                [button]
            )
        );
        dispatch({
            type: C.COUNTER_OFFER,
            payload: Entity,
        });
    }

    if (StatusChangeInfo.NewStatus === 5) {
        dispatch(fetchContracts()).then(() => {
            dispatch(
                messageNotifier(
                    i18next.t('contract_declined_event', {
                        contractName: cleanName,
                    }),
                    i18next.t('new_event'),
                    [button]
                )
            );

            dispatch({
                type: C.CONTRACT_DECLINED_BY_PARTNER,
                payload: Entity,
            });
        });
    }

    if (StatusChangeInfo.NewStatus === 4) {
        dispatch(
            messageNotifier(
                i18next.t('partner_accepted_contract_offer', {
                    name: `${Entity.Id}. ${Entity.Name}`,
                })
            )
        );
        dispatch(fetchContracts());
    }

    if (StatusChangeInfo.NewStatus === 6) {
        dispatch(
            messageNotifier(
                i18next.t('contract_expired_text', {
                    name: cleanName,
                    id: Entity.Id,
                }),
                i18next.t('new_event'),
                [button]
            )
        );
    }

    if (StatusChangeInfo.NewStatus === 3 && StatusChangeInfo.OldStatus < 3) {
        dispatch(
            messageNotifier(
                i18next.t('contract_accepted', {
                    name: cleanName,
                    id: Entity.Id,
                }),
                i18next.t('new_event'),
                [button]
            )
        );
        dispatch({
            type: C.CONTRACT_UPDATED,
            payload: Entity,
        });
    }
};

const getSmevStatusChangeMessage = (StatusChangeInfo, Entity) => (dispatch) => {
    dispatch({
        type: C.SMEV_STATUS_CHANGED,
        payload: Entity,
    });

    switch (StatusChangeInfo.NewStatus) {
        case 100:
            return dispatch(
                messageNotifier(
                    i18next.t('event_text_12'),
                    i18next.t('new_event')
                )
            );
        case 1:
            return dispatch(
                messageNotifier(
                    i18next.t('event_text_13'),
                    i18next.t('new_event')
                )
            );
        case 500:
        case 501:
            return dispatch(
                errorNotifier(
                    i18next.t('event_text_14'),
                    i18next.t('event_text_15')
                )
            );
    }
};

const incomingRequest = (Entity) => (dispatch) => {
    switch (Entity.EntityType) {
        case 2:
            // Запрос по сделке
            const button = {
                name: i18next.t('go_to_deal'),
                primary: true,
                onClick: () => {
                    window.location = `/user/deals/${Entity.EntityId}`;
                },
            };

            dispatch(
                messageNotifier(
                    i18next.t('event_text_16'),
                    i18next.t('new_request'),
                    [button]
                )
            );

            dispatch(
                dealProlongationRequested({
                    DealId: Entity.EntityId,
                    RequestId: Entity.Id,
                    NewDuration: Entity.Data.NewDuration,
                })
            );
    }
};

export const dealProlonged = (deal, requestId) => (dispatch) => {
    const cleanName = sanitizeHtml(deal.Name);

    dispatch({
        type: C.DEAL_PROLONGED,
        payload: {
            ...deal,
            RequestId: requestId,
        },
    });
    dispatch(
        messageNotifier(
            i18next.t('event_text_17', {
                dealName: cleanName,
                interpolation: { escapeValue: false },
            }),
            i18next.t('new_event')
        )
    );
};

export const dealRejected = (requestData) => (dispatch) => {
    const button = {
        name: i18next.t('go_to_deal'),
        primary: true,
        onClick: () => {
            window.location = `/user/deals/${requestData.EntityId}`;
        },
    };
    dispatch({
        type: C.DEAL_PROLONGATION_REQUEST_REJECTED,
        payload: {
            DealId: requestData.EntityId,
            RequestId: requestData.Id,
        },
    });
    dispatch(
        messageNotifier(i18next.t('event_text_18'), i18next.t('new_event'), [
            button,
        ])
    );
};

const requestRejected = (Entity) => (dispatch) => {
    switch (Entity.EntityType) {
        case 2:
            dispatch(dealRejected(Entity));
    }
};

// const contractExpiresSoon = Entity => dispatch => {
//     const cleanName = sanitizeHtml(Entity.Name);
//     dispatch(messageNotifier(`${i18next.t('expire_soon_text')} <b class="bold">"${Entity.Id + '. ' + cleanName}"</b> ${i18next.t('expire_soon_text_3')}`));
// };

export const contractExpiresSoon = (Entity) => (dispatch) => {
    const cleanName = sanitizeHtml(Entity.Name);
    dispatch(
        messageNotifier(
            i18next.t('contract_expires_soon_text', { name: cleanName })
        )
    );
};

const dealExpiresSoon = (Entity) => (dispatch) => {
    const cleanName = sanitizeHtml(Entity.Name);
    dispatch(
        messageNotifier(
            i18next.t('deal_expires_soon_text', { name: cleanName })
        )
    );
};

export const getEventMessage = (data) => (dispatch) => {
    const { StatusChangeInfo, EventInfo, Entity } = data;
    switch (EventInfo.EventType) {
        case 1:
            return (
                Entity &&
                dispatch(getContractEventMessage(StatusChangeInfo, Entity))
            );
        case 2:
            return (
                Entity &&
                dispatch(getDealEventMessage(StatusChangeInfo, Entity))
            );
        case 5:
            return (
                Entity && dispatch(dealProlonged(Entity, EventInfo.EntityId))
            );
        case 8:
            return (
                Entity &&
                dispatch(getSmevStatusChangeMessage(StatusChangeInfo, Entity))
            );
        case 10:
            return Entity && dispatch(messageReceived(Entity));
        case 11:
            return Entity && dispatch(incomingRequest(Entity));
        // case 12:
        //     return dispatch(requestAccepted(Entity));
        case 13:
            return Entity && dispatch(requestRejected(Entity));
        case 19:
            return Entity && dispatch(dealExpiresSoon(Entity));
        case 20:
            return Entity && dispatch(contractExpiresSoon(Entity));
    }
};
