import C from '../../constants/actionTypes';

const localeInitialState = {
    lang: null,
    localeLoaded: false,
    localizationData: {},
};

export const locale = (state = localeInitialState, action) => {
    switch (action.type) {
        case C.LOCALE_LOADED:
            return {
                lang: state.lang === null ? 'ru-RU' : state.lang,
                localeLoaded: true,
                localizationData: {
                    'en-EN': {
                        translation: { ...action.payload['en-EN'] },
                    },
                    'ru-RU': {
                        translation: { ...action.payload['ru-RU'] },
                    },
                },
            };

        case C.LANGUAGE_CHANGED:
            return {
                ...state,
                lang: action.payload,
            };
        default:
            return state;
    }
};
