import C from '../../constants/actionTypes';

const initialState = {
    currentInfo: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case C.CONTRACT_FETCHED:
            return {
                ...state,
                currentInfo: {
                    ...action.payload,
                },
            };
        case C.COUNTER_OFFER:
            const { currentInfo } = state;
            return {
                ...state,
                currentInfo: {
                    ...action.payload,
                },
                previousInfo: currentInfo,
            };
        case C.CONTRACT_UPDATED:
        case C.CONTRACT_ACCEPTED_BY_PARTNER:
        case C.CONTRACT_ACCEPTED_BY_USER:
            if (
                !!state.currentInfo.Id &&
                state.currentInfo.Id === parseInt(action.payload.Id)
            ) {
                return {
                    ...state,
                    currentInfo: {
                        ...action.payload,
                    },
                };
            }
            return state;

        case C.CONTRACT_DECLINED_BY_USER:
        case C.CONTRACT_DECLINED_BY_PARTNER:
            if (
                !!state.currentInfo.Id &&
                state.currentInfo.Id === action.payload.Id
            ) {
                return {
                    ...state,
                    currentInfo: {
                        ...state.currentInfo,
                        Status: 5,
                    },
                };
            }
            return state;

        case C.PREVIOUS_CONTRACT_VERSION_FETCHED:
            return {
                ...state,
                previousInfo: action.payload,
            };

        case C.CONTRACT_TEMPLATE_FETCHED:
            return {
                ...state,
                template: action.payload,
            };

        case C.CONTRACT_FEES_FETCHED:
            return {
                ...state,
                fees: action.payload,
            };

        case C.CONTRACT_TRANSACTIONS_FETCHED:
            return {
                ...state,
                transactions: action.payload,
            };
        case C.LIMITED_CONTRACT_DATA_FETCHED:
            return {
                ...state,
                transactions: null,
                previousInfo: null,
            };
        case C.CLEAR_CONTRACT_DETAILED:
        case C.CONTRACT_FETCH_FAILED:
            return initialState;
        default:
            return state;
    }
};
