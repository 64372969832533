import C from '../../constants/actionTypes';

const initialState = {
    type: '',
    opened: false,
    loading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case C.OPEN_MODAL:
            const { type, data, callback, dialogText } = action.payload;
            return {
                opened: true,
                type,
                data,
                callback,
                dialogText,
            };

        case C.CLOSE_MODAL:
            return { ...state, opened: false };

        case C.MODAL_LOADING:
            return { ...state, loading: true };

        case C.MODAL_LOADED:
            return { ...state, loading: false };

        default:
            return state;
    }
};
