import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as form } from 'redux-form';
import deals from './deals';
import dealsHistory from './dealsHistory';
import contractDetailed from './contractDetailed';
import dealDetailed from './dealDetailed';
import contracts from './contracts';
import contractsHistory from './contractsHistory';
import publicContracts from './publicContracts';
import publicDetailed from './publicDetailed';
import user from './user';
import { chatsList, chatDetailed } from './chatsReducer';
import loginHistory from './loginHistory';
import C from '../../constants/actionTypes';
import { reducer as notificationsReducer } from 'reapop';
import { locale } from './locale';
import { validations } from './validations';
import chat from './chat';
import modal from './modal';
import { support } from './support';
import * as R from 'ramda';

const contractTypes = (state = {}, action) => {
    switch (action.type) {
        case C.CONTRACT_TYPES_FETCHED:
            return R.pipe(
                R.reduce((acc, value) => {
                    if (!!value.SubTypes) {
                        const a = R.append(value, R.prop('SubTypes', value));
                        return acc.concat(a);
                    } else {
                        return R.append(value, acc);
                    }
                }, []),
                R.indexBy(R.prop('Id'))
            )(action.payload);
        default:
            return state;
    }
};

const loadFailed = (state = false, action) => {
    switch (action.type) {
        case C.LOAD_FAILED:
            return true;
        default:
            return state;
    }
};

const redirectUrl = (state = {}, action) => {
    switch (action.type) {
        case C.SET_REDIRECT_URL:
            return {
                url: action.payload,
            };
        case C.CLEAR_REDIRECT_URL:
            return {};
        default:
            return state;
    }
};

const fees = (state = {}, action) => {
    switch (action.type) {
        case C.FEES_FETCHED:
            return {
                ...state,
                ...action.payload,
            };
        case C.RESET_FEES:
            return {};
        default:
            return state;
    }
};

const paymentMethods = (state = {}, action) => {
    switch (action.type) {
        case C.PAYMENT_METHODS_LOADED:
            const { payload } = action;
            const WMP = 'WebMoneyWMP';

            const paymentMethods = payload.reduce((acc, method) => {
                return {
                    ...acc,
                    [method.Name]: method,
                };
            }, {});

            const WmpEnabled = WMP in paymentMethods;
            const clientMethods = Object.keys(paymentMethods)
                .filter((key) => key !== WMP)
                .reduce((acc, key) => {
                    if (key !== 'WebMoney') {
                        return { ...acc, [key]: paymentMethods[key] };
                    }
                    return {
                        ...acc,
                        [key]: {
                            ...paymentMethods[key],
                            ...(WmpEnabled && {
                                Currencies: [
                                    ...paymentMethods[key].Currencies,
                                    { Name: 'WMP', Value: 'WMP' },
                                ],
                            }),
                        },
                    };
                }, {});

            return {
                defaultList: paymentMethods,
                clientList: clientMethods,
            };

        default:
            return state;
    }
};

const diffPaymentMethods = (state = {}, action) => {
    switch (action.type) {
        case C.PAYMENT_DIFF_METHODS_LOADED:
            return R.pipe(
                R.keys,
                R.map((key) =>
                    R.assoc('method', key, R.prop(key, action.payload))
                ),
                R.indexBy(R.prop('method'))
            )(action.payload);
        default:
            return state;
    }
};

const loading = (state = false, action) => {
    switch (action.type) {
        case C.SHOW_LOADER:
            return true;
        case C.HIDE_LOADER:
            return false;
        default:
            return state;
    }
};

const btcRestricted = (state = false, action) => {
    switch (action.type) {
        case C.RESTRICT_BTC:
            return action.payload;
        default:
            return state;
    }
};

const globalContractType = (state = '', action) => {
    switch (action.type) {
        case C.GLOBAL_CONTRACT_TYPE:
            return action.payload;
        default:
            return state;
    }
};

const wsConnected = (state = false, action) => {
    switch (action.type) {
        case C.WS_CONNECTED:
            return true;
        case C.USER_LOGGED_OUT:
        case C.WS_DISCONNECT:
            return false;
        default:
            return state;
    }
};

const isFetchWithDrawMoney = (state = false, action) => {
    switch (action.type) {
        case C.USER_FETCHING_WITHDRAW_MONEY:
            return true;

        case C.USER_FETCHED_WITHDRAW_MONEY:
            return false;

        default:
            return state;
    }
};

const publicContractAcceptanceTriggered = (state = {}, action) => {
    switch (action.type) {
        case C.UNAUTHORIZED_ACCEPTANCE_TRIGGERED:
            return action.payload;

        case C.CLEAR_PUBLIC_CONTRACT_ACCEPTANCE_DATA:
            return {};
        default:
            return state;
    }
};
const statistics = (state = false, action) => {
    switch (action.type) {
        case C.STATISTICS_FETCHED:
            return action.payload;
        default:
            return state;
    }
};

const appReducer = combineReducers({
    btcRestricted,
    globalContractType,
    form,
    user,
    loginHistory,
    isFetchWithDrawMoney,
    loading,
    deals,
    dealDetailed,
    dealsHistory,
    contracts,
    contractDetailed,
    contractsHistory,
    publicContracts,
    publicDetailed,
    chat,
    support,
    routing: routerReducer,
    notifications: notificationsReducer(),
    chatsList,
    chatDetailed,
    contractTypes,
    redirectUrl,
    paymentMethods,
    diffPaymentMethods,
    locale,
    fees,
    validations,
    loadFailed,
    wsConnected,
    statistics,
    modal,
    publicContractAcceptanceTriggered,
});

const rootReducer = (state, action) => {
    if (action.type === C.USER_LOGGED_OUT) {
        const {
            routing,
            locale,
            contractTypes,
            statistics,
            paymentMethods,
            isFetchWithDrawMoney,
            diffPaymentMethods,
            publicContracts,
            modal,
            loginHistory,
            publicDetailed,
            btcRestricted,
            globalContractType,
        } = state;
        state = {
            btcRestricted,
            globalContractType,
            isFetchWithDrawMoney,
            routing,
            locale,
            contractTypes,
            paymentMethods,
            diffPaymentMethods,
            publicContracts,
            statistics,
            modal,
            loginHistory,
            publicDetailed,
        };
    }
    return appReducer(state, action);
};

export default rootReducer;
