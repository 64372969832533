import C from '../../constants/actionTypes';
import URLS from '../../constants/urls';
import {
    sendData,
    getData,
    handleServerResponse,
    handleDataReceived,
    getDomainType,
    tryGetItemFromLocalStorage,
} from '../helpers/common';
import { handleError, messageNotifier } from '../helpers/notifications';
import { validationsFetched } from '../actions/actionCreators';
import param from 'jquery-param';
import 'isomorphic-fetch';

//Locale
export const fetchLocale = () => (dispatch) => {
    const url = URLS.LOCALIZATION_URL;

    return fetch(url)
        .then(handleServerResponse)
        .then(handleDataReceived)
        .then((data) => {
            console.log('locale loaded');

            dispatch({
                type: C.LOCALE_LOADED,
                payload: data,
            });
            return data;
        });
};

export const fetchFormValidations = () => (dispatch) => {
    return getData(URLS.FORM_VALIDATORS_URL)
        .then((data) => {
            dispatch(validationsFetched(data));
            return data;
        })
        .catch((error) => {
            console.log('fetchFormValidations failed');
            dispatch(handleError(error));
        });
};

//GET SHORT USER INFO
export const searchUser =
    (str = '') =>
    (dispatch) => {
        const url = URLS.USER_SHORT_INFO_URL + str;

        return getData(url).catch((err) => dispatch(handleError(err)));
    };

//Check WMID
export const checkWMid = (str = '') => {
    const url = URLS.WMID_CHECK_URL + str;

    return getData(url);
};

//FETCH PAYMENT METHODS
export const getPaymethods = () => (dispatch) => {
    const lStorage = JSON.parse(tryGetItemFromLocalStorage('state'));
    if (lStorage == null) return null;
    const { btcRestricted } = lStorage;

    const url = URLS.PAYMENT_METHODS_URL;

    return fetch(url)
        .then(handleServerResponse)
        .then(handleDataReceived)
        .then((data) => {
            if (getDomainType() !== 3) {
                dispatch({
                    type: C.PAYMENT_METHODS_LOADED,
                    payload: data,
                });
            } else {
                let filteredMethods = data.filter(
                    (el) => !['Bitcoin', 'WebMoney'].includes(el.Name)
                );
                dispatch({
                    type: C.PAYMENT_METHODS_LOADED,
                    payload: filteredMethods,
                });
            }

            return data;
        });
};

//  FETCH Partner Payment Methods
export const getPartnerPaymethods = () => (dispatch) => {
    const lStorage = JSON.parse(tryGetItemFromLocalStorage('item'));
    if (lStorage == null) return null;
    const { btcRestricted } = lStorage;

    const url = URLS.PAYMENT_DIFF_METHODS_URL;

    return fetch(url)
        .then(handleServerResponse)
        .then(handleDataReceived)
        .then((data) => {
            if (getDomainType() !== 3) {
                dispatch({
                    type: C.PAYMENT_DIFF_METHODS_LOADED,
                    payload: data,
                });
            } else {
                let filteredMethods = data.filter(
                    (el) => !['Bitcoin', 'WebMoney'].includes(el.Name)
                );

                dispatch({
                    type: C.PAYMENT_DIFF_METHODS_LOADED,
                    payload: filteredMethods,
                });
            }

            return data;
        });
};
//FETCH commission details

export const fetchCommissionDetails = (paymethod, currency) => {
    const pmDictionary = {
        WebMoney: 1,
        Cards: 2,
        Bitcoin: 3,
        Qiwi: 5,
        Yandex: 6,
        Emoney: 7,
        WebMoneyWMP: 8,
        SBP: 10,
    };
    const currencyDictionary = {
        USD: 1,
        EUR: 2,
        RUB: 3,
        BTC: 4,
        UAH: 5,
        BYN: 6,
        VND: 7,
        BCH: 8,
        LTC: 9,
    };
    if (currency === 'WMP') {
        paymethod = 'WebMoneyWMP';
        currency = 'RUB';
    }
    const methodCode = pmDictionary[paymethod];
    const currencyCode = currencyDictionary[currency];

    const params = {
        sourcePayMethod: methodCode,
        destinationPayMethod: methodCode,
        currency: currencyCode,
    };
    const requestParams = param(params);
    const url = URLS.FEES_DETAILING_URL + '?' + requestParams;

    return fetch(url).then(handleServerResponse).then(handleDataReceived);
};

//Получить комиссии по сделке

/**
 * @param entity Entity info
 * @param entity.SourcePayMethod  Способ оплаты покупателя
 * @param entity.DestinationPayMethod  Способ оплаты продавца
 * @param entity.Amount  Сумма сделки
 * @param entity.Currency  Валюта сделки
 * @param entity.CommissionType Тип комиссии
 * @param entity.IsPaymentFromPayerDeposit  оплата продавцу идёт из залога покупателя. Для сайта всегда true
 */

export const fetchFees = (entity) => (dispatch) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const {
        user: { AccessToken },
    } = lStorage;

    const url = URLS.FEES_URL + '?' + param(entity);
    return fetch(url, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${AccessToken}`,
        },
    })
        .then(handleServerResponse)
        .then(handleDataReceived)
        .catch((error) => {
            dispatch(handleError(error));
        });
};

export const formatCreditCard = (value) => {
    let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    let matches = v.match(/\d{4,16}/g);
    let match = (matches && matches[0]) || '';
    let parts = [];

    for (let i = 0; i < match.length; i += 4) {
        parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
        return parts.join(' ');
    } else {
        return value;
    }
};

export const fetchStatistics = () => (dispatch) => {
    return fetch(URLS.STATISTICS_URL)
        .then(handleServerResponse)
        .then(handleDataReceived)
        .then((statistics) => {
            dispatch({
                type: C.STATISTICS_FETCHED,
                payload: statistics,
            });
        });
};
