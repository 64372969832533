import React, { Component } from 'react';
import ThemeProvider from 'react-toolbox/lib/ThemeProvider';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import moment from 'moment';
import 'moment/locale/ru';
import { isEmpty } from 'lodash';
import { removeNotifications } from 'reapop';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
import {
    openPasswordRecoveryModal,
    handleRegistrationConfirmation,
    handleRegistrationAlreadyComplited,
    handleInvoiceRegistration,
} from 'methods/api/users';
import { handleError, warningNotifier } from 'methods/helpers/notifications';
import { openModal, closeModal } from 'methods/actions/actionCreators';
import { handleWMAuthorization, handleLogout } from 'methods/api/session';
import cookiesNames from './constants/cookiesNames';
import theme from './assets/toolbox/theme';
import Footer from './components/Shared/Footer';
import Header from './components/Shared/Header/HeaderMenu';
import Main from './components/Shared/Main';
import Modal from './components/Modals/Modal';

moment.locale('ru');

const {
    RESET_PASSWORD,
    EMAIL_CONFIRMED,
    WEBMONEY_AUTH,
    INVOICE_REGISTRATION,
    REGISTRATION_ALREADY_COMPLITED,
} = cookiesNames;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            elapsed: 0,
        };

        this.handleUserActive = this.handleUserActive.bind(this);
        this.handleUserIdle = this.handleUserIdle.bind(this);
        this.clearSessionTimer = this.clearSessionTimer.bind(this);
    }

    componentDidMount() {
        const {
            handleLogout,
            user,
            handleRegistrationConfirmation,
            handleWMAuthorization,
            handleInvoiceRegistration,
            openPasswordRecoveryModal,
            handleRegistrationAlreadyComplited,
        } = this.props;

        if (this.timer) clearInterval(this.timer);
        const cookies = Cookies.get();

        if (!isEmpty(cookies)) {
            Object.keys(cookies).forEach((key) => {
                // eslint-disable-next-line default-case
                switch (key) {
                    case RESET_PASSWORD:
                        if (user.loggedIn) handleLogout();
                        openPasswordRecoveryModal(cookies[key]);
                        break;

                    case EMAIL_CONFIRMED:
                        handleRegistrationConfirmation(cookies[key]);
                        break;

                    case WEBMONEY_AUTH:
                        handleWMAuthorization(cookies[key]);
                        break;

                    case INVOICE_REGISTRATION:
                        if (user.loggedIn) handleLogout();
                        handleInvoiceRegistration(cookies[key]);
                        break;

                    case REGISTRATION_ALREADY_COMPLITED:
                        if (user.loggedIn) handleLogout();
                        handleRegistrationAlreadyComplited(cookies[key]);
                        break;
                }
            });
        }
    }

    clearSessionTimer() {
        clearInterval(this.timer);
        this.setState({
            elapsed: 0,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { lang } = this.props;

        if (lang !== nextProps.lang) {
            i18next.init(
                {
                    lng: nextProps.lang,
                    debug: true,
                    interpolation: {
                        escapeValue: false,
                    },
                    resources: { ...nextProps.localizationData },
                },
                () => {
                    i18next.changeLanguage(nextProps.lang);
                }
            );
        }
    }

    handleUserActive() {
        this.clearSessionTimer();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.elapsed === nextState.elapsed;
    }

    handleUserIdle() {
        const {
            user: { loggedIn },
            handleLogout,
            openModal,
            closeModal,
            user,
        } = this.props;
        const maxIdleDuration = 3600000 + 5 * 60 * 1000;
        const sessionOutdated = 3600000;

        if (loggedIn) {
            this.timer = setInterval(() => {
                this.setState(
                    {
                        elapsed: this.state.elapsed + 1000,
                    },
                    () => {
                        const { elapsed } = this.state;
                        if (elapsed >= sessionOutdated) {
                            openModal(
                                'session-timeout',
                                user,
                                this.clearSessionTimer
                            );
                        }

                        if (elapsed >= maxIdleDuration) {
                            closeModal();
                            handleLogout();
                            this.clearSessionTimer();
                        }
                    }
                );
            }, 1000);
        }
    }

    render() {
        const {
            location: { pathname },
        } = this.props;
        return (
            <IdleTimer
                ref="idleTimer"
                activeAction={this.handleUserActive}
                idleAction={this.handleUserIdle}
                timeout={10000}>
                <ThemeProvider theme={theme}>
                    <div className="app">
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>{i18next.t('title_text')}</title>
                            <meta
                                name="description"
                                content={i18next.t('meta_text')}
                            />
                            <meta
                                name="keywords"
                                content={i18next.t('meta_keywords')}
                            />
                        </Helmet>

                        {pathname !== '/card-payment' &&
                            pathname !== '/passport-details' &&
                            pathname !== '/card-add' && <Header />}

                        <Main />

                        {pathname !== '/card-payment' &&
                            pathname !== '/passport-details' &&
                            pathname !== '/card-add' && <Footer />}

                        <Modal />
                    </div>
                </ThemeProvider>
            </IdleTimer>
        );
    }
}

const mapStateToProps = ({
    user,
    support,
    loading,
    modal,
    notifications,
    contractTypes,
    locale: { lang, localeLoaded, localizationData },
}) => ({
    user,
    notifications,
    loading,
    lang,
    modal,
    localeLoaded,
    contractTypes,
    support,
    localizationData,
});

export default withRouter(
    connect(mapStateToProps, {
        handleLogout,
        openModal,
        removeNotifications,
        closeModal,
        warningNotifier,
        handleError,
        handleWMAuthorization,
        handleRegistrationConfirmation,
        openPasswordRecoveryModal,
        handleInvoiceRegistration,
        handleRegistrationAlreadyComplited,
    })(App)
);
