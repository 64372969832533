import moment from 'moment';
import i18next from 'i18next';
import {
    getData,
    handleDataReceived,
    handleServerResponse,
    sendData,
} from '../helpers/common';
import C from '../../constants/actionTypes';
import URLS from '../../constants/urls';
import {
    handleError,
    successNotifier,
    warningNotifier,
} from '../helpers/notifications';
import {
    closeModal,
    onFetchedWithDrawMoney,
    onFetchingWithDrawMoney,
} from '../actions/actionCreators';
import { selectAccumulateAssets, selectUserLogin } from './profile.selectors';
import * as types from './profile.types';

const baseUrl = '/api/profile';

export const checkPartnerName = (partnerName) =>
    getData(URLS.USER_SHORT_PROFILE_URL + partnerName);

export const fetchUserProfile = (user) => (dispatch) =>
    getData(`${URLS.USER_PROFILE_URL}?login=${user.UserLogin}`)
        .then((data) => {
            if (
                data.ActiveDealWithoutIdentification !== 0 &&
                data.Profile.SmevIdentificatedStatus !== 1 &&
                data.Profile.SmevIdentificatedStatus !== 100
            ) {
                dispatch(passportRequiredNotification());
            }

            dispatch({
                type: C.USER_PROFILE_FETCHED,
                payload: data,
            });

            return data;
        })
        .catch((error) => {
            console.log('fetchFormValidations failed');

            dispatch(handleError(error));
        });

export const fetchShortUserInfo = (login) =>
    getData(URLS.USER_SHORT_PROFILE_URL + login);

export const fetchShortUserInfoById = (id, ignorePartner = 0) =>
    getData(
        `${
            URLS.USER_SHORT_PROFILE_BY_ID_URL + id
        }&ignorePartner=${ignorePartner}`
    );

const passportRequiredNotification = () => (dispatch) => {
    const msg = `<div class="passport-required-block">
   
                <h1 class="notification-header">${i18next.t('attention')}</h1>
                <p>
                   ${i18next.t('notification_text')}
                </p>
                <p>
                  ${i18next.t('notification_text_2')}
                </p>
                <p>
                ${i18next.t('notification_text_3')}
                </p>
            </div>`;
    dispatch(warningNotifier(msg));
};

export const fetchUserTransactions =
    (page = 0, limit = 10) =>
    (dispatch) =>
        getData(
            `${URLS.USER_TRANSACTIONS_URL}?offset=${
                page * limit
            }&limit=${limit}`
        ).then((data) => {
            dispatch({
                type: C.USER_TRANSACTIONS_FETCHED,
                payload: { entity: data },
            });
            return data;
        });

export const fetchAttachedPaymentAccounts = (type) => (dispatch) => {
    const url = `${URLS.USER_PAYMENT_ACCOUNTS_URL}?paymethod=${type}`;
    return getData(url).catch((error) => {
        dispatch(handleError(error));
    });
};

export const getSbpBanksList = (nameToFind) => {
    const url = `/api/profile/payment-accounts/sbp-banks?nameToFind=${nameToFind}`;
    return getData(url).catch((error) => {
        console.log(error);
    });
};

export const editProfile = (data) => (dispatch) => {
    sendData('PUT', URLS.USER_PROFILE_URL, data)
        .then(() => {
            dispatch({
                type: C.USER_PROFILE_UPDATED,
                payload: data,
            });
            dispatch(successNotifier(i18next.t('notification_text_4')));
        })
        .catch((error) => dispatch(handleError(error)));
};

export const addPurse =
    (
        Purse = '',
        Currency = '',
        Paymethod = '',
        CardMonth = '',
        CardYear = '',
        CardHolder = '',
        BankId = '',
        UserLogin = ''
    ) =>
    (dispatch) => {
        return sendData('POST', URLS.USER_PAYMENT_ACCOUNTS_URL, {
            Purse,
            CardHolder,
            CardMonth,
            Paymethod,
            CardYear,
            Currency,
            BankId,
            UserLogin,
        })
            .then((purseId) =>
                dispatch({
                    type: C.USER_PURSE_ADDED,
                    payload: {
                        Purse,
                        Currency,
                        Paymethod,
                        Id: purseId,
                        RecurrentPayment: false,
                    },
                })
            )
            .then(() =>
                dispatch(successNotifier(i18next.t('notification_text_5')))
            )
            .catch((error) => dispatch(handleError(error)));
    };

export const changePassword = (data) => (dispatch) => {
    sendData('POST', URLS.USER_PASSWORD_URL, data)
        .then(() => dispatch(successNotifier(i18next.t('notification_text_6'))))
        .catch((error) => dispatch(handleError(error)));
};

export const sendWithdrawTransactionMoney = (data) => (dispatch) => {
    dispatch(onFetchingWithDrawMoney(true));
    sendData('POST', URLS.USER_SAVING_ACCOUNT_WITHDRAW_URL, data)
        .then(() => {
            dispatch(onFetchedWithDrawMoney(false));
            dispatch(successNotifier(i18next.t('notification_text_9')));
        })
        .catch((error) => {
            dispatch(handleError(error));
        })
        .finally(() => dispatch(onFetchedWithDrawMoney(false)));
};

export const getPurses = () => (dispatch) =>
    getData(URLS.USER_PAYMENT_ACCOUNTS_URL)
        .then((purses) => {
            dispatch({
                type: C.USER_PURSES_LOADED,
                payload: purses,
            });
            return purses;
        })
        .catch((error) => dispatch(handleError(error)));

export const removePurse = (index) => (dispatch) =>
    getData(`${URLS.USER_PAYMENT_ACCOUNTS_URL}/${index}`, 'DELETE')
        .then(() => {
            dispatch({
                type: C.USER_PURSE_REMOVED,
                payload: index,
            });
            dispatch(closeModal());
            dispatch(successNotifier(i18next.t('notification_text_7')));
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(handleError(error));
        });

export const savePassportData = (data) => (dispatch) => {
    const lStorage = JSON.parse(localStorage.getItem('state'));
    const { user } = lStorage;

    const BirthdayDate = moment(data.BirthdayDate).format('MM.DD.YYYY');
    const PassportIssuedDate = moment(data.PassportIssuedDate).format(
        'MM.DD.YYYY'
    );

    const passportData = {
        ...data,
        BirthdayDate,
        UserLogin: user.UserLogin,
        PassportIssuedDate,
    };
    return sendData('POST', URLS.USER_PASSPORT_URL, passportData)
        .then((data) => {
            dispatch({
                type: C.USER_PASSPORT_ADDED,
                payload: passportData,
            });
            dispatch(fetchUserProfile(user))
                .then(() => {
                    dispatch(successNotifier(i18next.t('notification_text_8')));
                    dispatch(closeModal());
                })
                .catch((error) => {
                    dispatch(handleError(error));
                });
        })
        .catch((error) => {
            dispatch(handleError(error));
        });
};

export const iframeSavePassportData = (
    {
        UserLogin,
        FirstName,
        Surname,
        Patronymic = null,
        BirthdayDate,
        PassportNumber,
        PassportSerial,
        PassportIssuedBy,
        PassportIssuerCode,
        PassportBirthPlace = null,
        PassportIssuedDate,
        PhoneNumber,
        INN = null,
        SNILS = null,
    },
    token = ''
) => {
    const body = new URLSearchParams();
    body.append('UserLogin', UserLogin);
    body.append('FirstName', FirstName);
    body.append('Surname', Surname);
    body.append('Patronymic', Patronymic);
    body.append('BirthdayDate', BirthdayDate);
    body.append('PassportNumber', PassportNumber);
    body.append('PassportSerial', PassportSerial);
    body.append('PassportIssuedBy', PassportIssuedBy);
    body.append('PassportIssuerCode', PassportIssuerCode);
    body.append('PassportBirthPlace', PassportBirthPlace);
    body.append('PassportIssuedDate', PassportIssuedDate);
    body.append('PhoneNumber', PhoneNumber);
    if (INN != null) {
        body.append('INN', INN);
    }
    if (SNILS != null) {
        body.append('SNILS', SNILS);
    }

    return fetch(URLS.USER_PASSPORT_URL, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body,
    })
        .then(handleServerResponse)
        .then(handleDataReceived);
};

export const toggleAccumulateAssets = () => (dispatch, getState) => {
    const state = getState();
    const login = selectUserLogin(state);
    const value = !selectAccumulateAssets(state);

    return sendData('POST', `${baseUrl}/accumulate-assets`, {
        UserLogin: login,
    })
        .then(() =>
            dispatch({
                type: types.TOGGLE_ACCUMULATE_ASSETS,
                payload: { entity: value },
            })
        )
        .catch((error) => dispatch(handleError(error)));
};

export const withdrawAssetsBegin =
    (amount, cardNumber, name, surname, month, year) => (dispatch) =>
        sendData('POST', `${baseUrl}/init-withdraw`, {
            Amount: amount,
            CardNumber: cardNumber,
            CardHolderName: name,
            CardHolderSurname: surname,
            CardMonth: month,
            CardYear: year,
        }).catch((error) => dispatch(handleError(error)));

export const withdrawAssetsEnd = (code, token) => (dispatch) =>
    sendData('POST', `${baseUrl}/confirm-withdraw`, {
        Code: code,
        Token: token,
    }).catch((error) => {
        dispatch(handleError(error));
        throw error;
    });

export const getX11Info = () => getData(URLS.X11_INFO_URL);
