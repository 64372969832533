import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import C from '../../constants/actionTypes';
import { store } from '../../store/store';
import { getDomainType } from './common';

export var ProtectedRoute = function ({ component: Component, ...rest }) {
    const appState = store.getState();
    const {
        user: { loggedIn },
    } = appState;
    return (
        <Route
            {...rest}
            render={() => {
                if (loggedIn) {
                    return <Component {...rest} />;
                }
                const {
                    location: { pathname },
                } = rest;
                store.dispatch({
                    type: C.SET_REDIRECT_URL,
                    payload: pathname,
                });
                return <Redirect to="/login" />;
            }}
        />
    );
};

export var DependsOnDomainRoute = function ({
    component: Component,
    fallbackComponent: FallbackComponent,
    ...rest
}) {
    return (
        <Route
            {...rest}
            render={() => {
                if (getDomainType() !== 3) {
                    return <Component {...rest} />;
                }
                if (FallbackComponent) {
                    return <FallbackComponent {...rest} />;
                }
                const {
                    location: { pathname },
                } = rest;
                store.dispatch({
                    type: C.SET_REDIRECT_URL,
                    payload: pathname,
                });
                return <Redirect to="/404" />;
            }}
        />
    );
};
