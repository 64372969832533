import moment from 'moment';
import param from 'jquery-param';
import i18next from 'i18next';
import sanitizeHtml from 'sanitize-html';
import { getData, sendData } from '../helpers/common';
import { getDealStatus } from '../helpers/statuses';
import {
    handleError,
    messageNotifier,
    successNotifier,
} from '../helpers/notifications';
import {
    closeModal,
    dealPaymentConfirmed,
    dealProlongationConfirmed,
    dealProlongationRejected,
    dealProlongationRequestSent,
    dealsFetched,
    dealFetched,
    dealsFetchFailed,
    dealsHistoryFetched,
    dealUpdated,
    disputeOpened,
    openModal,
} from '../actions/actionCreators';
import C from '../../constants/actionTypes';
import URLS from '../../constants/urls';
import { fetchChatMessages, getUnreadMessages, sendMessage } from './chat';

// FETCH DEALS LIST
export const fetchDeals =
    (filters = {}) =>
    (dispatch) => {
        let url = URLS.DEALS_FOR_SITE_URL;
        url += filters.Offset !== null ? `?offset=${filters.Offset}` : '';
        url += filters.Limit !== null ? `&limit=${filters.Limit}` : '';
        url += filters.startDate ? `&startDate=${filters.startDate}` : '';
        url += filters.endDate ? `&endDate=${filters.endDate}` : '';
        url += filters.search ? `&search=${filters.search}` : '';
        url +=
            !!filters.statuses && filters.statuses.length > 0
                ? `&statuses=${filters.statuses}`
                : '';
        url +=
            !!filters.paymethods && filters.paymethods.length > 0
                ? `&paymethods=${filters.paymethods}`
                : '';
        url +=
            !!filters.categories && filters.categories.length > 0
                ? `&categories=${filters.categories.join(',')}`
                : '';
        url +=
            !!filters.tags && filters.tags.length > 0
                ? `&tags=${filters.tags}`
                : '';

        return getData(url)
            .then((deals) => {
                const {
                    Items,
                    Pagination: { Total },
                } = deals;
                const chatsRequests = [];
                Items.map((deal) => {
                    chatsRequests.push(fetchChatMessages(deal.ContractId));
                });

                dispatch({
                    type: C.DEALS_COUNTER_UPDATED,
                    payload: Total,
                });

                return Promise.all(chatsRequests).then((chats) => {
                    const updatedItems = Items.map((deal, i) => {
                        const UnreadMessages = getUnreadMessages(
                            chats[i]
                        ).length;
                        return {
                            ...deal,
                            UnreadMessages,
                        };
                    });

                    dispatch(
                        dealsFetched({
                            ...deals,
                            Items: updatedItems,
                        })
                    );
                });
            })
            .catch((error) => {
                dispatch(dealsFetchFailed());
                dispatch(handleError(error));
            });
    };

// FETCH DEAL
export const fetchDeal = (id) => {
    return getData(`${URLS.DEALS_URL}/site` + `/${id}`);
};
// FETCH DEAL BY CONTRACT ID
export const fetchDealByContractId = (contractId) =>
    getData(`${URLS.DEALS_URL}/get-by-contractId/${contractId}`);

// DEAL PAYMENT CONFIRMATION
export const confirmPayment = (deal) => (dispatch) => {
    const { Purse, Id } = deal;

    return sendData('POST', `${URLS.DEALS_URL}/${Id}/confirm-payment`, {
        Purse,
    })
        .then(async () => {
            const updatedDeal = await fetchDeal(Id);
            dispatch(dealUpdated(updatedDeal));
            dispatch(successNotifier(i18next.t('acceptance_text')));
            dispatch(dealPaymentConfirmed(updatedDeal));
        })
        .catch((error) => {
            dispatch(handleError(error));
            dispatch(closeModal());
        });
};

// DEAL ACCEPTANCE CONFIRMATION
export const confirmAcceptance = (deal) => (dispatch) => {
    const getConfirmRoute = (routeConfirm) =>
        `${URLS.DEALS_URL}/${deal.Id}/${routeConfirm}`;
    const url =
        deal.PartnerPaymethod && deal.Payamethod !== deal.PartnerPaymethod
            ? getConfirmRoute('diff-confirm')
            : getConfirmRoute('confirm');

    return sendData('POST', url)
        .then(async () => {
            const updatedDeal = await fetchDeal(deal.Id);
            dispatch(dealUpdated(updatedDeal));

            const status = getDealStatus(updatedDeal);

            if (status === 'closed') {
                dispatch(openModal('deal-closed', updatedDeal));
            } else {
                dispatch(closeModal());
            }
            dispatch(successNotifier(i18next.t('acceptance_text')));
        })
        .catch((error) => {
            dispatch(closeModal());
            dispatch(handleError(error));
        });
};

// REQUEST DEAL PROLONGATION
export const prolongDeal = (entity) => (dispatch) => {
    const { DealId, NewDuration } = entity;

    const url = `${URLS.DEALS_URL}/${DealId}/prolong`;

    return sendData('POST', url, {
        NewDuration: moment(NewDuration).format('MM.DD.YYYY'),
    }).then((data) => {
        dispatch(
            successNotifier(
                i18next.t('prolong_request_text'),
                i18next.t('new_event')
            )
        );

        dispatch(
            dealProlongationRequestSent({
                ...data,
                ...entity,
            })
        );
        return data;
    });
};

// CONFIRM DEAL PROLONGATION REQUEST

export const confirmDealProlongation =
    (id, requestId, newDuration) => (dispatch) => {
        const url = `${URLS.DEALS_URL}/${id}/prolong?requestId=${requestId}`;
        return getData(url)
            .then((dealInfo) => {
                const { RequestId, Duration, Id } = dealInfo;

                dispatch(
                    successNotifier(
                        i18next.t('prolongation_text'),
                        i18next.t('new_event')
                    )
                );

                dispatch(
                    dealProlongationConfirmed({
                        RequestId,
                        Duration,
                        Id,
                    })
                );
            })
            .catch((error) => {
                dispatch(handleError(error));
            });
    };

// CONFIRM DEAL PROLONGATION REQUEST

export const rejectDealProlongation = (id, requestId) => (dispatch) => {
    const url = `${URLS.DEALS_URL}/${id}/reject_prolong?requestId=${requestId}`;
    return getData(url)
        .then((data) => {
            dispatch(
                messageNotifier(
                    i18next.t('prolongation_rejection_text'),
                    i18next.t('new_event')
                )
            );

            dispatch(
                dealProlongationRejected({
                    RequestId: requestId,
                    Id: id,
                })
            );
        })
        .catch((error) => {
            dispatch(handleError(error));
        });
};

// OPEN DISPUTE
export const openDealDispute = (entity, user) => async (dispatch) => {
    const { Id, DisputeReason, ContractId } = entity;

    const url = `${URLS.DEALS_URL}/${Id}/open-dispute`;
    const cleanName = sanitizeHtml(entity.Name);

    const initiatedByUser = entity.Initiator.UserLogin === user.UserLogin;
    const addressee = initiatedByUser
        ? entity.Partner.UserLogin
        : entity.Initiator.UserLogin;
    try {
        await sendData('POST', url, { DisputeReason });
        const updatedDeal = await fetchDeal(Id);
        dispatch(dealUpdated(updatedDeal));
        dispatch(
            successNotifier(
                `${i18next.t(
                    'deal'
                )} <b class="bold">"${cleanName}"</b>  ${i18next.t(
                    'arbitration_text'
                )}`,
                i18next.t('dispute_opened_text')
            )
        );

        const modifiedText = `Открыт спор по следующей причине: "${DisputeReason}"`;
        const msg = {
            body: modifiedText,
            from: user.UserLogin,
            to: addressee,
            chatType: 1,
            bindedObjectId: ContractId,
        };

        dispatch(sendMessage(msg)).catch((error) =>
            dispatch(handleError(error))
        );
    } catch (e) {
        dispatch(handleError(e));
    }
};

// FETCH DEALS HISTORY
export const fetchDealsHistory = (filters) => (dispatch) => {
    const params = param(filters);

    return getData(`${URLS.DEALS_HISTORY_URL}?${params}`)
        .then((data) => {
            if (data) {
                const { Items, Pagination } = data;
                dispatch(
                    dealsHistoryFetched({
                        Items,
                        Pagination,
                    })
                );
                return data;
            }
        })
        .catch((error) => {
            dispatch(handleError(error));
        });
};

// FETCH DEAL TRANSACTIONS
export const fetchDealTransactionsList = (entity) => (dispatch) => {
    const url = `${URLS.DEALS_URL}/${entity.Id}/transactions`;
    return getData(url).then((data) => {
        dispatch({
            type: C.DEAL_TRANSACTIONS_FETCHED,
            payload: data,
        });
    });
};

export const requestDealCancel = (DealId) => (dispatch) => {
    return getData(`/api/deals/${DealId}/cancel`)
        .then(async ({ RequestId }) => {
            const updatedDeal = await fetchDeal(DealId);
            dispatch(dealUpdated(updatedDeal));
            dispatch({
                type: 'DEAL_CANCEL_REQUESTED',
                payload: { DealId, RequestId },
            });
            dispatch(successNotifier('Запрос успешно отправлен'));
        })
        .catch((error) => {
            dispatch(handleError(error));
        });
};

export const acceptDealCancel = (dealId, requestId) => (dispatch) => {
    return getData(`/api/deals/${dealId}/accept_cancel?requestId=${requestId}`)
        .then(async (data) => {
            const updatedDeal = await fetchDeal(dealId);
            dispatch(dealUpdated(updatedDeal));
            dispatch({
                type: 'DEAL_CANCEL_CONFIRMED',
                payload: dealId,
            });
            dispatch(
                successNotifier(
                    'Запрос на отмену сделки успешно принят. Сделка будет закрыта в ближайшее время.'
                )
            );
        })
        .catch((error) => {
            dispatch(handleError(error));
        });
};

export const rejectDealCancel = (dealId, requestId) => (dispatch) => {
    return getData(`/api/deals/${dealId}/reject_cancel?requestId=${requestId}`)
        .then(async () => {
            const updatedDeal = await fetchDeal(dealId);
            dispatch(dealUpdated(updatedDeal));
            dispatch({
                type: 'DEAL_CANCEL_REJECTED',
                payload: dealId,
            });
            dispatch(successNotifier('Запрос успешно отменен.'));
        })
        .catch((error) => {
            dispatch(handleError(error));
        });
};
